import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Reports from 'components/Reports';
import { setArrayImmutable } from 'utils/array.js';
import { createReport, CREATE_REPORT_SUCCESS, fetchTilbydere } from 'store/actions/report';
import {
  validationRunner,
  runValidations,
  required,
  requiredMultiList,
  validReportTimespan,
} from 'utils/validation/validation';

class ReportsContainer extends React.Component {
  constructor(props) {
    super(props);

    const typeNames = [
      { name: 'Innlandsfiske', value: 'INNLANDSFISKETILBUD' },
      { name: 'Laksefiske', value: 'LAKSEFISKETILBUD' },
      { name: 'Storvilt', value: 'STORVILTTILBUD' },
      { name: 'Småvilt', value: 'SMAAVILTTILBUD' },
    ];

    this.onHomeClick = this.onHomeClick.bind(this);
    this.onFromDayChange = this.onFromDayChange.bind(this);
    this.onToDayChange = this.onToDayChange.bind(this);
    this.onTilbyderChange = this.onTilbyderChange.bind(this);
    this.onTypeChange = this.onTypeChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitSuccess = this.onSubmitSuccess.bind(this);
    this.disabledFromDates = this.disabledFromDates.bind(this);
    this.disabledToDates = this.disabledToDates.bind(this);
    this.isValid = this.isValid.bind(this);

    this.fieldValidations = [
      validationRunner('fromDate', 'Fra-dato', required),
      validationRunner('toDate', 'Til-dato', required),
      validationRunner('types', 'Typer', requiredMultiList),
      validationRunner('reportTimespan', 'Tidsrom', validReportTimespan),
    ];

    this.state = {
      filters: {
        fromDate: null,
        toDate: null,
        selectedTilbyder: null,
        types: typeNames.map(t => ({ ...t, active: true })),
        reportTimespan: {fromDate: null, toDate: null},
      },
      validationErrors: {},
      displayErrors: false,
    };

    this.state.validationErrors = runValidations(this.state.filters, this.fieldValidations);
  }

  componentDidMount() {
    this.props.fetchTilbydere();
  }

  onHomeClick() {
    this.props.history.push({ pathname: '/', state: { transition: 'home' } });
  }

  onFromDayChange(selectedDay) {
    const { filters } = this.state;
    filters.fromDate = selectedDay;
    filters.reportTimespan.fromDate = selectedDay;
    this.setState({
      filters,
      validationErrors: runValidations(filters, this.fieldValidations),
    });
  }

  onToDayChange(selectedDay) {
    const { filters } = this.state;
    filters.toDate = selectedDay;
    filters.reportTimespan.toDate = selectedDay;
    this.setState({
      filters,
      validationErrors: runValidations(filters, this.fieldValidations),
    });
  }

  onTilbyderChange(selectedTilbyder) {
    this.setState(prev => ({ ...prev, filters: { ...prev.filters, selectedTilbyder } }));
  }

  onTypeChange(typeIndex) {
    const { filters } = this.state;
    const selectedType = filters.types[typeIndex];
    filters.types = setArrayImmutable(filters.types, typeIndex, {
      name: selectedType.name,
      value: selectedType.value,
      active: !selectedType.active,
    });
    this.setState({
      filters,
      validationErrors: runValidations(filters, this.fieldValidations),
    });
  }

  onSubmit() {
    if (this.isValid()) {
      console.log('submitting');
      this.props.createReport(this.state.filters).then(this.onSubmitSuccess);
    }
  }

  onSubmitSuccess() {
    if (this.props.report && this.props.report.lenke) window.open(this.props.report.lenke);
  }

  disabledToDates(day) {
    const { fromDate } = this.state.filters;
    if (fromDate === null) return false;
    return day.getTime() <= fromDate.getTime();
  }

  disabledFromDates(day) {
    const { toDate } = this.state.filters;
    if (toDate === null) return false;
    return day.getTime() >= toDate.getTime();
  }

  isValid() {
    return Object.keys(this.state.validationErrors).length === 0;
  }

  render() {
    const alleTilbydere = { label: 'ALLE', value: 'all' };
    const tilbyderValg = [alleTilbydere].concat(this.props.tilbydere);

    return (
      <Reports
        filters={this.state.filters}
        tilbydere={tilbyderValg}
        onHomeClick={this.onHomeClick}
        onFromDayChange={this.onFromDayChange}
        onToDayChange={this.onToDayChange}
        onTilbyderChange={this.onTilbyderChange}
        onTypeChange={this.onTypeChange}
        onSubmit={this.onSubmit}
        validationErrors={this.state.validationErrors}
        displayErrors={this.state.displayErrors}
        isValid={this.isValid()}
        disabledFromDates={this.disabledFromDates}
        disabledToDates={this.disabledToDates}
        loading={this.props.loading}
      />
    );
  }
}

ReportsContainer.propTypes = {
  createReport: PropTypes.func.isRequired,
  fetchTilbydere: PropTypes.func.isRequired,
  tilbydere: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  report: PropTypes.object,
  loading: PropTypes.bool.isRequired,
};

ReportsContainer.defaultProps = {
  report: null,
};

const mapStateToProps = state => ({
  loading: state.get.actionsInProgress.includes(CREATE_REPORT_SUCCESS),
  report: state.get.report,
  tilbydere: state.get.tilbydere.map(t => ({ label: t.label, value: t.id })),
});

export default connect(
  mapStateToProps,
  { createReport, fetchTilbydere }
)(ReportsContainer);
