import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  dropdown: {
    fontFamily: theme.fonts.barlowSemiCondensed,
    background: 'rgba(253, 252, 249, 1)',
    border: '1pt solid rgba(210,210,210,1)',
    borderRadius: '1pt',
    padding: 2,
  },
});

const SelectField = ({ classes, value, onChange, name, options, placeholder }) => (
  <Select
    className={classes.dropdown}
    inputProps={{ className: classes.props }}
    searchable
    clearable={false}
    autosize={false}
    placeholder={placeholder}
    noResultsText="Ingen resultat funnet"
    onChange={onChange}
    options={options}
    value={value}
    name={name}
  />
);

SelectField.defaultProps = {
  value: null,
};

SelectField.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default withStyles(styles)(SelectField);
