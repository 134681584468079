import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, withStyles } from '@material-ui/core';
import countries from 'static/countries.json';
import TextField from 'components/Common/TextField';
import MobilInput from 'components/Personalia/MobileInput';
import SelectField from '../Common/SelectField';

import 'react-select/dist/react-select.css';

const styles = theme => ({
  label: {
    fontFamily: theme.fonts.barlow,
    fontWeight: 500,
    color: 'rgba(49,45,49,1)',
    marginBottom: '1em',
  },
  dropdown: {
    fontFamily: theme.fonts.barlowSemiCondensed,
    border: '1pt solid rgba(210,210,210,1)',
    borderRadius: '1pt',
    padding: 2,
  },
  verticalDivider: {
    borderLeft: '1pt dashed green',
  },
  emailWrapper: {
    marginTop: '1em',
  },
  emailText: {
    fontFamily: theme.fonts.barlowSemiCondensed,
    fontWeight: 200,
    color: 'rgba(49,45,49,1)',
  },
  receiptText: {
    fontFamily: theme.fonts.barlowSemiCondensed,
    fontWeight: 200,
    color: 'rgba(49,45,49,1)',
  },
});

const PersonaliaForm = ({
  classes,
  customer,
  onChange,
  onDropdownChange,
  validationErrors,
  displayErrors,
  showMemberInput,
  showKommuneInput,
  fetchPersonaliaFromNumber,
  customerNotFound,
}) => {
  const findCountryIndex = code => countries.findIndex(c => c.value === code.toLowerCase());
  const {
    countryCode,
    mobileNumber,
    name,
    country,
    zipCode,
    email,
    membersNumber,
    kommune,
  } = customer;
  return (
    <Grid container spacing={16}>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <MobilInput
          countryCode={countryCode}
          mobileNumber={mobileNumber}
          onChange={onChange}
          onClick={fetchPersonaliaFromNumber}
          errorText={validationErrors.mobileNumber}
          displayError={displayErrors}
          customerNotFound={customerNotFound}
        />
      </Grid>
      <div className={classes.verticalDivider} />
      <Grid container spacing={16} item xs={12} sm={12} md={6} lg={6}>
        <Grid item xs={12}>
          <TextField
            label="Navn"
            value={name}
            name="name"
            onChange={onChange}
            errorText={validationErrors.name}
            displayError={displayErrors}
          />
        </Grid>
        <Grid item xs={12} md={7}>
          <Typography variant="subheading" className={classes.label}>
            Land
          </Typography>
          <SelectField
            placeholder="Velg land"
            onChange={onDropdownChange}
            options={countries}
            value={countries[findCountryIndex(country)]}
            name="country"
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <TextField
            label="Postnummer"
            type="number"
            value={zipCode}
            name="zipCode"
            onChange={onChange}
            errorText={validationErrors.zipCode}
            displayError={displayErrors}
          />
        </Grid>
        {showMemberInput && (
          <Grid item xs={12}>
            <TextField
              label="Medlemsnummer"
              type="number"
              onChange={onChange}
              value={membersNumber}
              name="membersNumber"
              errorText={validationErrors.membersNumber}
              displayError={displayErrors}
            />
          </Grid>
        )}
        {showKommuneInput && (
          <Grid item xs={12}>
            <TextField
              label="Kommune"
              onChange={onChange}
              value={kommune}
              name="kommune"
              type="text"
              errorText={validationErrors.kommune}
              displayError={displayErrors}
            />
          </Grid>
        )}
        <Grid item xs={12} className={classes.emailWrapper}>
          <TextField
            label="E-post"
            value={email}
            name="email"
            type="email"
            onChange={onChange}
            errorText={validationErrors.email}
            displayError={displayErrors}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

PersonaliaForm.propTypes = {
  classes: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onDropdownChange: PropTypes.func.isRequired,
  displayErrors: PropTypes.bool.isRequired,
  validationErrors: PropTypes.object.isRequired,
  showMemberInput: PropTypes.bool.isRequired,
  showKommuneInput: PropTypes.bool.isRequired,
  fetchPersonaliaFromNumber: PropTypes.func.isRequired,
  customerNotFound: PropTypes.bool.isRequired,
};

export default withStyles(styles)(PersonaliaForm);
