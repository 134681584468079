import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import dalsbygda from 'static/icons/dalsbygda.svg';

const styles = theme => ({
  cardFront: {
    position: 'absolute',
    background: theme.gradients.dark,
    '&:hover': {
      opacity: 0.9,
    },
  },
  content: {
    height: 'inherit',
    padding: 20,
  },
  title: {
    fontFamily: theme.fonts.vollkorn,
    fontSize: 16,
    fontWeight: 500,
    display: 'block',
    color: theme.colors.fontColor2,
  },
  area: {
    fontFamily: theme.fonts.barlow,
    fontSize: 14,
    fontWeight: 300,
    color: 'rgba(220,210,160,1)',
  },
  icon: {
    width: 50,
    height: 60,
  },
  iconLarge: {
    width: 75,
    height: 75,
    float: 'right',
  },
  titleContainer: {
    width: '80%',
    float: 'left',
  },
  titleContainerLarge: {
    height: '50%',
  },
  iconContainer: {
    float: 'right',
  },
  iconContainerLarge: {
    height: '50%',
  },
});

const OfferCardFront = ({ classes, offer, sizeClass, large, onOfferCardClick }) => {
  const titleContainerClass = large ? classes.titleContainerLarge : classes.titleContainer;
  const iconContainerClass = large ? classes.iconContainerLarge : classes.iconContainer;
  const iconClass = large ? classes.iconLarge : classes.icon;
  return (
    <Paper
      onClick={() => onOfferCardClick(offer)}
      className={`${sizeClass} ${classes.cardFront}`}
      elevation={1}>
      <div className={classes.content}>
        <div className={iconContainerClass}>
          <img className={iconClass} src={dalsbygda} alt="logo" />
        </div>
        <div className={titleContainerClass}>
          <span className={`${classes.title} ${classes.titleFront}`}>
            {offer.title.toUpperCase()}
          </span>
          <span className={classes.area}>{offer.tilbyderName}</span>
        </div>
      </div>
    </Paper>
  );
};

OfferCardFront.propTypes = {
  classes: PropTypes.object.isRequired,
  offer: PropTypes.object.isRequired,
  sizeClass: PropTypes.string.isRequired,
  large: PropTypes.bool.isRequired,
  onOfferCardClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(OfferCardFront);
