import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
  STAGE_OFFER,
  STAGE_TIME,
  STAGE_ADDITIONAL,
  STAGE_PERSONALIA,
  resetPurchase,
} from 'store/actions/order';
import { removeState } from 'store/index';
import PropTypes from 'prop-types';
import PurchaseSidebar from 'components/Purchase/PurchaseSidebar';
import PurchaseRoutes from 'components/Purchase/PurchaseRoutes';

class PurchaseContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sale: true,
    };
    this.onSaleClick = this.onSaleClick.bind(this);
    this.onInfoClick = this.onInfoClick.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
    this.onLinkClick = this.onLinkClick.bind(this);
  }

  onSaleClick() {
    this.setState({ sale: true });
  }

  onInfoClick() {
    this.setState({ sale: false });
  }

  onCancelClick() {
    removeState();
    this.props.history.push({ pathname: '/', state: { duration: 300, transition: 'cancel' } });
    setTimeout(() => {
      // Cannot reset purchase before route transition is finished
      this.props.resetPurchase();
    }, 500);
  }

  onLinkClick(route) {
    this.props.history.push(route);
  }

  render() {
    const { sale } = this.state;
    const { match, order, location } = this.props;
    const sidebar = [STAGE_OFFER, STAGE_TIME, STAGE_ADDITIONAL, STAGE_PERSONALIA].includes(
      order.stage
    );
    return (
      <div>
        {sidebar && (
          <div>
            <PurchaseSidebar
              order={order}
              sale={sale}
              onSaleClick={this.onSaleClick}
              onInfoClick={this.onInfoClick}
              onCancelClick={this.onCancelClick}
              onLinkClick={this.onLinkClick}
            />
          </div>
        )}
        <PurchaseRoutes sale={sale} match={match} location={location} />
      </div>
    );
  }
}

PurchaseContainer.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  resetPurchase: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  order: state.get.order,
});

export default withRouter(
  connect(
    mapStateToProps,
    { resetPurchase }
  )(PurchaseContainer)
);
