import { getRequest } from 'store/actions/request';
import { requestBegin, requestEnd } from 'store/actions/status';

export const CREATE_REPORT_SUCCESS = 'CREATE_REPORT_SUCCESS';
export const FETCH_TILBYDERE_SUCCESS = 'FETCH_TILBYDERE_SUCCESS';

function getReportSuccess(report) {
  return {
    type: CREATE_REPORT_SUCCESS,
    report,
  };
}

function fetchTilbydereSuccess(tilbydere) {
  return {
    type: FETCH_TILBYDERE_SUCCESS,
    tilbydere,
  };
}

export const fetchTilbydere = () => async dispatch => {
  dispatch(requestBegin(FETCH_TILBYDERE_SUCCESS));
  const { json } = await getRequest('/api/v1/forhandlere/tilbydere');
  dispatch(requestEnd(FETCH_TILBYDERE_SUCCESS));
  return dispatch(fetchTilbydereSuccess(json));
};

export const createReport = filters => async dispatch => {
  dispatch(requestBegin(CREATE_REPORT_SUCCESS));

  const fromDateString = filters.fromDate.getTime();
  const toDateString = filters.toDate.getTime();
  const typeString = filters.types
    .filter(t => t.active)
    .map(t => t.value)
    .join(',');

  let requestString = `/api/v1/forhandlere/rapport/${fromDateString}/${toDateString}?typer=${typeString}`;
  if (filters.selectedTilbyder.value !== 'all')
    requestString += `&tilbyder=${filters.selectedTilbyder.value}`;

  let response;
  try {
    response = await getRequest(requestString);

    /* eslint-disable no-await-in-loop */
    while (!response.json.fullfort) {
      await timer(1000);
      response = await getRequest(`/api/v1/forhandlere/rapport/${response.json.id}`);
    }
    /* eslint-enable no-await-in-loop */
  } catch (error) {
    dispatch(requestEnd(CREATE_REPORT_SUCCESS));
    return error;
  }

  dispatch(requestEnd(CREATE_REPORT_SUCCESS));
  return dispatch(getReportSuccess(response.json));
};

function timer(ms) {
  return new Promise((res) => {
    setTimeout(res, ms);
  });
}
