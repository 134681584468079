import React from 'react';
import { Route, Redirect, withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { validateCookie } from 'store/actions/auth';
import ErrorBoundary from 'components/Error/ErrorBoundary';

class PrivateRoute extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (!this.props.authValidated) {
      this.props.validateCookie();
    }
  }

  shouldComponentUpdate(nextProps) {
    if (
      nextProps.isAuthenticated === this.props.isAuthenticated &&
      nextProps.authValidated === this.props.authValidated
    ) {
      return false;
    }
    return true;
  }

  render() {
    const { isAuthenticated, authValidated, component, render, ...rest } = this.props;
    if (authValidated) {
      if (isAuthenticated) {
        return component ? (
          <ErrorBoundary>
            <Route {...rest} component={component} />
          </ErrorBoundary>
        ) : (
          <ErrorBoundary>
            <Route {...rest} render={render} />
          </ErrorBoundary>
        );
      } else {
        return <Redirect to="/login" />;
      }
    } else {
      return null;
    }
  }
}

PrivateRoute.propTypes = {
  component: PropTypes.func,
  render: PropTypes.func,
  isAuthenticated: PropTypes.bool.isRequired,
  authValidated: PropTypes.bool.isRequired,
  validateCookie: PropTypes.func.isRequired,
};

PrivateRoute.defaultProps = {
  component: null,
  render: null,
};

const mapStateToProps = state => ({
  isAuthenticated: state.get.auth.isAuthenticated,
  authValidated: state.get.auth.authValidated,
});

export default withRouter(
  connect(
    mapStateToProps,
    { validateCookie }
  )(PrivateRoute)
);
