import React from 'react';
import { Route, Switch } from 'react-router';
import LoginContainer from 'containers/LoginContainer';
import HomeContainer from 'containers/HomeContainer';
import SalesContainer from 'containers/SalesContainer';
import ReportsContainer from 'containers/ReportsContainer';
import PurchaseContainer from 'containers/PurchaseContainer';
import NotFound from 'components/Error/NotFound';
import Unauthorized from 'components/Error/Unauthorized';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { withRouter } from 'react-router-dom';
import PrivateRoute from 'containers/PrivateRoute';

export default withRouter(({ location }) => (
  <TransitionGroup
    childFactory={child =>
      React.cloneElement(child, {
        classNames: location.state ? location.state.transition : 'default',
        timeout: location.state && location.state.duration ? location.state.duration : 400,
      })
    }>
    <CSSTransition key={location.key} timeout={200} classNames="default" unmountOnExit>
      <Switch location={location}>
        <Route exact path="/login" component={LoginContainer} />
        <PrivateRoute exact path="/" component={HomeContainer} />
        <PrivateRoute path="/sales" component={SalesContainer} />
        <PrivateRoute path="/reports" component={ReportsContainer} />
        <PrivateRoute path="/purchase" component={PurchaseContainer} />
        <Route path="/unauthorized" component={Unauthorized} />
        <Route path="*" component={NotFound} />
      </Switch>
    </CSSTransition>
  </TransitionGroup>
));
