import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import OtherOptions from 'components/OtherOptions';
import {
  setPurchaseStage,
  STAGE_ADDITIONAL,
  UPDATE_OPTIONS_SUCCESS,
  updateOptions,
  setPrice,
  addToPrice,
  removeFromPrice,
} from 'store/actions/order';

class OtherOptionsContainer extends React.Component {
  constructor(props) {
    super(props);
    const { order } = this.props;
    this.state = {
      activeOptions: order.purchase.valg ? order.purchase.valg : [],
    };
    this.onOptionClick = this.onOptionClick.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
  }

  componentDidMount() {
    this.props.setPurchaseStage(STAGE_ADDITIONAL);
    this.props.setPrice(this.props.order.purchase.pris);
  }

  componentWillReceiveProps(nextProps) {
    const nextPurchase = nextProps.order.purchase;
    const prevPurchase = this.props.order.purchase;
    if (nextPurchase !== prevPurchase && nextPurchase.valg) {
      this.setState({ activeOptions: nextPurchase.valg });
    }
  }

  onNextClick() {
    const { order } = this.props;
    const { activeOptions } = this.state;
    this.props.updateOptions(order.purchase, activeOptions).then(() => {
      this.props.history.push({
        pathname: `/purchase/personalia/${order.purchase.id}`,
        state: { transition: 'next' },
      });
    });
  }

  onOptionClick(option, active) {
    const { order } = this.props;
    const { activeOptions } = this.state;
    const numberOfPurchaseItems = order.purchase.datovalg ? order.purchase.datovalg.length : 1;

    if (active) {
      this.removeOption(activeOptions, option, numberOfPurchaseItems);
    } else {
      this.addOption(activeOptions, option, numberOfPurchaseItems);
    }
  }

  addOption(activeOptions, option, numberOfItems) {
    const newActiveOptions = [...activeOptions, option];
    this.props.addToPrice(option.pris * numberOfItems);
    this.setState({
      activeOptions: newActiveOptions,
    });
  }

  removeOption(activeOptions, option, numberOfItems) {
    const newActiveOptions = activeOptions.filter(o => o.navn !== option.navn);
    this.props.removeFromPrice(option.pris * numberOfItems);
    this.setState({ activeOptions: newActiveOptions });
  }

  render() {
    const { order, updatingOptions } = this.props;
    const { activeOptions } = this.state;
    return (
      <OtherOptions
        options={order.period ? order.period.valg : []}
        activeOptions={activeOptions}
        updatingOptions={updatingOptions}
        purchase={order.purchase}
        onOptionClick={this.onOptionClick}
        onNextClick={this.onNextClick}
      />
    );
  }
}

OtherOptionsContainer.propTypes = {
  history: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  setPurchaseStage: PropTypes.func.isRequired,
  updateOptions: PropTypes.func.isRequired,
  setPrice: PropTypes.func.isRequired,
  addToPrice: PropTypes.func.isRequired,
  removeFromPrice: PropTypes.func.isRequired,
  updatingOptions: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  order: state.get.order,
  updatingOptions: state.get.actionsInProgress.includes(UPDATE_OPTIONS_SUCCESS),
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      setPurchaseStage,
      updateOptions,
      setPrice,
      addToPrice,
      removeFromPrice,
    }
  )(OtherOptionsContainer)
);
