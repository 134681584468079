import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import OffersContainer from 'containers/OffersContainer';
import OfferPeriodContainer from 'containers/OfferPeriodContainer';
import CalendarContainer from 'containers/CalendarContainer';
import OtherOptionsContainer from 'containers/OtherOptionsContainer';
import PersonaliaContainer from 'containers/PersonaliaContainer';
import SummaryContainer from 'containers/SummaryContainer';
import ReceiptContainer from 'containers/ReceiptContainer';
import { Route } from 'react-router';

const styles = theme => ({
  root: {
    position: 'absolute',
    background: theme.gradients.light,
    display: 'flex',
    minHeight: '100%',
    width: `calc(100% - ${theme.custom.sidebarWidth}px)`,
    marginLeft: theme.custom.sidebarWidth,
  },
  content: {
    flex: 1,
    padding: '30px 30px 30px 30px',
    position: 'relative',
    height: 'inherit',
  },
});

const PurchaseRoutes = ({ classes, match, location, sale }) => {
  const path = location.pathname;
  const wrapContent = ['offers', 'period', 'calendar', 'additional', 'personalia', 'hunter'].some(
    el => path.includes(el)
  );
  return (
    <div>
      {wrapContent && (
        <div className={classes.root}>
          <div className={`${classes.content} fade-in slide-up fade-out slide-down`}>
            <Route
              path={`${match.url}/offers/:type`}
              render={() => <OffersContainer sale={sale} />}
            />
            <Route path={`${match.url}/period/:id`} render={() => <OfferPeriodContainer />} />
            <Route path={`${match.url}/calendar/:id`} render={() => <CalendarContainer />} />
            <Route path={`${match.url}/additional/:id`} render={() => <OtherOptionsContainer />} />
            <Route path={`${match.url}/personalia`} render={() => <PersonaliaContainer />} />
            <Route
              path={`${match.url}/hunter`}
              render={() => <PersonaliaContainer showHunterValidation />}
            />
          </div>
        </div>
      )}
      <div>
        <div className="slide-up slide-down fade-out fade-in slide-out">
          <Route path={`${match.url}/summary`} render={() => <SummaryContainer />} />
          <Route path={`${match.url}/receipt`} render={() => <ReceiptContainer />} />
        </div>
      </div>
    </div>
  );
};

PurchaseRoutes.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  sale: PropTypes.bool.isRequired,
};

export default withStyles(styles)(PurchaseRoutes);
