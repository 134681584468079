import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  mixins: {
    toolbar: {
      height: 80,
    },
  },
  palette: {
    primary: {
      main: 'rgba(50, 55, 50, 1)',
    },
    secondary: {
      main: 'rgba(50, 80, 50, 1)',
    },
  },
  typography: {
    fontFamily: [],
  },
  overrides: {
    MuiAppBar: {
      root: {
        boxShadow: 'none',
      },
    },
    MuiTab: {
      label: {
        fontSize: '1rem',
      },
    },
    MuiDrawer: {
      paper: {
        border: 'none',
      },
    },
  },
  custom: {
    sidebarWidth: 300,
    homeSidebarWidth: 500,
    homeSidebarWidthLarge: 700,
    menubarHeight: 80,
    cardHeight: 110,
    cardWidth: 315,
    cardHeightLarge: 200,
  },
  fonts: {
    barlow: 'barlow',
    barlowCondensed: 'barlow-condensed',
    barlowSemiCondensed: 'barlow-semi-condensed',
    vollkorn: 'vollkorn',
  },
  colors: {
    buttonPrimary: 'rgba(50, 55, 50, 1)',
    buttonPrimaryGradient: 'linear-gradient(to top, rgba(50,55,50,1), rgba(40,50,40,1))',
    buttonSecondary: 'rgba(50, 80, 50, 1)',
    buttonSuccess: 'rgba(20,120,50,1)',
    buttonDanger: 'rgba(189, 9, 9, 1)',
    menuBackground: 'rgba(253, 252, 249, 1)',
    menuFontColor: 'rgba(50, 55, 50, 1)',
    fontColor1: 'rgba(50, 55, 50, 1)',
    fontColor2: 'rgba(253,252,249,1)',
    backgroundColor1: 'rgba(253,252,249,1)',
    inputLabel: 'rgba(49,45,49,1)',
    bulletPoint: 'rgba(20,120,50,1)',
  },
  gradients: {
    dark: 'linear-gradient(to top, rgba(40,50,40,1), rgba(50,55,50,1))',
    light: 'linear-gradient(to top left, rgba(210,210,210,0.15), rgba(253,252,249,1))',
  },
  divider: {
    primaryDashed: '1px dashed rgba(70,130,90,1)',
    secondary: '1px solid rgba(50,55,50,1)',
    secondaryDashed: '1px dashed rgba(50,55,50,1)',
  },
  margins: {
    contentMarginTop: '130px',
    xs: '8px',
    small: '16px',
    medium: '32px',
    large: '64px',
  },
});
