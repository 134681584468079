import React from 'react';
import PropTypes from 'prop-types';
import { Typography, FormControl, withStyles } from '@material-ui/core';
import Button from 'components/Common/Button';
import TextField from 'components/Common/TextField';

const styles = (theme) => ({
  formControl: {
    marginBottom: '2em',
  },
  errorText: {
    fontFamily: theme.fonts.barlow,
    fontSize: 15,
    color: 'rgba(189, 9, 9, 1)',
  },
  label: {
    color: 'rgba(49,45,49,1)',
  },
  button: {
    marginTop: '2rem',
  },
  input: {
    border: '1px solid #D2D2D2',
    padding: '0.2em',
    color: 'rgba(49,45,49,1)',
  },
});

const LoginForm = ({
  classes,
  username,
  password,
  errors,
  onChange,
  onSubmit,
  isAuthenticating,
}) => (
  <form onSubmit={onSubmit}>
    <FormControl fullWidth className={classes.formControl}>
      <TextField label="E-post" value={username} name="username" onChange={onChange} />
    </FormControl>
    <FormControl fullWidth className={classes.formControl}>
      <TextField
        label="Passord"
        value={password}
        name="password"
        type="password"
        onChange={onChange}
      />
    </FormControl>

    {errors.length > 0 && (
      <Typography className={classes.errorText}>{errors[0].message.toUpperCase()}</Typography>
    )}

    <Button
      theme="tetriary"
      onClick={() => {
        window.location.href =
          'https://auth.inatur.no/realms/inatur/login-actions/reset-credentials?client_id=forhandler_spa&ui_locale=no&kc_locale=no';
      }}
      label="GLEMT PASSORD?"
    />

    <Button
      className={classes.button}
      theme="secondary"
      fullWidth
      onClick={onSubmit}
      type="submit"
      label="LOGG INN"
      loading={isAuthenticating}
      loadingLabel="LOGGER INN"
    />
  </form>
);

LoginForm.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  errors: PropTypes.array.isRequired,
  isAuthenticating: PropTypes.bool.isRequired,
};

export default withStyles(styles)(LoginForm);
