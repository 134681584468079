import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 2,
    marginTop: 100,
    marginLeft: 50,
    marginRight: 50,
  },
  header: {
    fontFamily: theme.fonts.barlowSemiCondensed,
    fontSize: 40,
  },
  text: {
    fontFamily: theme.fonts.barlow,
    fontSize: 18,
  },
});

const NotFound = ({ classes }) => (
  <div className={classes.root} elevation={1}>
    <Typography className={classes.header} variant="headline" component="h3">
      404
    </Typography>
    <Typography className={classes.text} component="p">
      Angitt ressurs ble ikke funnet. <Link to="/">Gå tilbake</Link>
    </Typography>
  </div>
);

NotFound.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NotFound);
