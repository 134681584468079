import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  mainHeader: {
    fontFamily: theme.fonts.barlow,
    color: theme.colors.menuFontColor,
    fontSize: 18,
    display: 'block',
    marginBottom: 10,
  },
  subHeader: {
    fontFamily: theme.fonts.barlow,
    color: 'rgba(49, 45, 49, 1)',
    fontSize: 14,
    display: 'block',
    marginBottom: 10,
  },
  value: {
    fontFamily: theme.fonts.barlowSemiCondensed,
    color: theme.colors.menuFontColor,
    fontSize: 16,
    display: 'block',
  },
  valueGroup: {
    paddingTop: 5,
    paddingBottom: 5,
  },
});

const ContactInfo = ({ classes }) => (
  <div>
    <span className={classes.mainHeader}>Kontaktinformasjon</span>
    <p className={classes.valueGroup}>
      <span className={classes.subHeader}>Telefon</span>
      <span className={classes.value}>+47 74 21 30 90</span>
    </p>
    <p className={classes.valueGroup}>
      <span className={classes.subHeader}>Epost</span>
      <span className={classes.value}>post@inatur.no</span>
    </p>
    <p className={classes.valueGroup}>
      <span className={classes.subHeader}>Man - Fre</span>
      <span className={classes.value}>08:00 - 16:00</span>
    </p>
  </div>
);

ContactInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ContactInfo);
