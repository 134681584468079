export const FETCH_TYPES_SUCCESS = 'FETCH_TYPES_SUCCESS';

const fetchTypesSuccess = types => ({
  type: FETCH_TYPES_SUCCESS,
  types,
});

export const fetchTypes = () => dispatch => dispatch(fetchTypesSuccess(types));

const types = [
  {
    name: 'INNLANDSFISKETILBUD',
    title: 'Innlandsfiske',
  },
  {
    name: 'SMAAVILTTILBUD',
    title: 'Jakt / Småvilt',
    jakttype: 'SMAAVILT',
  },
  {
    name: 'STORVILTTILBUD',
    title: 'Jakt / storvilt',
    jakttype: 'STORVILT',
  },
  {
    name: 'LAKSEFISKETILBUD',
    title: 'Laksefiske',
  },
];
