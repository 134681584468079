import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Personalia from 'components/Personalia';
import {
  setPurchaseStage,
  STAGE_PERSONALIA,
  UPDATE_CUSTOMER_SUCCESS,
  setCustomer,
  fetchCustomerByPhone,
  updateCustomer,
} from 'store/actions/order';
import HunterContainer from 'containers/HunterContainer';
import { withRouter } from 'react-router';
import {
  validationRunner,
  runValidations,
  required,
  validEmail,
} from 'utils/validation/validation';

class PersonaliaContainer extends React.Component {
  constructor(props) {
    super(props);
    const { order } = this.props;

    this.fieldValidations = [
      validationRunner('countryCode', 'Landskode', required),
      validationRunner('mobileNumber', 'Mobilnummer', required),
      validationRunner('zipCode', 'Postnummer', required),
      validationRunner('country', 'Land', required),
      validationRunner('name', 'Navn', required),
      validationRunner('email', 'E-post', required),
      validationRunner('email', 'E-post', validEmail),
    ];

    if (
      order.purchase.valg &&
      order.purchase.valg.filter(valg => valg.id === 'MEDLEM').length > 0
    ) {
      this.fieldValidations.push(validationRunner('membersNumber', 'Medlemsnummer', required));
    }
    if (
      order.purchase.valg &&
      order.purchase.valg.filter(valg => valg.id === 'KOMMUNE').length > 0
    ) {
      this.fieldValidations.push(validationRunner('kommune', 'Kommune', required));
    }

    this.state = {
      customer: {
        mobileNumber: order.customer ? order.customer.mobil.nummer : '',
        countryCode: order.customer ? order.customer.mobil.landskode : '+47',
        name: order.customer ? order.customer.navn : '',
        country: order.customer ? order.customer.land : 'no',
        zipCode: order.customer ? order.customer.postnummer : '',
        email: order.customer ? order.customer.epost : '',
        membersNumber: order.customer ? order.customer.medlemsnummer : '',
        kommune: order.customer ? order.customer.kommune : '',
      },
      validationErrors: {},
      displayErrors: false,
      customerNotFound: false,
    };

    this.state.validationErrors = runValidations(this.state.customer, this.fieldValidations);
    this.onChange = this.onChange.bind(this);
    this.onDropdownChange = this.onDropdownChange.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
    this.fetchPersonaliaFromNumber = this.fetchPersonaliaFromNumber.bind(this);
  }

  componentDidMount() {
    this.props.setPurchaseStage(STAGE_PERSONALIA);
  }

  onChange(event) {
    const { customer } = this.state;
    customer[event.target.name] = event.target.value;
    this.setState({ customer, validationErrors: runValidations(customer, this.fieldValidations) });
  }

  onDropdownChange(event) {
    const { customer } = this.state;
    this.setState({ customer: { ...customer, country: event ? event.value : 'no' } });
  }

  onNextClick() {
    if (this.isValid()) {
      const { order, type } = this.props;
      const customer = this.createCustomer();
      this.props.setCustomer(customer);
      this.props.updateCustomer(order.purchase.id, customer).then(() => {
        if (['SMAAVILTTILBUD', 'STORVILTTILBUD'].includes(type.name)) {
          this.props.history.push({
            pathname: `${this.props.match.url.replace(/personalia/i, 'hunter')}`,
            state: { transition: 'next' },
          });
        } else {
          this.props.history.push({
            pathname: `/purchase/summary/${order.purchase.id}`,
            state: { transition: 'next' },
          });
        }
      });
    }
  }

  fetchPersonaliaFromNumber() {
    const { mobileNumber } = this.state.customer;
    const { purchase } = this.props.order;
    this.props
      .fetchCustomerByPhone(this.removeWhitespaces(mobileNumber), purchase.id)
      .then(result => {
        if (result.customer) {
          const { customer } = this.state;
          customer.name = result.customer.navn;
          customer.country = result.customer.land;
          customer.zipCode = result.customer.postnummer;
          customer.email = result.customer.epost;
          this.setState({
            customer,
            validationErrors: runValidations(customer, this.fieldValidations),
          });
        } else {
          this.setState({ customerNotFound: true });
        }
      });
  }

  isValid() {
    return Object.keys(this.state.validationErrors).length === 0;
  }

  createCustomer() {
    const { customer } = this.state;
    return {
      mobil: {
        landskode: this.removeWhitespaces(customer.countryCode),
        nummer: this.removeWhitespaces(customer.mobileNumber),
      },
      navn: customer.name,
      land: customer.country,
      postnummer: this.removeWhitespaces(customer.zipCode),
      epost: customer.email,
      medlemsnummer: customer.membersNumber,
      kommune: customer.kommune,
    };
  }

  removeWhitespaces(value) {
    return value.replace(/\s/g, '');
  }

  render() {
    const { customer, validationErrors, displayErrors, customerNotFound } = this.state;
    const { updatingCustomer, order, showHunterValidation } = this.props;
    const showMemberInput = order.purchase.valg
      ? order.purchase.valg.filter(valg => valg.id === 'MEDLEM').length > 0
      : false;
    const showKommuneInput = order.purchase.valg
      ? order.purchase.valg.filter(valg => valg.id === 'KOMMUNE').length > 0
      : false;
    return showHunterValidation ? (
      <HunterContainer />
    ) : (
      <Personalia
        customer={customer}
        onChange={this.onChange}
        onDropdownChange={this.onDropdownChange}
        onNextClick={this.onNextClick}
        validationErrors={validationErrors}
        displayErrors={displayErrors}
        isValid={this.isValid()}
        showMemberInput={showMemberInput}
        showKommuneInput={showKommuneInput}
        updatingCustomer={updatingCustomer}
        fetchPersonaliaFromNumber={this.fetchPersonaliaFromNumber}
        customerNotFound={customerNotFound}
      />
    );
  }
}

PersonaliaContainer.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  setPurchaseStage: PropTypes.func.isRequired,
  setCustomer: PropTypes.func.isRequired,
  fetchCustomerByPhone: PropTypes.func.isRequired,
  updateCustomer: PropTypes.func.isRequired,
  updatingCustomer: PropTypes.bool.isRequired,
  order: PropTypes.object.isRequired,
  type: PropTypes.object.isRequired,
  showHunterValidation: PropTypes.bool,
};

PersonaliaContainer.defaultProps = {
  showHunterValidation: false,
};

const mapStateToProps = state => ({
  order: state.get.order,
  updatingCustomer: state.get.actionsInProgress.includes(UPDATE_CUSTOMER_SUCCESS),
  type: state.get.purchaseType,
});

export default withRouter(
  connect(
    mapStateToProps,
    { setPurchaseStage, setCustomer, fetchCustomerByPhone, updateCustomer }
  )(PersonaliaContainer)
);
