import React from 'react';
import PropTypes from 'prop-types';
import { Raven } from 'config';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 2,
    marginTop: 100,
    marginLeft: 50,
    marginRight: 50,
  },
  header: {
    fontFamily: theme.fonts.barlowSemiCondensed,
    fontSize: 40,
    marginBottom: theme.margins.medium,
  },
  text: {
    fontFamily: theme.fonts.barlow,
    fontSize: 18,
  },
  link: {
    cursor: 'pointer',
    color: 'blue',
    textDecoration: 'underline',
  },
});

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Raven.captureException(error, { extra: errorInfo });
  }

  render() {
    const { classes } = this.props;
    if (this.state.error) {
      return (
        <div className={classes.root} elevation={1}>
          <Typography className={classes.header} variant="headline" component="h3">
            Noe gikk galt
          </Typography>
          <Typography className={classes.text} component="p">
            Feilen har blitt rapportert, men klikk{' '}
            <span
              className={classes.link}
              onClick={() => Raven.lastEventId() && Raven.showReportDialog()}>
              her
            </span>{' '}
            for å fylle ut en rapport.
          </Typography>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}

ErrorBoundary.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default withStyles(styles)(ErrorBoundary);
