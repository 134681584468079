import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import OfferInformation from 'components/Offer/OfferInformation';
import crossIcon from 'static/icons/cross.svg';
import infoIcon from 'static/icons/info.svg';

const styles = theme => ({
  dialog: {
    width: `calc(100% - ${theme.custom.sidebarWidth}px)`,
    height: `calc(100% - ${theme.custom.menubarHeight}px)`,
    position: 'absolute',
    right: '2rem',
    overflow: 'scroll',
  },
  content: {
    marginTop: 50,
    padding: 70,
  },
  title: {
    fontFamily: theme.fonts.vollkorn,
    fontSize: 24,
    color: theme.colors.fontColor1,
    fontWeight: 'normal',
  },
  infoIcon: {
    padding: 10,
    borderRadius: '50%',
    border: `1px solid ${theme.colors.fontColor1}`,
  },
});

const InfoDialog = ({ classes, offer, active, onClose, onSellCardClick }) => (
  <div>
    <Dialog
      PaperProps={{ className: classes.dialog }}
      open={active}
      maxWidth={false}
      scroll="paper"
      onClose={onClose}>
      <DialogActions>
        <IconButton onClick={onClose} color="inherit">
          <img src={crossIcon} alt="" />
        </IconButton>
      </DialogActions>
      <DialogContent className={classes.content}>
        <img className={classes.infoIcon} src={infoIcon} alt="logo" />
        <OfferInformation offer={offer} onSellCardClick={onSellCardClick} />
      </DialogContent>
    </Dialog>
  </div>
);

InfoDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  offer: PropTypes.object.isRequired,
  active: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSellCardClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(InfoDialog);
