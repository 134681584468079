import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { addHours, format } from 'date-fns';
import { isFastperiode, isLoepende } from '../../utils/misc';

const styles = theme => ({
  content: {
    width: '100%',
    fontFamily: theme.fonts.barlowSemiCondensed,
    fontSize: 16,
    fontWeight: 500,
  },
  divider: {
    border: 'none',
    borderTop: '1px solid',
  },
  dividerDashed: {
    border: 'none',
    borderTop: '1px dashed',
  },
  header: {
    fontFamily: theme.fonts.barlowSemiCondensed,
    fontSize: 16,
    fontWeight: 600,
  },
  totalSum: {
    marginTop: 20,
    float: 'right',
    fontSize: 36,
  },
  price: {
    float: 'right',
  },
  period: {
    display: 'inline-block',
    maxWidth: 'calc(100% - 50px)',
  },
});

const formatDate = date => format(date * 1000, 'DD.MM.YYYY');

// TODO: Dette finnes ikke i salgsdetaljer-apiet i dag.
const findCardPeriod = (classes, details) => {
  const datovalg = details.datoValg;
  const card = details.kort;
  if (isFastperiode(card)) {
    const from = details.startDato;
    const to = details.sluttDato;
    return (
      <div>
        <span className={classes.name}>
          {formatDate(from)} - {formatDate(to)}
        </span>
        <span className={classes.price}>{card.pris} kr</span>
      </div>
    );
  }
  if (isLoepende(card)) {
    if (datovalg && card.varighet === 24) {
      return datovalg.map(valg => (
        <div key={valg.dato}>
          <span className={classes.name}>{formatDate(valg.dato)}</span>
          <span className={classes.price}>{valg.pris} kr</span>
        </div>
      ));
    } else {
      return (
        <div>
          <span className={classes.name}>
            {formatDate(datovalg[0].dato)}
            {' - '}
            {formatDate(addHours(datovalg[0].dato * 1000, card.varighet) / 1000)}
          </span>
          <span className={classes.price}>{datovalg[0].pris} kr</span>
        </div>
      );
    }
  }
  return null;
};

const SalePriceSummary = ({ classes, details }) => {
  const options = details.andreValg ? details.andreValg : [];
  const numberOfDays = details.datoValg ? details.datoValg.length : 1;
  const discounts = options
    .filter(option => option.rabatt)
    .map(discount => (
      <p key={discount.id}>
        <span>{discount.navn}</span>
        <span className={classes.price}>- {discount.pris * numberOfDays} kr</span>
      </p>
    ));
  const extras = options
    .filter(option => !option.rabatt)
    .map(extra => (
      <p key={extra.id}>
        <span>{extra.navn}</span>
        <span className={classes.price}>+ {extra.pris * numberOfDays} kr</span>
      </p>
    ));
  return (
    <div className={classes.content}>
      <h4 className={classes.header}>{details.kort.title}</h4>
      <div>
        <span>{findCardPeriod(classes, details)}</span>
      </div>
      {(discounts.length > 0 || extras.length > 0) && (
        <div>
          <hr className={classes.dividerDashed} />
          <h4 className={classes.header}>Rabatter og tillegg</h4>
          {discounts.length > 0 && <div>{discounts}</div>}
          {extras.length > 0 && <div>{extras}</div>}
        </div>
      )}
      <hr className={classes.divider} />
      <hr className={classes.divider} />
      <p className={classes.totalSum}>
        <span>{details.pris} kr</span>
      </p>
    </div>
  );
};

SalePriceSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  details: PropTypes.object.isRequired,
};

export default withStyles(styles)(SalePriceSummary);
