import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography } from '@material-ui/core';
import checkmark from 'static/icons/checkmark.svg';
import cross from 'static/icons/cross_white.svg';

const styles = theme => ({
  content: {
    display: 'flex',
  },
  centerText: {
    justifyContent: 'center',
  },
  success: {
    backgroundColor: 'rgba(20,120,50,1)',
    color: 'rgba(255,255,255,1)',
    padding: '16px',
    fontFamily: theme.fonts.barlow,
  },
  error: {
    backgroundColor: '#BD0909',
    color: 'rgba(255,255,255,1)',
    padding: '16px',
    fontFamily: theme.fonts.barlow,
  },
  icon: {
    alignSelf: 'center',
    marginRight: theme.margins.xs,
  },
  message: {
    alignSelf: 'center',
    fontFamily: theme.fonts.barlowSemiCondensed,
  },
});

const Status = ({ classes, style, className, message, variant, iconType, positionText }) => {
  const findStatusIcon = () => {
    switch (iconType) {
      case 'check':
        return <img src={checkmark} alt="" className={classes.icon} />;
      case 'close':
        return <img src={cross} alt="" className={classes.icon} />;
      default:
        return null;
    }
  };
  const classVariant = classes[variant];
  return (
    <Typography
      style={style}
      className={`${classVariant} ${classes.content} ${positionText === 'center' &&
        classes.centerText} ${className}`}>
      {findStatusIcon()}
      <span className={classes.message}>{message}</span>
    </Typography>
  );
};

Status.propTypes = {
  style: PropTypes.object,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['success', 'error']).isRequired,
  iconType: PropTypes.oneOf(['check', 'close']),
  positionText: PropTypes.oneOf(['center', 'left']),
};

Status.defaultProps = {
  iconType: null,
  className: null,
  style: null,
  positionText: 'center',
};

export default withStyles(styles)(Status);
