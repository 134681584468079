import { getRequest } from 'store/actions/request';
import { requestBegin, requestEnd } from 'store/actions/status';

export const FETCH_OFFERS_SUCCESS = 'FETCH_OFFERS_SUCCESS';
export const FETCH_OFFER_SUCCESS = 'FETCH_OFFER_SUCCESS';
export const FETCH_PERIODS_SUCCESS = 'FETCH_PERIODS_SUCCESS';
export const FETCH_PERIOD_AVAILABILITIES_SUCCESS = 'FETCH_PERIOD_AVAILABILITIES_SUCCESS';

const fetchOffersSuccess = offers => ({
  type: FETCH_OFFERS_SUCCESS,
  offers,
});

const fetchOfferSuccess = offer => ({
  type: FETCH_OFFER_SUCCESS,
  offer,
});

const fetchPeriodsSuccess = periods => ({
  type: FETCH_PERIODS_SUCCESS,
  periods,
});

const fetchPeriodAvailabilitiesSuccess = availabilities => ({
  type: FETCH_PERIOD_AVAILABILITIES_SUCCESS,
  availabilities,
});

export const fetchOffers = type => async dispatch => {
  dispatch(requestBegin(FETCH_OFFERS_SUCCESS));
  const { json } = await getRequest(`/api/v1/forhandlere/tilbud/?type=${type}`);
  dispatch(requestEnd(FETCH_OFFERS_SUCCESS));
  return dispatch(fetchOffersSuccess(json));
};

export const fetchOffer = id => async dispatch => {
  dispatch(requestBegin(FETCH_OFFER_SUCCESS));
  const { json } = await getRequest(`/api/v1/forhandlere/tilbud/${id}`);
  dispatch(requestEnd(FETCH_OFFER_SUCCESS));
  return dispatch(fetchOfferSuccess(json));
};

export const fetchPeriods = id => async dispatch => {
  dispatch(requestBegin(FETCH_PERIODS_SUCCESS));
  const { json } = await getRequest(`/api/v1/forhandlere/tilbud/${id}/kort`);
  dispatch(requestEnd(FETCH_PERIODS_SUCCESS));
  return dispatch(fetchPeriodsSuccess(json));
};

export const fetchPeriodAvailabilities = (offerId, periodId, from, to) => async dispatch => {
  const { json } = await getRequest(
    `/api/v1/forhandlere/tilbud/${offerId}/kort/${periodId}/tilgjengeligheter?from=${from}&to=${to}`
  );
  return dispatch(fetchPeriodAvailabilitiesSuccess(json));
};
