import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import TypeCard from 'components/Home/TypeCard';

const styles = theme => ({
  root: {
    flexGrow: 1,
    paddingTop: 200,
    height: 'inherit',
    padding: 50,
    marginLeft: theme.custom.homeSidebarWidth - 300,
    marginRight: 50,
  },
});

function Home({ classes, types, active, onTypeClick }) {
  const cards = types.map(type => (
    <Grid key={type.title} item>
      <TypeCard
        key={type.title}
        onTypeClick={active ? () => {} : onTypeClick}
        type={type}
        active={active}
      />
    </Grid>
  ));
  return (
    <div className={classes.root}>
      <Grid container spacing={16} justify="flex-end">
        <Grid item sm={12} md={12} lg={9} xl={7}>
          <Grid container justify="flex-end" spacing={16}>
            {cards}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

Home.propTypes = {
  types: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  active: PropTypes.bool.isRequired,
  onTypeClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(Home);
