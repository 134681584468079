import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { findTypeIcon } from 'utils/misc';
import isoCountries from 'static/countries.json';
import { format } from 'date-fns';
import nbLocale from 'date-fns/locale/nb';

const styles = theme => ({
  header: {
    fontFamily: theme.fonts.vollkorn,
    fontSize: '24pt',
    fontWeight: 'normal',
  },
  status: {
    display: 'inline-block',
    width: 125,
    fontFamily: theme.fonts.barlow,
    textTransform: 'capitalize',
    marginLeft: 20,
    color: theme.palette.secondary.main,
  },
  personalia: {
    marginTop: 60,
    fontFamily: theme.fonts.barlow,
    fontSize: 16,
    fontWeight: 300,
  },
  name: {
    fontSize: 20,
  },
  icon: {
    width: 40,
    height: 40,
  },
  divider: {
    border: 'none',
    borderTop: '1px solid',
  },
});

const SaleSummary = ({ classes, details }) => {
  const title = details.tilbud ? details.tilbud.title : 'title';
  const customer = details.betaler;

  const date = format(new Date(details.created), 'dddd, DD.MM.YYYY', { locale: nbLocale });

  const country = isoCountries.filter(
    isoCountry => isoCountry.value === customer.land.toLowerCase()
  )[0];
  return (
    <div>
      <div>
        <img className={classes.icon} src={findTypeIcon(details.tilbud.type, true)} alt="" />
        <p className={classes.status}>SOLGT - {date}</p>
      </div>
      <h1 className={classes.header}>{title}</h1>
      <hr className={classes.divider} />
      {customer && (
        <div className={classes.personalia}>
          <p className={classes.name}>{customer.navn}</p>
          <p>{customer.epost}</p>
          <p>
            {customer.mobil.landskode} {customer.mobil.nummer}
          </p>
          <p>{country.label}</p>
        </div>
      )}
    </div>
  );
};

SaleSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  details: PropTypes.object.isRequired,
};

export default withStyles(styles)(SaleSummary);
