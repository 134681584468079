import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Common/Button';
import { Grid, withStyles } from '@material-ui/core';

const styles = theme => ({
  buttonRow: {
    marginTop: 50,
    borderTop: '1px solid rgba(230,230,230,1)',
    paddingTop: theme.margins.medium,
  },
});

function SaleEditButtons({ classes, onCloseClick, onSaveClick, isValid }) {
  return (
    <div className={classes.buttonRow}>
      <Grid container spacing={16} alignContent="center">
        <Grid item xs={12} sm={3} md={3} lg={3}>
          <Button
            fullWidth
            className={classes.button}
            theme="tetriary"
            onClick={onCloseClick}
            label="AVBRYT"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Button
            disabled={!isValid}
            fullWidth
            className={classes.button}
            theme="secondary"
            onClick={onSaveClick}
            label="LAGRE"
          />
        </Grid>
      </Grid>
    </div>
  );
}

SaleEditButtons.propTypes = {
  classes: PropTypes.object.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
};

export default withStyles(styles)(SaleEditButtons);
