import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { findTypeIcon } from 'utils/misc';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
  card: {
    height: 200,
    width: 315,
    flexGrow: 1,
    background: theme.gradients.dark,
    '&:hover': {
      opacity: 0.9,
      cursor: 'pointer',
    },
  },
  content: {
    padding: '40px 30px 40px 30px',
  },
  title: {
    color: theme.colors.fontColor2,
    fontFamily: theme.fonts.barlow,
    fontSize: 18,
    fontWeight: 500,
  },
  iconContainer: {
    width: '50%',
    float: 'left',
  },
  titleContainer: {
    height: 'inherit',
    marginTop: 100,
  },
});

const TypeCard = ({ classes, onTypeClick, type, active }) => (
  <Paper
    onClick={() => {
      onTypeClick(type);
    }}
    className={classes.card}
    elevation={1}
    style={Object.assign({}, active && { opacity: 0.3 })}>
    <div className={classes.content}>
      <div className={classes.iconContainer}>
        <img className={classes.icon} src={findTypeIcon(type.name)} alt="logo" />
      </div>
      <div className={classes.titleContainer}>
        <span className={classes.title}>{type.title}</span>
      </div>
    </div>
  </Paper>
);

TypeCard.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.object.isRequired,
  active: PropTypes.bool.isRequired,
  onTypeClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(TypeCard);
