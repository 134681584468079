import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';
import LoginForm from 'components/Login/LoginForm';
import logo from 'static/icons/inatur_logo.svg';

const style = (theme) => ({
  container: {
    position: 'absolute',
    height: '100%',
  },
  left: {
    background: 'rgba(253, 252, 249, 1)',
  },
  right: {
    background: theme.gradients.light,
  },
  content: {
    padding: '200px 80px 80px 80px',
    color: theme.colors.fontColor1,
  },
  divider: {
    border: 'none',
    borderBottom: `1px solid ${theme.colors.fontColor1}`,
    marginBottom: '3rem',
  },
  title: {
    marginBottom: theme.margins.small,
    fontFamily: theme.fonts.vollkorn,
    color: theme.colors.fontColor1,
    fontWeight: 'normal',
    fontSize: 24,
  },
  logo: {
    height: 40,
    width: 110,
    position: 'absolute',
    right: 30,
    top: 200 + 2 * theme.custom.cardHeightLarge,
  },
  statusMessage: {
    background: '#f6f2eb',
    padding: '1rem',
    border: '1px solid #eee5d8',
    fontFamily: 'barlow, sans-serif',
    lineHeight: 1.4,
    marginBottom: '3rem',
  },
});

const Login = ({ classes, onSubmit, onChange, errors, username, password, isAuthenticating }) => (
  <Grid container spacing={0} className={classes.container}>
    <Grid item xs={12} sm={7} md={7} lg={7} className={classes.left}>
      <div className={classes.content}>
        <Typography variant="display1" gutterBottom className={classes.title}>
          INATUR FORHANDLERLØSNING
        </Typography>
        <hr className={classes.divider} />
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <p className={classes.statusMessage}>
            <span style={{ fontWeight: 700 }}>
              Logger du inn for første gang etter 31. oktober?
            </span>
            <br />
            <br /> Før du kan logge inn første gang, må du verifisere din bruker. Følg lenken, logg
            inn og verifiser din e-postadresse i tilsendt e-post. Når det er gjort, blir du sendt
            tilbake hit.
            <br />
            <br />
            <a href="https://inatur.no/login?redirectUrl=https://forhandler.inatur.no">
              Verifiser e-postadresse
            </a>
          </p>
          <LoginForm
            onChange={onChange}
            onSubmit={onSubmit}
            username={username}
            password={password}
            errors={errors}
            isAuthenticating={isAuthenticating}
          />
        </Grid>
      </div>
    </Grid>
    <Grid item xs={12} sm={5} md={5} lg={5} className={classes.right}>
      <div>
        <img className={classes.logo} src={logo} alt="logo" />
      </div>
    </Grid>
  </Grid>
);

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  errors: PropTypes.array.isRequired,
  isAuthenticating: PropTypes.bool.isRequired,
};

export default withStyles(style)(Login);
