import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import 'react-select/dist/react-select.css';
import countries from 'static/countries.json';
import TextField from 'components/Common/TextField';
import MobilInput from 'components/Personalia/MobileInput';
import ButtonGroup from 'components/Common/ButtonGroup';
import SelectField from '../Common/SelectField';

const styles = theme => ({
  label: {
    fontFamily: theme.fonts.barlow,
    fontWeight: 500,
    color: 'rgba(49,45,49,1)',
    marginBottom: '1em',
  },
  dropdown: {
    fontFamily: theme.fonts.barlowSemiCondensed,
    border: '1pt solid rgba(210,210,210,1)',
    borderRadius: '1pt',
    padding: 2,
  },
  verticalDivider: {
    borderLeft: '1pt dashed green',
  },
  emailWrapper: {
    marginTop: '1em',
  },
  emailText: {
    fontFamily: theme.fonts.barlowSemiCondensed,
    fontWeight: 200,
    color: 'rgba(49,45,49,1)',
  },
  receiptText: {
    fontFamily: theme.fonts.barlowSemiCondensed,
    fontWeight: 200,
    color: 'rgba(49,45,49,1)',
  },
});

const SaleEditPersonaliaForm = ({
  classes,
  customer,
  onChange,
  onDropdownChange,
  onNorwegianSelect,
  onEnglishSelect,
  validationErrors,
  displayErrors,
  showMemberInput,
  showReceiptChoice,
  norwegian,
  fetchPersonaliaFromNumber,
  customerNotFound,
}) => {
  const findCountryIndex = code => countries.findIndex(c => c.value === code.toLowerCase());
  const { countryCode, mobileNumber, name, country, zipCode, email, membersNumber } = customer;
  return (
    <Grid container spacing={16}>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <MobilInput
          countryCode={countryCode}
          mobileNumber={mobileNumber}
          onChange={onChange}
          onClick={fetchPersonaliaFromNumber}
          errorText={validationErrors.mobileNumber}
          displayError={displayErrors}
          customerNotFound={customerNotFound}
        />
      </Grid>
      <div className={classes.verticalDivider} />
      <Grid container spacing={16} item xs={12} sm={12} md={6} lg={6}>
        <Grid item xs={12}>
          <TextField
            label="Navn"
            value={name}
            name="name"
            onChange={onChange}
            errorText={validationErrors.name}
            displayError={displayErrors}
          />
        </Grid>
        <Grid item xs={12} md={7}>
          <Typography variant="subheading" className={classes.label}>
            Land
          </Typography>
          <SelectField
            placeholder="Velg land"
            onChange={onDropdownChange}
            options={countries}
            value={countries[findCountryIndex(country)]}
            name="country"
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <TextField
            label="Postnummer"
            type="number"
            value={zipCode}
            name="zipCode"
            onChange={onChange}
            errorText={validationErrors.zipCode}
            displayError={displayErrors}
          />
        </Grid>
        {showMemberInput && (
          <Grid item xs={12}>
            <TextField
              label="Medlemsnummer"
              type="number"
              onChange={onChange}
              value={membersNumber}
              name="membersNumber"
              errorText={validationErrors.membersNumber}
              displayError={displayErrors}
            />
          </Grid>
        )}
        <Grid item xs={12} className={classes.emailWrapper}>
          <TextField
            label="E-post"
            value={email}
            name="email"
            onChange={onChange}
            errorText={validationErrors.email}
            displayError={displayErrors}
          />
        </Grid>
        {showReceiptChoice && (
          <Grid item xs={12}>
            <p className={classes.receiptText}>Kvittering sendes ut på:</p>
            <ButtonGroup
              bordered
              buttons={[
                {
                  label: 'NORSK',
                  onClick: onNorwegianSelect,
                  active: norwegian,
                },
                {
                  label: 'ENGELSK',
                  onClick: onEnglishSelect,
                  active: !norwegian,
                },
              ]}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

SaleEditPersonaliaForm.propTypes = {
  classes: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onDropdownChange: PropTypes.func.isRequired,
  onNorwegianSelect: PropTypes.func.isRequired,
  onEnglishSelect: PropTypes.func.isRequired,
  norwegian: PropTypes.bool.isRequired,
  displayErrors: PropTypes.bool.isRequired,
  validationErrors: PropTypes.object.isRequired,
  showMemberInput: PropTypes.bool.isRequired,
  showReceiptChoice: PropTypes.bool.isRequired,
  fetchPersonaliaFromNumber: PropTypes.func.isRequired,
  customerNotFound: PropTypes.bool.isRequired,
};

export default withStyles(styles)(SaleEditPersonaliaForm);
