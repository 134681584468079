import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, withStyles, FormHelperText } from '@material-ui/core';
import arrowLeft from 'static/icons/arrow_left.svg';
import DatePickerField from 'components/Common/DatePickerField';
import SelectField from 'components/Common/SelectField';
import Button from 'components/Common/Button';
import Tab from 'components/Common/Tab';

const styles = theme => ({
  label: {
    fontFamily: theme.fonts.barlow,
    fontWeight: 500,
    color: 'rgba(49,45,49,1)',
    marginBottom: '1em',
  },

  arrow: {
    display: 'inline-block',
    transform: 'scaleX(-1)',
    width: '32px',
    padding: '15px 10px',
    verticalAlign: 'bottom',
  },

  dateFieldContainer: {
    display: 'inline-block',
    width: 'calc(50% - 16px)',
  },

  dropdown: {
    fontFamily: theme.fonts.barlowSemiCondensed,
    border: '1pt solid rgba(210,210,210,1)',
    background: 'rgba(253, 252, 249, 1)',
    borderRadius: '1pt',
  },

  divider: {
    border: 'none',
    borderTop: '1px solid rgba(210,210,210,1)',
    width: '100%',
    marginTop: '1rem',
    marginBottom: '1rem',
  },

  submitButton: {
    marginTop: 40,
  },
});

const ReportPeriodFilter = ({
  classes,
  filters,
  tilbydere,
  onFromDayChange,
  onToDayChange,
  onTilbyderChange,
  onTypeChange,
  onSubmit,
  validationErrors,
  displayErrors,
  isValid,
  disabledFromDates,
  disabledToDates,
  loading,
}) => {
  const typeComponents = filters.types.map((type, i) => (
    <Grid key={type.value} item xs={12} sm={6} md={6} lg={6}>
      <Tab title={type.name} active={type.active} onClick={() => onTypeChange(i)} />
    </Grid>
  ));

  return (
    <Grid container spacing={24} alignContent="center">
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <div className={classes.dateFieldContainer}>
          <Typography variant="subheading" className={classes.label}>
            Fra
          </Typography>
          <DatePickerField
            onChange={onFromDayChange}
            selectedDay={filters.fromDate}
            disabledDays={disabledFromDates}
            displayError={displayErrors}
            errorText={validationErrors.fromDate}
          />
        </div>
        <img className={classes.arrow} src={arrowLeft} alt="" />
        <div className={classes.dateFieldContainer}>
          <Typography variant="subheading" className={classes.label}>
            Til
          </Typography>
          <DatePickerField
            onChange={onToDayChange}
            selectedDay={filters.toDate}
            disabledDays={disabledToDates}
            displayError={displayErrors}
            errorText={validationErrors.toDate}
          />
        </div>
        <div>
          {validationErrors.reportTimespan && <FormHelperText className={classes.errorText}>{validationErrors.reportTimespan}</FormHelperText>}
        </div>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Typography variant="subheading" className={classes.label}>
          Tilbyder
        </Typography>
        <SelectField
          onChange={onTilbyderChange}
          options={tilbydere}
          value={filters.selectedTilbyder}
          name="tilbyder"
          placeholder="Velg tilbyder"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Typography variant="subheading" className={classes.label}>
          Type
        </Typography>
        <Grid item xs={12}>
          <hr className={classes.divider} />
        </Grid>
        <Grid container spacing={16}>
          {typeComponents}
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Button
          className={classes.submitButton}
          disabled={!isValid}
          theme="secondary"
          fullWidth
          onClick={onSubmit}
          label="LAST NED .XLS"
          loading={loading}
        />
      </Grid>
      <Grid item xs={12}>
        <hr className={classes.divider} />
      </Grid>
    </Grid>
  );
};

ReportPeriodFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  tilbydere: PropTypes.array.isRequired,
  onFromDayChange: PropTypes.func.isRequired,
  onToDayChange: PropTypes.func.isRequired,
  onTilbyderChange: PropTypes.func.isRequired,
  onTypeChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  disabledFromDates: PropTypes.func.isRequired,
  disabledToDates: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  validationErrors: PropTypes.object.isRequired,
  displayErrors: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
};

export default withStyles(styles)(ReportPeriodFilter);
