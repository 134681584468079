import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { findTypeIcon } from 'utils/misc';
import isoCountries from 'static/countries.json';

const styles = theme => ({
  header: {
    fontFamily: theme.fonts.vollkorn,
    fontSize: 24,
    fontWeight: 'normal',
  },
  personalia: {
    marginTop: 60,
    fontFamily: theme.fonts.barlow,
    fontSize: 16,
    fontWeight: 300,
  },
  name: {
    fontSize: 20,
  },
  icon: {
    width: 40,
    height: 40,
  },
  divider: {
    border: 'none',
    borderTop: '1px solid',
  },
});

const PersonaliaSummary = ({ classes, order }) => {
  const title = order.offer ? order.offer.title : 'title';
  const customer = order.purchase ? order.purchase.betaler : null;
  // TODO: Kanskje hive på navn på land for betaler i api?
  const country = isoCountries.filter(
    isoCountry => isoCountry.value === customer.land.toLowerCase()
  )[0];
  return (
    <div>
      <img className={classes.icon} src={findTypeIcon(order.offer.type, true)} alt="" />
      <h1 className={classes.header}>{title}</h1>
      <hr className={classes.divider} />
      {customer && (
        <div className={classes.personalia}>
          <p className={classes.name}>{customer.navn}</p>
          <p>{customer.epost}</p>
          <p>
            {customer.mobil.landskode} {customer.mobil.nummer}
          </p>
          <p>{country.label}</p>
        </div>
      )}
    </div>
  );
};

PersonaliaSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
};

export default withStyles(styles)(PersonaliaSummary);
