import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';

const styles = theme => ({
  card: {
    background: 'linear-gradient(to bottom right, rgba(50,55,50,1), rgba(40,50,40,1))',
    height: 110,
    width: '100%',
    margin: 0,
    '&:hover': {
      opacity: 0.9,
      cursor: 'pointer',
    },
  },
  content: {
    position: 'relative',
    padding: '1em',
    height: '100%',
  },
  title: {
    fontFamily: theme.fonts.barlow,
    color: 'rgba(253,252,249,1)',
    padding: 'inherit',
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
  dot: {
    display: 'inline-block',
    float: 'left',
    height: 15,
    width: 15,
    backgroundColor: 'rgba(70,130,90,1)',
    borderRadius: '50%',
    marginRight: '12px',
  },
  square: {
    display: 'inline-block',
    float: 'left',
    height: 15,
    width: 15,
    backgroundColor: 'rgba(70,130,90,1)',
    marginRight: '12px',
  },
  longSquare: {
    overflow: 'hidden',
    height: 15,
    backgroundColor: 'rgba(70,130,90,1)',
  },

  inactive: {
    opacity: 0.4,
    '&:hover': {
      opacity: 0.4,
      cursor: 'inherit',
    },
  },
});

const PeriodCard = ({ classes, title, numberOfDays, numberOfWeeks, isSeason, isActive }) => {
  const daysIndicator = [...Array(numberOfDays).keys()].map(value => (
    <span key={value} className={classes.dot} />
  ));

  const weeksIndicator = [...Array(numberOfWeeks).keys()].map(value => (
    <span key={value} className={classes.square} />
  ));

  const seasonIndicator = isSeason ? <div className={classes.longSquare} /> : null;
  const cardClass = isActive ? classes.card : `${classes.card} ${classes.inactive}`;

  return (
    <Paper square className={cardClass}>
      <div className={classes.content}>
        <div className={classes.iconContainer}>
          {weeksIndicator}
          {daysIndicator}
          {seasonIndicator}
        </div>
        <div className={classes.title}>{title}</div>
      </div>
    </Paper>
  );
};

PeriodCard.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  numberOfDays: PropTypes.number.isRequired,
  numberOfWeeks: PropTypes.number.isRequired,
  isSeason: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default withStyles(styles)(PeriodCard);
