import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CalendarPage from 'components/OfferPeriod/PeriodCalendarPage';
import { fetchPeriodAvailabilities } from 'store/actions/offers';
import { isWithinRange, isSameDay, addDays, subDays, endOfMonth, startOfDay } from 'date-fns';
import {
  RESERVE_PURCHASE_SUCCESS,
  reservePurchase,
  addToPrice,
  removeFromPrice,
  setPrice,
} from 'store/actions/order';

class CalendarContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDateSelected: false,
      selectedDays: [],
      rangeLength: this.props.period.varighet ? Math.ceil(this.props.period.varighet / 24) : 0,
      time: '0',
    };
    this.onDaySelect = this.onDaySelect.bind(this);
    this.onRangeSelect = this.onRangeSelect.bind(this);
    this.onTimeChange = this.onTimeChange.bind(this);
    this.onMonthChange = this.onMonthChange.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
    this.dateIsDisabled = this.dateIsDisabled.bind(this);
  }

  componentDidMount() {
    const { period } = this.props;
    this.props.setPrice(0);
    this.findAvailabilities(period, new Date());
  }

  onDaySelect(day, { selected, disabled, past }) {
    if (disabled || past) return;
    const { period } = this.props;
    const { selectedDays } = this.state;
    const price = this.priceForRange(
      startOfDay(day),
      period.priser.perioder,
      period.priser.standardpris
    );
    if (selected) {
      const selectedIndex = selectedDays.findIndex(selectedDay => isSameDay(selectedDay, day));
      selectedDays.splice(selectedIndex, 1);
      this.props.removeFromPrice(price);
    } else {
      selectedDays.push(day);
      this.props.addToPrice(price);
    }
    this.setState({ selectedDays, isDateSelected: selectedDays.length > 0 });
  }

  onRangeSelect(day, { disabled, past }) {
    if (disabled || past) return;
    const { period } = this.props;
    const price = this.priceForRange(
      startOfDay(day),
      period.priser.perioder,
      period.priser.standardpris
    );
    this.props.setPrice(price);
    this.setState({
      selectedDays: [day],
      isDateSelected: true,
    });
  }

  onTimeChange(e) {
    if (e.target.value % 1800 === 0) {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  }

  onMonthChange(firstDayOfMonth) {
    const { period } = this.props;
    this.findAvailabilities(period, firstDayOfMonth);
  }

  onNextClick() {
    const { selectedDays, time } = this.state;
    const { offer, period } = this.props;
    const dates = selectedDays.map(day => this.dateToSeconds(day));
    this.props.reservePurchase(offer.id, period.id, dates, time).then(() => {
      if (period.valg) {
        this.props.history.push({
          pathname: `/purchase/additional/${period.id}`,
          state: { transition: 'next' },
        });
      } else {
        this.props.history.push({
          pathname: `/purchase/personalia/${period.id}`,
          state: { transition: 'next' },
        });
      }
    });
  }

  dateIsDisabled(date) {
    const { period, periodAvailabilities } = this.props;
    const dateString = date.getTime() / 1000;
    const availability = periodAvailabilities.find(a => isSameDay(a.date * 1000, date));
    return (
      (availability && availability.antall <= 0) ||
      dateString < period.sesong.fra ||
      dateString > period.sesong.til
    );
  }

  findAvailabilities(period, fromDate, toDate = null) {
    const { offer } = this.props;
    let to = toDate;
    if (!to) {
      to = addDays(endOfMonth(fromDate), 7);
    }
    this.props.fetchPeriodAvailabilities(
      offer.id,
      period.id,
      this.dateToSeconds(subDays(fromDate, 7)),
      this.dateToSeconds(to)
    );
  }

  dateToSeconds(date) {
    return Math.round(date / 1000);
  }

  priceForRange(date, priceChangePeriods, defaultPrice) {
    const periodFound = priceChangePeriods.find(period =>
      isWithinRange(date, new Date(period.fra * 1000), new Date(period.til * 1000))
    );
    return periodFound ? periodFound.pris : defaultPrice;
  }

  render() {
    const { period, reservingPurchase, periodAvailabilities } = this.props;
    const { rangeLength, selectedDays, isDateSelected, time } = this.state;
    return (
      <CalendarPage
        period={period}
        periodAvailabilities={periodAvailabilities}
        reservingPurchase={reservingPurchase}
        rangeLength={rangeLength}
        onDaySelect={this.onDaySelect}
        onRangeSelect={this.onRangeSelect}
        onMonthChange={this.onMonthChange}
        selectedDays={selectedDays}
        time={time}
        onTimeChange={this.onTimeChange}
        onNextClick={this.onNextClick}
        isDateSelected={isDateSelected}
        isDisabled={this.dateIsDisabled}
      />
    );
  }
}

CalendarContainer.propTypes = {
  history: PropTypes.object.isRequired,
  fetchPeriodAvailabilities: PropTypes.func.isRequired,
  reservePurchase: PropTypes.func.isRequired,
  setPrice: PropTypes.func.isRequired,
  addToPrice: PropTypes.func.isRequired,
  removeFromPrice: PropTypes.func.isRequired,
  period: PropTypes.object.isRequired,
  periodAvailabilities: PropTypes.array.isRequired,
  offer: PropTypes.object,
  reservingPurchase: PropTypes.bool.isRequired,
};

CalendarContainer.defaultProps = {
  offer: null,
};

const mapStateToProps = state => ({
  offer: state.get.order.offer,
  period: state.get.periodCard,
  periodAvailabilities: state.get.periodAvailabilities,
  reservingPurchase: state.get.actionsInProgress.includes(RESERVE_PURCHASE_SUCCESS),
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      fetchPeriodAvailabilities,
      reservePurchase,
      addToPrice,
      removeFromPrice,
      setPrice,
    }
  )(CalendarContainer)
);
