import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import OfferCardFront from 'components/Offer/OfferCardFront';
import OfferCardBack from 'components/Offer/OfferCardBack';

const styles = {
  cardContainer: {
    width: 315,
    flexGrow: 1,
    position: 'relative',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  cardFlipContainer: {
    transformStyle: 'preserve-3d',
    position: 'relative',
  },
  flip: {
    transform: 'rotateY(180deg)',
    transition: '0.5s',
  },
  flipInverse: {
    transform: 'rotateY(0deg)',
    transition: '0.5s',
  },
  cardSize: {
    height: 110,
    width: 315,
    backfaceVisibility: 'hidden',
  },
  cardSizeLarge: {
    height: 230,
    width: 315,
    backfaceVisibility: 'hidden',
  },
};

const OfferCard = ({ classes, offer, large, sale, onInfoOpen, onOfferCardClick }) => {
  const cardSize = large ? classes.cardSizeLarge : classes.cardSize;
  return (
    <div className={classes.cardContainer}>
      <div className={`${classes.cardFlipContainer} ${!sale ? classes.flip : classes.flipInverse}`}>
        <OfferCardFront
          offer={offer}
          large={large}
          sizeClass={cardSize}
          onOfferCardClick={onOfferCardClick}
        />
        <OfferCardBack offer={offer} sizeClass={cardSize} onInfoOpen={onInfoOpen} />
      </div>
    </div>
  );
};

OfferCard.propTypes = {
  classes: PropTypes.object.isRequired,
  offer: PropTypes.object.isRequired,
  large: PropTypes.bool.isRequired,
  sale: PropTypes.bool.isRequired,
  onInfoOpen: PropTypes.func.isRequired,
  onOfferCardClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(OfferCard);
