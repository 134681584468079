import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import crossIcon from 'static/icons/cross.svg';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
  closeButton: {
    position: 'absolute',
    right: 0,
  },
});

const CloseButton = ({ onClick, classes }) => (
  <div className={classes.closeButton}>
    <IconButton onClick={onClick} color="inherit" aria-label="Menu">
      <img src={crossIcon} alt="" />
    </IconButton>
  </div>
);

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CloseButton);
