import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  content: {
    width: '100%',
    padding: 14,
    border: '1px solid rgba(230,230,230,1)',
    borderRadius: '50px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  active: {
    backgroundColor: 'rgba(45,45,45,1)',
  },
  disabled: {
    opacity: 0.4,
    cursor: 'not-allowed',
  },
  name: {
    fontFamily: theme.fonts.barlowSemiCondensed,
    color: 'rgba(49,45,49,1)',
  },
  nameActive: {
    color: 'rgba(255,255,255,1)',
    fontWeight: 600,
  },
  extra: {
    float: 'right',
    fontFamily: theme.fonts.barlowCondensed,
    color: 'rgba(210,210,210,1)',
  },
  extraActive: {
    color: theme.colors.fontColor2,
  },
});

const OptionTab = ({ classes, title, extra, onClick, active, disabled }) => (
  <div
    className={`${classes.content} ${active && classes.active} ${disabled && classes.disabled}`}
    onClick={!disabled ? onClick : () => {}}>
    <span className={`${active && classes.nameActive} ${classes.name}`}>{title}</span>
    {extra && <span className={`${classes.extra} ${active && classes.extraActive}`}>{extra}</span>}
  </div>
);

OptionTab.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  extra: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

OptionTab.defaultProps = {
  disabled: false,
  extra: null,
};

export default withStyles(styles)(OptionTab);
