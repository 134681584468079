import { history } from 'index';
import * as Toastr from 'toastr';

const DEFAULT_ERROR_MSG =
  'Noe gikk galt. Vennligst prøv på nytt; hvis problemene vedvarer, kontakt systemadministrator';
const CHANGED_STATUS_MSG = 'Kjøpet kunne ikke fullføres på grunn av endret status, prøv igjen';

const acceptJsonHeader = {
  Accept: 'application/json',
  'Accept-Charset': 'utf-8',
};

const acceptPdfHeader = {
  Accept: 'application/pdf',
  'Accept-Charset': 'utf-8'
}

const contentTypeJsonHeader = {
  'Content-Type': 'application/json',
};

async function handleResponse(response) {
  switch (response.status) {
    case 401:
      history.push('/login');
      return {
        status: response.status,
      };
    case 404:
      history.push('/notfound');
      throw new Error(response.statusText);
    case 403:
    case 409:
    case 410:
      history.push('/');
      showError(CHANGED_STATUS_MSG);
      throw new Error(CHANGED_STATUS_MSG);
    default:
      break;
  }
  const contentType = response.headers.get('content-type');
  if (contentType && contentType.includes('json')) {
    const json = await response.json();
    if (!response.ok) {
      if (json.errors) {
        throw new Error(json.errors);
      }
      throw new Error(DEFAULT_ERROR_MSG);
    } else {
      return {
        status: response.status,
        json,
      };
    }
  } else if (contentType && contentType.includes('pdf')) {
    return response;
  } else if(response.ok) {
    return {
      status: response.status,
    };
  }
  throw new Error(DEFAULT_ERROR_MSG);
}

function showError(error) {
  Toastr.error(error, 'Error', {
    toastClass: 'error_toast',
    positionClass: 'toast-bottom-full-width',
  });
}

async function tryAjax(func) {
  try {
    const response = await func();
    return handleResponse(response);
  } catch (error) {
    return error;
  }
}

export const getRequest = (url, includeCredentials = true) =>
  tryAjax(() =>
    fetch(url, {
      method: 'GET',
      credentials: includeCredentials ? 'same-origin' : 'omit',
      headers: {
        ...acceptJsonHeader,
      },
    })
  );

export const postRequest = (url, data, includeCredentials = true) =>
  tryAjax(async () =>
    fetch(url, {
      method: 'POST',
      credentials: includeCredentials ? 'same-origin' : 'omit',
      headers: {
        ...acceptJsonHeader,
        ...contentTypeJsonHeader,
        ...await getCsrfHeader(),
      },
      body: JSON.stringify(data),
    })
  );

export const putRequest = (url, data, includeCredentials = true) =>
  tryAjax(async () =>
    fetch(url, {
      method: 'PUT',
      credentials: includeCredentials ? 'same-origin' : 'omit',
      headers: {
        ...acceptJsonHeader,
        ...contentTypeJsonHeader,
        ...await getCsrfHeader(),
      },
      body: JSON.stringify(data),
    })
  );

export const deleteRequest = (url, data, includeCredentials = true) =>
  tryAjax(async () =>
    fetch(url, {
      method: 'DELETE',
      credentials: includeCredentials ? 'same-origin' : 'omit',
      headers: {
        ...acceptJsonHeader,
        ...await getCsrfHeader(),
      },
      body: JSON.stringify(data),
    })
  );

  export const getPdfRequest = (url, data, includeCredentials = true) =>
    tryAjax(() =>
      fetch(url, {
        method: 'GET',
        credentials: includeCredentials ? 'same-origin' : 'omit',
        headers: {
          ...acceptPdfHeader,
        },
        body: JSON.stringify(data)
      })
    );

export async function getCsrfHeader () {
  return (
    fetch('/api/csrf-token', {
      method: 'GET',
      headers: {
        ...acceptJsonHeader,
        ...contentTypeJsonHeader,
      },
      credentials: 'same-origin',
    })
    .then(response => handleResponse(response))
    .then(handleResponseResult => {
      const { json } = handleResponseResult;
      if (json.headerName && json.token) {
        return { [json.headerName]: json.token };
      }
      throw new Error(DEFAULT_ERROR_MSG);
    })
    .catch(() => {
      showError(DEFAULT_ERROR_MSG);
    })
  )
}
