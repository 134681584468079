import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography, TextField, FormHelperText } from '@material-ui/core';

const styles = theme => ({
  label: {
    fontFamily: theme.fonts.barlow,
    fontWeight: 500,
    color: theme.colors.inputLabel,
    marginBottom: theme.margins.small,
  },
  input: {
    border: '1.33px solid rgba(210,210,210,1)',
    borderRadius: '1.33px',
    padding: 4,
    paddingLeft: 8,
    fontFamily: theme.fonts.barlowSemiCondensed,
  },
  errorText: {
    fontFamily: theme.fonts.barlow,
    fontSize: 12,
    color: 'rgba(189, 9, 9, 1)',
  },
  errorBorder: {
    border: '1.33px solid rgba(189, 9, 9, 1)',
  },
});

const CustomTextField = ({
  classes,
  label,
  errorText,
  displayError,
  placeholder,
  value,
  name,
  type,
  onChange,
}) => (
  <div>
    <Typography variant="subheading" className={classes.label}>
      {label}
    </Typography>
    <TextField
      autoComplete="off"
      fullWidth
      InputProps={{ disableUnderline: true }}
      className={`${classes.input} ${displayError && errorText && classes.errorBorder}`}
      onChange={onChange}
      value={value}
      name={name}
      type={type}
      placeholder={placeholder}
    />
    {displayError && <FormHelperText className={classes.errorText}>{errorText}</FormHelperText>}
  </div>
);

CustomTextField.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  displayError: PropTypes.bool,
  errorText: PropTypes.string,
};

CustomTextField.defaultProps = {
  value: '',
  name: '',
  type: 'text',
  placeholder: '',
  errorText: null,
  displayError: false,
};

export default withStyles(styles)(CustomTextField);
