import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import checkmark from 'static/icons/checkmark_green.svg';
import Button from 'components/Common/Button';

const styles = theme => ({
  content: {
    color: theme.colors.fontColor1,
    fontFamily: theme.fonts.barlow,
    fontSize: 16,
  },
  title: {
    fontFamily: theme.fonts.vollkorn,
    fontSize: 24,
    fontWeight: 'normal',
    textTransform: 'uppercase',
  },
  divider: {
    borderColor: 'rgba(70,130,90,1)',
    borderTop: 'none',
  },
  species: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyleType: 'none',
    padding: 0,
  },
  specie: {
    marginRight: '3rem',
    fontFamily: theme.fonts.barlowSemiCondensed,
    fontSize: 16,
    fontWeight: 500,
    color: theme.colors.fontColor1,
  },
  section: {
    marginTop: '3rem',
    marginBottom: '3rem',
  },
  sellButton: {
    color: theme.colors.fontColor2,
    marginRight: '1rem',
  },
  map: {
    width: '100%',
    left: 0,
  },
});

const OfferInformation = ({ classes, offer, isReceipt, viewMap, onSellCardClick }) => {
  const species = offer.arter
    ? offer.arter.map(specie => (
        <li className={classes.specie} key={specie}>
          <span>
            <img src={checkmark} alt="" /> {specie.toUpperCase()}
          </span>
        </li>
      ))
    : null;

  const sectionsToShow = [
    { title: 'Kvoter', content: offer.kvoter },
    { title: 'Regler', content: offer.regler },
    { title: 'Veibeskrivelse', content: offer.veibeskrivelse },
    { title: 'Mer detaljert beskrivelse', content: offer.beskrivelse},
  ];
  return (
    <div className={classes.content}>
      <h2 className={classes.title}>{offer.title}</h2>
      <hr className={classes.divider} />
      <div className={classes.section}>
        <ul className={classes.species}>{species}</ul>
      </div>
      <div className={classes.section}>
        <p>{offer.ingress}</p>
      </div>
      {!isReceipt && (
        <div className={classes.section}>
          <Button
            className={classes.sellButton}
            style={{ background: 'rgba(50,55,50,1)' }}
            onClick={() => {
              onSellCardClick(offer);
            }}
            label="SELG KORT"
          />
        </div>
      )}
      {viewMap && (
        <div className={classes.section}>
          <img className={classes.map} src={offer.kart} alt="" />
        </div>
      )}
      {sectionsToShow
        .filter(section => section.content !== null && section.content.length > 0)
        .map(section => (
          <div className={classes.section} key={section.title}>
            <h2 className={classes.title}>{section.title}</h2>
            <hr className={classes.divider} />
            <p dangerouslySetInnerHTML={{ __html: section.content }} />
          </div>
        ))}
        {offer.kontaktinformasjon && 
          <div className={classes.section}>
            <h2 className={classes.title}>Kontaktinformasjon</h2>
            <hr className={classes.divider} />
            <p>{`Tilbydernavn: ${offer.kontaktinformasjon.tilbyderName}`}</p>
            { offer.kontaktinformasjon.kontaktperson &&
              <p>{`Kontaktperson: ${offer.kontaktinformasjon.kontaktperson}`}</p>
            }
            <p>{`Telefonnummer: ${offer.kontaktinformasjon.telefonnummer}`}</p>
            <p>{`Epost: ${offer.kontaktinformasjon.epost}`}</p>
          </div>
        }
    </div>
  );
};

OfferInformation.propTypes = {
  classes: PropTypes.object.isRequired,
  offer: PropTypes.object.isRequired,
  isReceipt: PropTypes.bool,
  viewMap: PropTypes.bool,
  onSellCardClick: PropTypes.func,
};

OfferInformation.defaultProps = {
  isReceipt: false,
  viewMap: true,
  onSellCardClick: null,
};

export default withStyles(styles)(OfferInformation);
