import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, Tabs, Tab, IconButton } from '@material-ui/core';
import menuIcon from 'static/icons/icon_menu.svg';
import crossIcon from 'static/icons/cross.svg';
import markerDownIcon from 'static/icons/marker_down.svg';
import markerUpIcon from 'static/icons/marker_up.svg';

const styles = theme => ({
  root: {
    flexGrow: 1,
    position: 'absolute',
    height: theme.custom.menubarHeight,
  },
  flex: {
    flex: 1,
  },
  navbarHome: {
    background: 'rgba(255,255,255,0)',
    width: '100%',
    height: theme.custom.menubarHeight,
    maxHeight: theme.custom.menubarHeight,
    transition: 'max-height 0.2s linear',
    borderBottom: '1px solid rgba(230,230,230,0)',
  },
  navbarExpanded: {
    height: '130px !important',
    maxHeight: '130px !important',
    background: 'rgba(253,252,249,0.3)',
  },
  navbarBorder: {
    borderBottom: '1px solid rgba(230,230,230,1)',
  },
  title: {
    fontWeight: 300,
    fontSize: '16px',
    flex: 1,
  },
  font: {
    fontFamily: theme.fonts.barlowSemiCondensed,
    color: theme.colors.fontColor1,
  },
  menuItem: {
    fontWeight: 600,
    fontSize: 14,
    letterSpacing: 1,
    opacity: 1,
  },
  menuItemUser: {
    fontWeight: 300,
    fontSize: 15,
    opacity: 1,
  },
  fadeIn: {
    '-webkit-animation': 'fadein 1s',
    '-moz-animation': 'fadein 1s',
    '-ms-animation': 'fadein 1s',
    '-o-animation': 'fadein 1s',
    animation: 'fadein 1s',
  },
  indicator: {
    backgroundColor: 'inherit',
  },
  expanded: {
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
  dropdownMarker: {
    border: 'solid black',
    borderWidth: '0 1px 1px 0',
    display: 'inline-block',
    padding: 3,
    transform: 'rotate(45deg)',
  },
});

const Menu = ({
  active,
  activeItem,
  onToggleClick,
  onItemClick,
  onUserItemClick,
  classes,
  logout,
  expand,
  username,
}) => (
  <div className={classes.root}>
    <AppBar
      className={`${active && classes.navbarBorder} ${expand && classes.navbarExpanded} ${
        classes.navbarHome
      } menu`}
      color="default">
      <Toolbar>
        <IconButton onClick={onToggleClick} color="inherit" aria-label="Menu">
          {active ? <img src={crossIcon} alt="" /> : <img src={menuIcon} alt="" />}
        </IconButton>
        <Typography color="inherit" className={`${classes.title} ${classes.font}`}>
          MENY
        </Typography>
        {active && (
          <Tabs classes={{ indicator: classes.indicator }} value={activeItem}>
            <Tab
              className={classes.font}
              label={<span className={classes.menuItem}>SALGSHISTORIKK</span>}
              onClick={() => onItemClick('/sales')}
            />
            <Tab
              className={`${classes.font} ${classes.menuItem}`}
              label={<span className={classes.menuItem}>RAPPORTER</span>}
              onClick={() => onItemClick('/reports')}
            />
            <Tab
              className={`${classes.font} ${classes.menuItemUser}`}
              label={
                <span className={classes.menuItemUser}>
                  {username} <img src={expand ? markerUpIcon : markerDownIcon} alt="" />
                </span>
              }
              onClick={onUserItemClick}
            />
          </Tabs>
        )}
      </Toolbar>
      {expand && (
        <div className={classes.expanded}>
          <Toolbar>
            <Tabs classes={{ indicator: classes.indicator }} value={activeItem}>
              {/* TODO:
              <Tab
                style={{ minWidth: 0 }}
                className={`${classes.font} ${classes.fadeIn}`}
                label={<span className={classes.menuItem}>REDIGER PROFIL</span>}
              />
              */}
              <Tab
                style={{ minWidth: 0 }}
                className={`${classes.font} ${classes.fadeIn} ${classes.menuItem}`}
                label={<span className={classes.menuItem}>LOGG UT</span>}
                onClick={logout}
              />
            </Tabs>
          </Toolbar>
        </div>
      )}
    </AppBar>
  </div>
);

Menu.propTypes = {
  classes: PropTypes.object.isRequired,
  active: PropTypes.bool.isRequired,
  activeItem: PropTypes.number.isRequired,
  expand: PropTypes.bool.isRequired,
  username: PropTypes.string.isRequired,
  onItemClick: PropTypes.func.isRequired,
  onUserItemClick: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  onToggleClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(Menu);
