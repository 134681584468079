import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SearchField from 'components/Common/SearchField';
import OfferCard from 'components/Offer/OfferCard';
import OfferInfoDialog from 'components/Offer/OfferInfoDialog';
import Grid from '@material-ui/core/Grid';
import Loading from 'components/Common/Loading';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  search: {
    position: 'absolute',
    marginRight: 40,
    top: 0,
    right: 0,
  },
  offers: {
    marginTop: theme.margins.contentMarginTop,
  },
  loader: {
    position: 'absolute',
    top: 20,
    left: 30,
  },
});

const CARD_SIZE_LIMIT = 4;
const LETTER_SIZE_LIMIT = 80;

const Offers = ({
  classes,
  offers,
  loading,
  onTextChange,
  searchWord,
  sale,
  currentOffer,
  infoActive,
  onInfoClose,
  onInfoOpen,
  onOfferCardClick,
}) => {
  let maxTextLength = 0;
  const filtered = offers.filter(offer => {
    const currentLength = offer.title.length + offer.tilbyderName.length;
    if (currentLength > maxTextLength) {
      maxTextLength = currentLength;
    }
    return (
      offer.tilbyderName.toLowerCase().includes(searchWord.toLowerCase()) ||
      offer.title.toLowerCase().includes(searchWord.toLowerCase())
    );
  });
  const isLarge = offers.length <= CARD_SIZE_LIMIT || maxTextLength > LETTER_SIZE_LIMIT;
  const cards = filtered.map(offer => (
    <Grid key={offer.id} item>
      <OfferCard
        key={offer.id}
        offer={offer}
        large={isLarge}
        sale={sale}
        onInfoOpen={() => onInfoOpen(offer)}
        onOfferCardClick={onOfferCardClick}
      />
    </Grid>
  ));
  return (
    <div className={classes.root}>
      <div className={classes.search}>
        <SearchField
          type="text"
          name="search"
          placeholder="Søk etter område eller tilbyder"
          text={searchWord}
          onFieldChange={onTextChange}
          hasButton={false}
        />
      </div>
      <div className={classes.offers}>
        <Grid container spacing={16}>
          {cards}
        </Grid>
      </div>
      {currentOffer !== null && (
        <OfferInfoDialog
          offer={currentOffer}
          active={infoActive}
          onClose={onInfoClose}
          onSellCardClick={onOfferCardClick}
        />
      )}
      <Loading label="Laster inn" active={loading && offers.length === 0} />
    </div>
  );
};

Offers.propTypes = {
  classes: PropTypes.object.isRequired,
  onTextChange: PropTypes.func.isRequired,
  offers: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  searchWord: PropTypes.string.isRequired,
  sale: PropTypes.bool.isRequired,
  infoActive: PropTypes.bool.isRequired,
  currentOffer: PropTypes.object,
  onInfoClose: PropTypes.func.isRequired,
  onInfoOpen: PropTypes.func.isRequired,
  onOfferCardClick: PropTypes.func.isRequired,
};

Offers.defaultProps = {
  currentOffer: null,
};

export default withStyles(styles)(Offers);
