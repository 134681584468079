import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from 'components/Common/Button';

const styles = theme => ({
  root: {
    flex: 1,
  },
  button: {
    position: 'fixed',
    width: `calc(100% - ${theme.custom.sidebarWidth}px)`,
    bottom: 0,
    right: 0,
    height: 80,
    fontSize: 18,
  },
});

const NextButton = ({ classes, disabled, loading, text, icon, onClick }) => (
  <Button
    className={classes.button}
    theme="primarygradient"
    onClick={onClick}
    disabled={disabled}
    label={text}
    icon={icon}
    fullWidth
    loading={loading}
  />
);

NextButton.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

NextButton.defaultProps = {
  disabled: false,
  loading: false,
};

export default withStyles(styles)(NextButton);
