import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DayPicker from 'react-day-picker';
import markerLeftIcon from 'static/icons/marker_left.svg';
import markerRightIcon from 'static/icons/marker_right.svg';
import { isWithinRange, addDays, eachDay, startOfDay } from 'date-fns';
import { padStart } from '../../utils/misc';
import 'react-day-picker/lib/style.css';
import 'components/OfferPeriod/calendar.css';

const styles = theme => ({
  footer: {
    marginTop: 20,
    width: '100%',
  },
  timeInput: {
    height: 40,
    width: 85,
    textAlign: 'center',
    fontFamily: theme.fonts.barlowSemiCondensed,
    fontSize: 20,
    backgroundColor: 'white',
    border: 'none',
  },
  infoContainer: {
    width: 200,
    float: 'right',
  },
  infoLabel: {
    padding: 5,
    fontFamily: theme.fonts.barlow,
    fontSize: 16,
    fontWeight: 300,
    color: theme.colors.fontColor1,
  },
  infoIcon: {
    marginLeft: 40,
    height: 20,
    width: 20,
    display: 'inline-block',
    float: 'right',
  },
  unavailable: {
    background:
      'repeating-linear-gradient(45deg, #979797, #979797 1px, rgba(255,255,255,0) 1px, rgba(255,255,255,0) 6px)',
  },
  remaining: {
    backgroundColor: 'rgba(70,130,90,1)',
    borderRadius: '50%',
  },
  calendar: {
    width: '100%',
    minWidth: 400,
    fontFamily: theme.fonts.barlow,
    fontSize: 16,
    color: theme.colors.fontColor1,
  },
});

const modifiers = {
  saturdays: { daysOfWeek: [6] },
  sundays: { daysOfWeek: [0] },
  weekday: { daysOfWeek: [1, 2, 3, 4, 5] },
  past: {
    before: new Date(),
  },
  future: {
    after: new Date(),
  },
};

const modifiersStyles = {
  saturdays: {
    backgroundColor: '#c2d4c6',
    borderRadius: 0,
  },
  sundays: {
    backgroundColor: '#c2d4c6',
    borderRadius: 0,
  },
  weekday: {
    backgroundColor: '#f9f8f6',
    borderRadius: 0,
  },
  past: {
    color: 'rgba(50, 55, 50, 0.25)',
    cursor: 'not-allowed',
    fontWeight: 'normal',
  },
  future: {
    fontFamily: 'barlow-semi-condensed-medium',
    fontWeight: 'normal',
  },
  today: {
    fontFamily: 'barlow-semi-condensed-medium',
    fontWeight: 'normal',
  },
  disabled: {
    mixBlendMode: 'multiply',
    fontWeight: 'normal',
    cursor: 'not-allowed',
  },
  outside: {
    fontFamily: 'barlow-semi-condensed',
  },
};

const PeriodCalendar = ({
  classes,
  selectedDays,
  period,
  periodAvailabilities,
  onTimeChange,
  onDaySelect,
  onRangeSelect,
  onMonthChange,
  rangeLength,
  isDisabled,
}) => {
  const onSelect = rangeLength === 1 ? onDaySelect : onRangeSelect;
  const markedDays =
    rangeLength === 1
      ? selectedDays
      : eachDay(selectedDays[0], addDays(selectedDays[0], rangeLength - 1));

  const mods =
    rangeLength === 1 && markedDays.length > 0
      ? modifiers
      : { ...modifiers, start: markedDays[0], end: markedDays[markedDays.length - 1] };

  const availabilities = periodAvailabilities.map(pa => {
    const day = new Date(pa.date * 1000);
    return { date: day.toLocaleDateString(), number: pa.antall };
  });
  const isStartTimeOption = period.valgbarStarttidspunkt;

  const timestampOptions = () => [...Array(48).keys()].map( k => (
    <option key={k} value={k*1800}>{padStart(Math.floor(k/2).toString(), 2, '0')}{':'}{(k % 2 === 0) ? '00' : '30'}</option>
  ))

  return (
    <div>
      <DayPicker
        className={classes.calendar}
        modifiers={mods}
        modifiersStyles={modifiersStyles}
        navbarElement={<CalendarNavbar />}
        renderDay={(...params) => renderDay(...params, rangeLength, availabilities, period.priser)}
        disabledDays={isDisabled}
        selectedDays={markedDays}
        onDayClick={onSelect}
        onMonthChange={onMonthChange}
        showOutsideDays
        locale="no"
        months={MONTHS}
        weekdaysLong={WEEKDAYS_LONG}
        weekdaysShort={WEEKDAYS_SHORT}
        firstDayOfWeek={FIRST_DAY_OF_WEEK}
        labels={LABELS}
      />
      <div className={classes.footer}>
        { isStartTimeOption && 
          <div>
            <select
              name="time"
              className={classes.timeInput}
              onChange={onTimeChange}
              >
              {timestampOptions()}
            </select>
            <span className={classes.infoLabel}>Sett starttidspunkt for kortet</span>
          </div>
        }
        <div className={classes.infoContainer}>
          <div className={classes.infoLabel}>
            <span>Utilgjengelige kort </span>
            <span className={`${classes.infoIcon} ${classes.unavailable}`} />
          </div>
          <div className={classes.infoLabel}>
            <span>Gjenværende kort</span>
            <span className={`${classes.infoIcon} ${classes.remaining}`} />
          </div>
        </div>
      </div>
    </div>
  );
};

PeriodCalendar.propTypes = {
  classes: PropTypes.object.isRequired,
  onDaySelect: PropTypes.func.isRequired,
  onRangeSelect: PropTypes.func.isRequired,
  onMonthChange: PropTypes.func.isRequired,
  selectedDays: PropTypes.array.isRequired,
  period: PropTypes.object.isRequired,
  isDisabled: PropTypes.func.isRequired,
  periodAvailabilities: PropTypes.array.isRequired,
  onTimeChange: PropTypes.func.isRequired,
  rangeLength: PropTypes.number.isRequired,
};

export default withStyles(styles)(PeriodCalendar);

const renderDay = (day, { selected, start, end }, rangeLength, availabilities, prices) => {
  const cardsLeftStyle = {
    backgroundColor: 'rgba(70,130,90,1)',
    borderRadius: '50%',
    color: 'white',
    fontFamily: 'barlow',
    fontSize: 14,
    height: 18,
    width: 18,
    position: 'absolute',
    top: -9,
    right: '20%',
  };
  const cellStyle = {
    height: 50,
  };
  const dateBaseStyle = {
    height: 50,
    width: 300,
    display: 'table-cell',
    verticalAlign: 'middle',
  };
  const dateSelected = {
    background: 'rgba(50, 55, 50, 1)',
    color: 'white',
    borderRadius: '3rem',
  };
  const dateRangeSelected = {
    background: 'rgba(50, 55, 50, 1)',
    color: 'white',
    borderRadius: '0 !important',
  };
  const dateStart = {
    borderTopLeftRadius: '3rem',
    borderBottomLeftRadius: '3rem',
  };
  const dateEnd = {
    borderTopRightRadius: '3rem',
    borderBottomRightRadius: '3rem',
  };
  const date = day.toLocaleDateString();
  const availability = availabilities.filter(a => a.date === date);
  let dateSelectedStyle =
    rangeLength > 1
      ? { ...dateBaseStyle, ...dateRangeSelected }
      : { ...dateBaseStyle, ...dateSelected };
  if (rangeLength > 1 && start) dateSelectedStyle = { ...dateSelectedStyle, ...dateStart };
  if (rangeLength > 1 && end) dateSelectedStyle = { ...dateSelectedStyle, ...dateEnd };
  const dateStyle = selected ? dateSelectedStyle : dateBaseStyle;

  const priceForRange = (selectedDate, priceChangePeriods, defaultPrice) => {
    const periodFound = priceChangePeriods.find(per =>
      isWithinRange(selectedDate, new Date(per.fra * 1000), new Date(per.til * 1000))
    );
    return periodFound ? periodFound.pris : defaultPrice;
  };

  const price = priceForRange(startOfDay(day), prices.perioder, prices.standardpris);
  const selectedDateString = rangeLength > 1 && !start ? '' : `${price} .-`;

  return (
    <div style={cellStyle}>
      {selected &&
        availability.length > 0 &&
        (availability[0].number || availability[0].number === 0) &&
        (rangeLength === 1 || start) && (
          <div style={cardsLeftStyle}>
            {availability[0].number > 9 ? '9+' : availability[0].number}
          </div>
        )}

      <div style={dateStyle}>{selected ? selectedDateString : day.getDate()}</div>
    </div>
  );
};

const CalendarNavbar = ({ onPreviousClick, onNextClick, className }) => (
  <div className={className}>
    <img
      src={markerLeftIcon}
      alt=""
      style={{ float: 'left', cursor: 'pointer' }}
      onClick={() => onPreviousClick()}
    />
    <img
      src={markerRightIcon}
      alt=""
      style={{ float: 'right', cursor: 'pointer' }}
      onClick={() => onNextClick()}
    />
  </div>
);

CalendarNavbar.propTypes = {
  onPreviousClick: PropTypes.func,
  onNextClick: PropTypes.func,
  className: PropTypes.string,
};

CalendarNavbar.defaultProps = {
  onPreviousClick: null,
  onNextClick: null,
  className: null,
};

const WEEKDAYS_SHORT = ['S', 'M', 'T', 'O', 'T', 'F', 'L'];

const MONTHS = [
  'Januar',
  'Februar',
  'Mars',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Desember',
];

const WEEKDAYS_LONG = ['Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag', 'Søndag'];

const FIRST_DAY_OF_WEEK = 1;

const LABELS = { nextMonth: 'Neste måned', previousMonth: 'Forrige måned' };
