import React from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import Navbar from 'components/Common/Navbar';
import PersonaliaSummary from 'components/Summary/PersonaliaSummary';
import PriceSummary from 'components/Summary/PriceSummary';
import Button from 'components/Common/Button';
import IconButton from '@material-ui/core/IconButton';
import printIcon from 'static/icons/icon_print.svg';
import infoIcon from 'static/icons/info.svg';
import arrowLeft from 'static/icons/arrow_left.svg';
import OfferInformation from 'components/Offer/OfferInformation';
import Status from 'components/Common/Status';

const styles = (theme) => ({
  container: {
    height: '100%',
  },
  left: {
    background: theme.colors.menuBackground,
  },
  right: {
    background: 'linear-gradient(to top left, rgba(210,210,210,0.15), rgba(253,252,249,1))',
  },
  content: {
    padding: '150px 80px 80px 80px',
    color: theme.colors.fontColor1,
  },
  status: {
    position: 'absolute',
    width: 'calc(100% - 160px)',
    bottom: 20,
    left: 80,
    right: 80,
    zIndex: 1,
    opacity: 1,
  },
  fadeout: {
    '-webkit-animation': 'fadeout 1s ease-out 5s',
    '-moz-animation': 'fadeout 2s ease-out 5s',
    '-ms-animation': 'fadeout 2s ease-out 5s',
    '-o-animation': 'fadeout 2s ease-out 5s',
    animation: 'fadeout 2s ease-out 5s',
  },
  subContent: {
    padding: '40px 80px 40px 80px',
  },
  bottomGroup: {
    marginTop: 50,
    position: 'relative',
    width: '100%',
    fontFamily: theme.fonts.barlow,
    fontSize: 14,
  },
  printIcon: {
    border: '1px solid rgba(50,55,50,1)',
    marginTop: 80,
  },
  toPrint: {
    display: 'none',
  },
});

class Receipt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMessage: true,
    };
    this.onIconClick = this.onIconClick.bind(this);
  }

  componentDidMount() {
    this.toggleMessage();
  }

  componentWillUnmount() {
    clearTimeout(this.messageTimeout);
  }

  onIconClick(event) {
    event.currentTarget.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  toggleMessage() {
    this.messageTimeout = setTimeout(() => {
      this.setState({ showMessage: false });
    }, 6000);
  }

  render() {
    const {
      classes,
      order,
      offer,
      onPrintClick,
      onNextCustomerClick,
      onSameCardNewCustomerClick,
      onNewCardSameCustomerClick,
      isPrinting,
    } = this.props;
    const { showMessage } = this.state;
    return (
      <div>
        <Navbar title="HOME" position="absolute" icon={arrowLeft} onClick={onNextCustomerClick} />
        {showMessage && (
          <Status
            className={`${classes.status} ${classes.fadeout}`}
            message="KORTET ER KJØPT, KVITTERING ER SENDT PÅ SMS OG E-POST"
            variant="success"
            positionText="center"
            iconType="check"
          />
        )}
        <Grid container spacing={0} className={classes.container}>
          <Grid item xs={12} sm={7} md={7} lg={7} className={classes.left}>
            <div className={classes.content}>
              <PersonaliaSummary order={order} />
              <div className={classes.bottomGroup}>
                <Grid container spacing={16} alignContent="center">
                  <Grid item xs={12} sm={12} md={12} lg={4}>
                    <Button
                      fullWidth
                      className={classes.button}
                      theme="primary"
                      onClick={onNextCustomerClick}
                      label="NESTE KUNDE"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Button
                      fullWidth
                      className={classes.button}
                      theme="tetriary"
                      onClick={onSameCardNewCustomerClick}
                      label="NYTT KORT TIL SAMME KUNDE"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4}>
                    <Button
                      fullWidth
                      className={classes.button}
                      theme="tetriary"
                      onClick={onNewCardSameCustomerClick}
                      label="SAMME KORT TIL NY KUNDE&emsp;"
                    />
                  </Grid>
                </Grid>
                <div
                  ref={(section) => {
                    // eslint-disable-next-line react/no-unused-class-component-methods
                    this.Button = section;
                  }}>
                  <IconButton
                    className={classes.printIcon}
                    onClick={this.onIconClick}
                    color="inherit">
                    <img src={printIcon} alt="" />
                  </IconButton>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={5} md={5} lg={5} className={classes.right}>
            <div className={classes.content} style={{ marginTop: 40 }}>
              <PriceSummary order={order} />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={0} className={classes.right}>
          {/*

          Skal potensielt videreutvikles senere, blir derfor ikke slettet

           <Grid item xs={7}>
            <div className={classes.subContent}>
              <PrintOptions
                onPrintOptionSelect={onPrintOptionSelect}
                activePrintOptions={activePrintOptions}
              />
            </div>
          </Grid> */}
          <Grid item xs={5}>
            <div className={classes.subContent}>
              <Button
                style={{ marginTop: 140 }}
                theme="primary"
                onClick={onPrintClick}
                label="SKRIV UT"
              />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={0} className={classes.right}>
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <div className={classes.subContent}>
              <div
                ref={(section) => {
                  // eslint-disable-next-line react/no-unused-class-component-methods
                  this.OfferInformation = section;
                }}>
                <IconButton
                  className={classes.printIcon}
                  onClick={this.onIconClick}
                  color="inherit">
                  <img src={infoIcon} alt="" />
                </IconButton>
              </div>
              {offer && <OfferInformation offer={offer} isReceipt />}
            </div>
          </Grid>
          <Grid item xs={5} />
        </Grid>
        <div className={`${!isPrinting ? classes.toPrint : ''}`}>
          <div id="print_receipt">
            <PersonaliaSummary order={order} />
            <PriceSummary order={order} />
          </div>
          <div id="print_area">
            {offer && <OfferInformation offer={offer} isReceipt viewMap={false} />}
          </div>
        </div>
      </div>
    );
  }
}

Receipt.propTypes = {
  classes: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  offer: PropTypes.object,
  onNextCustomerClick: PropTypes.func.isRequired,
  onSameCardNewCustomerClick: PropTypes.func.isRequired,
  onNewCardSameCustomerClick: PropTypes.func.isRequired,
  onPrintClick: PropTypes.func.isRequired,
  isPrinting: PropTypes.bool.isRequired,
};

Receipt.defaultProps = {
  offer: null,
};

export default withStyles(styles)(Receipt);
