import React from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import Navbar from 'components/Common/Navbar';
import Loading from 'components/Common/Loading';
import Status from 'components/Common/Status';

import arrowLeft from 'static/icons/arrow_left.svg';

import SaleSummary from './SaleSummary';
import SaleDetailsButtons from './SaleDetailsButtons';
import SaleDeletionModal from './SaleDeletionModal';
import SaleEditModal from './SaleEditModal';
import SalePriceSummary from './SalePriceSummary';

const styles = theme => ({
  container: {
    height: '100%',
  },
  left: {
    background: theme.colors.menuBackground,
  },
  right: {
    background: 'linear-gradient(to top left, rgba(210,210,210,0.15), rgba(253,252,249,1))',
  },
  content: {
    padding: '150px 80px 80px 80px',
    color: theme.colors.fontColor1,
  },
  status: {
    position: 'absolute',
    width: 'calc(100% - 160px)',
    bottom: 20,
    left: 80,
    right: 80,
    zIndex: 1,
    opacity: 1,
  },
  fadeout: {
    '-webkit-animation': 'fadeout 1s ease-out 5s',
    '-moz-animation': 'fadeout 2s ease-out 5s',
    '-ms-animation': 'fadeout 2s ease-out 5s',
    '-o-animation': 'fadeout 2s ease-out 5s',
    animation: 'fadeout 2s ease-out 5s',
  },
  subContent: {
    padding: '40px 80px 40px 80px',
  },
  bottomGroup: {
    marginTop: 50,
    position: 'relative',
    width: '100%',
    fontFamily: theme.fonts.barlow,
    fontSize: 14,
  },
  printIcon: {
    border: '1px solid rgba(50,55,50,1)',
    marginTop: 80,
  },
  toPrint: {
    display: 'none',
  },
});

const SaleDetails = ({
  onBackClick,
  onDeleteButtonClick,
  onEditButtonClick,
  onDeletion,
  onCloseDeleteModalClick,
  onCloseEditModalClick,
  onSendMailClick,
  saleId,
  details,
  classes,
  loading,
  showDeletionModal,
  showEditModal,
  afterEdit,
  showReceiptMessage,
  onPrintButtonClick,
}) => (
  <div>
    <Navbar title="Salgshistorikk" icon={arrowLeft} onClick={onBackClick} />

    {showReceiptMessage && (
      <Status
        className={`${classes.status} ${classes.fadeout}`}
        message="KVITTERING ER SENDT PÅ E-POST"
        variant="success"
        positionText="center"
        iconType="check"
      />
    )}

    <Grid container spacing={0} className={classes.container}>
      <Grid item xs={12} sm={7} md={7} lg={7} className={classes.left}>
        {details && (
          <div className={classes.content}>
            <SaleSummary details={details} />
            <div className={classes.bottomGroup}>
              <SaleDetailsButtons
                onChangeClick={onEditButtonClick}
                onSendEmailClick={onSendMailClick}
                onDeleteClick={onDeleteButtonClick}
                onPrintClick={onPrintButtonClick}
              />
            </div>
          </div>
        )}
      </Grid>
      <Grid item xs={12} sm={5} md={5} lg={5} className={classes.right}>
        <div className={classes.content} style={{ marginTop: 40 }}>
          {details && <SalePriceSummary details={details} />}
        </div>
      </Grid>
    </Grid>

    {details && (
      <SaleDeletionModal
        onCloseClick={onCloseDeleteModalClick}
        onDeleteClick={onDeletion}
        show={showDeletionModal}
      />
    )}

    {details && (
      <SaleEditModal
        onCloseClick={onCloseEditModalClick}
        saleId={saleId}
        customer={details.betaler}
        show={showEditModal}
        afterEdit={afterEdit}
      />
    )}

    <Loading label="Laster inn" active={loading && details === null} />
  </div>
);

SaleDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  details: PropTypes.object,
  saleId: PropTypes.string.isRequired,
  onBackClick: PropTypes.func.isRequired,
  onEditButtonClick: PropTypes.func.isRequired,
  onCloseDeleteModalClick: PropTypes.func.isRequired,
  onCloseEditModalClick: PropTypes.func.isRequired,
  onDeleteButtonClick: PropTypes.func.isRequired,
  onPrintButtonClick: PropTypes.func.isRequired,
  onSendMailClick: PropTypes.func.isRequired,
  onDeletion: PropTypes.func.isRequired,
  afterEdit: PropTypes.func.isRequired,
  showDeletionModal: PropTypes.bool.isRequired,
  showEditModal: PropTypes.bool.isRequired,
  showReceiptMessage: PropTypes.bool.isRequired,
};

SaleDetails.defaultProps = {
  details: null,
};
export default withStyles(styles)(SaleDetails);
