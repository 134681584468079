import React from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import Navbar from 'components/Common/Navbar';
import arrowLeft from 'static/icons/arrow_left.svg';
import ReportPeriodFilter from './ReportPeriodFilter';

const styles = theme => ({
  container: {
    position: 'absolute',
    height: '100%',
  },
  header: {
    fontFamily: theme.fonts.vollkorn,
    fontSize: 48,
    fontWeight: 'normal',
  },
  left: {
    background: 'linear-gradient(to top, rgba(253,252,249,1), rgba(255,255,255,0.3))',
  },
  right: {
    background: theme.gradients.light,
  },
  content: {
    padding: '120px 40px 40px 40px',
    color: theme.colors.fontColor1,
  },
});

const Reports = ({
  classes,
  filters,
  tilbydere,
  onHomeClick,
  onFromDayChange,
  onToDayChange,
  onTilbyderChange,
  onTypeChange,
  onSubmit,
  validationErrors,
  displayErrors,
  isValid,
  disabledFromDates,
  disabledToDates,
  loading,
}) => (
  <div>
    <Navbar title="HJEM" icon={arrowLeft} onClick={onHomeClick} />
    <Grid container spacing={0} className={classes.container}>
      <Grid item sm={3} md={3} lg={3} className={classes.left} />
      <Grid item xs={12} sm={9} md={9} lg={9} className={classes.right}>
        <div className={classes.content}>
          <h1 className={classes.header}>Rapporter</h1>
          <ReportPeriodFilter
            filters={filters}
            tilbydere={tilbydere}
            onFromDayChange={onFromDayChange}
            onToDayChange={onToDayChange}
            onTilbyderChange={onTilbyderChange}
            onTypeChange={onTypeChange}
            onSubmit={onSubmit}
            validationErrors={validationErrors}
            displayErrors={displayErrors}
            isValid={isValid}
            loading={loading}
            disabledFromDates={disabledFromDates}
            disabledToDates={disabledToDates}
          />
        </div>
      </Grid>
    </Grid>
  </div>
);

Reports.propTypes = {
  classes: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  tilbydere: PropTypes.array.isRequired,
  onHomeClick: PropTypes.func.isRequired,
  onFromDayChange: PropTypes.func.isRequired,
  onToDayChange: PropTypes.func.isRequired,
  onTilbyderChange: PropTypes.func.isRequired,
  onTypeChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  disabledFromDates: PropTypes.func.isRequired,
  disabledToDates: PropTypes.func.isRequired,
  validationErrors: PropTypes.object.isRequired,
  displayErrors: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
};

export default withStyles(styles)(Reports);
