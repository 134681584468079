import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { fetchOffers, fetchOffer, FETCH_OFFERS_SUCCESS } from 'store/actions/offers';
import { setPurchaseStage, STAGE_OFFER, setOffer } from 'store/actions/order';

import Offers from 'components/Offer';

class OffersContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: '',
      infoActive: false,
    };
    this.onTextChange = this.onTextChange.bind(this);
    this.onInfoClose = this.onInfoClose.bind(this);
    this.onInfoOpen = this.onInfoOpen.bind(this);
    this.onOfferCardClick = this.onOfferCardClick.bind(this);
  }

  componentDidMount() {
    this.props.setPurchaseStage(STAGE_OFFER);
    this.props.fetchOffers(this.props.match.params.type);
  }

  onTextChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  onInfoOpen(offer) {
    this.props.fetchOffer(offer.id).then(() => {
      this.setState({
        infoActive: true,
      });
    });
  }

  onInfoClose() {
    this.setState({
      infoActive: false,
    });
  }

  onOfferCardClick(offer) {
    this.props.setOffer(offer);
    this.props.history.push({
      pathname: `/purchase/period/${offer.id}`,
      state: { duration: 300, transition: 'fade' },
    });
  }

  render() {
    const { offers, offer, sale, loading } = this.props;
    const { infoActive } = this.state;
    return (
      <Offers
        offers={offers}
        loading={loading}
        searchWord={this.state.search}
        onTextChange={this.onTextChange}
        sale={sale}
        currentOffer={offer}
        infoActive={infoActive}
        onInfoClose={this.onInfoClose}
        onInfoOpen={this.onInfoOpen}
        onOfferCardClick={this.onOfferCardClick}
      />
    );
  }
}

OffersContainer.propTypes = {
  history: PropTypes.object.isRequired,
  fetchOffer: PropTypes.func.isRequired,
  fetchOffers: PropTypes.func.isRequired,
  offers: PropTypes.array.isRequired,
  offer: PropTypes.object,
  sale: PropTypes.bool.isRequired,
  setPurchaseStage: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  setOffer: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

OffersContainer.defaultProps = {
  offer: null,
};

const mapStateToProps = (state, props) => ({
  offers: state.get.offers.filter(offer => offer.type === props.match.params.type),
  offer: state.get.offer,
  loading: state.get.actionsInProgress.includes(FETCH_OFFERS_SUCCESS),
});

export default withRouter(
  connect(
    mapStateToProps,
    { fetchOffers, fetchOffer, setPurchaseStage, setOffer }
  )(OffersContainer)
);
