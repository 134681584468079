import {
  RESET_SALE_SUCCESS,
  SET_SALE_SUCCESS,
  FETCH_SALES_SUCCESS,
  EMPTY_SALES_SUCCESS,
  FETCH_SALE_DETAILS_SUCCESS,
  DELETE_SALE_SUCCESS,
  EDIT_SALE_SUCCESS,
  SEND_RECEIPT_SUCCESS,
} from 'store/actions/sales';

const initialState = {
  sale: null,
  sales: [],
  salesRemaining: false,
  saleDetails: null,
};

export default function salesReducer(state = initialState, action = {}) {
  switch (action.type) {
    case RESET_SALE_SUCCESS:
      return { ...state, sale: null, saleDetails: null };
    case SET_SALE_SUCCESS:
      return { ...state, sale: action.sale };
    case FETCH_SALES_SUCCESS:
      return {
        ...state,
        sales: state.sales.concat(action.sales),
        salesRemaining: action.sales.length > 0,
      };
    case EMPTY_SALES_SUCCESS:
      return { ...state, sales: [] };
    case FETCH_SALE_DETAILS_SUCCESS:
      return { ...state, saleDetails: action.saleDetails };
    case DELETE_SALE_SUCCESS:
      return { ...state, sales: state.sales.filter(({ id }) => id !== action.id) };
    case EDIT_SALE_SUCCESS: {
      const index = state.sales.find(item => item.id === action.id);
      const updatedSale = { ...state.sales[index], betaler: action.buyer };
      return {
        ...state,
        sales: [...state.sales.slice(0, index), updatedSale, ...state.sales.slice(index + 1)],
      };
    }
    case SEND_RECEIPT_SUCCESS: {
      return state;
    }
    default:
      return state;
  }
}
