import { Grid, withStyles } from '@material-ui/core';
import Button from 'components/Common/Button';
import ButtonGroup from 'components/Common/ButtonGroup';
import Navbar from 'components/Common/Navbar';
import PersonaliaSummary from 'components/Summary/PersonaliaSummary';
import PriceSummary from 'components/Summary/PriceSummary';
import PropTypes from 'prop-types';
import React from 'react';
import arrowUp from 'static/icons/arrow_up.svg';

const styles = theme => ({
  container: {
    position: 'absolute',
    height: '100%',
  },
  left: {
    background: theme.gradients.dark,
  },
  right: {
    background: 'rgba(50, 55, 50, 1)',
  },
  content: {
    padding: '150px 80px 80px 80px',
    color: theme.colors.fontColor2,
  },
  bottomGroup: {
    marginTop: 50,
    position: 'relative',
    width: 320,
    fontFamily: theme.fonts.barlow,
    fontSize: 14,
  },
});

const Summary = ({
  classes,
  order,
  onBuyClick,
  onChangeClick,
  onNorwegianSelect,
  onEnglishSelect,
  norwegian,
  updatingStatus,
}) => (
  <div>
    <Navbar title="ENDRE" icon={arrowUp} onClick={onChangeClick} fontColor="rgba(253,252,249,1)" />
    <Grid container spacing={0} className={classes.container}>
      <Grid item xs={12} sm={7} md={7} lg={7} className={classes.left}>
        <div className={classes.content}>
          <div>
            <PersonaliaSummary order={order} />
          </div>
          <div className={classes.bottomGroup}>
            <p>Kvittering sendes ut på:</p>
            <ButtonGroup
              theme="dark"
              bordered
              buttons={[
                {
                  label: 'NORSK',
                  onClick: onNorwegianSelect,
                  active: norwegian,
                },
                {
                  label: 'ENGELSK',
                  onClick: onEnglishSelect,
                  active: !norwegian,
                },
              ]}
            />
            <br />
            <Button
              theme="success"
              fullWidth
              onClick={onBuyClick}
              loading={updatingStatus}
              label="GJENNOMFØR KJØPET"
            />
          </div>
        </div>
      </Grid>
      <Grid item xs={12} sm={5} md={5} lg={5} className={classes.right}>
        <div className={classes.content} style={{ marginTop: 40 }}>
          <PriceSummary order={order} />
        </div>
      </Grid>
    </Grid>
  </div>
);

Summary.propTypes = {
  classes: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  onNorwegianSelect: PropTypes.func.isRequired,
  onEnglishSelect: PropTypes.func.isRequired,
  onBuyClick: PropTypes.func.isRequired,
  onChangeClick: PropTypes.func.isRequired,
  norwegian: PropTypes.bool.isRequired,
  updatingStatus: PropTypes.bool.isRequired,
};

export default withStyles(styles)(Summary);
