import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { addHours, format, isWithinRange, startOfDay } from 'date-fns';
import { isFastperiode, isLoepende, padStart } from '../../utils/misc';

const styles = theme => ({
  content: {
    width: '100%',
    fontFamily: theme.fonts.barlowSemiCondensed,
    fontSize: 16,
    fontWeight: 500,
  },
  divider: {
    border: 'none',
    borderTop: '1px solid',
  },
  dividerDashed: {
    border: 'none',
    borderTop: '1px dashed',
  },
  header: {
    fontFamily: theme.fonts.barlowSemiCondensed,
    fontSize: 16,
    fontWeight: 600,
  },
  totalSum: {
    marginTop: 20,
    float: 'right',
    fontSize: 36,
  },
  price: {
    float: 'right',
  },
});

const formatDate = date => format(date * 1000, 'DD.MM.YYYY');

const formatVarighet = varighet => {
  const hours = Math.floor(varighet / 24);
  const remainder = varighet % 24;
  const dayText = (hours > 0) ? `${hours.toString()} døgn` : '';
  const andText = (hours > 0 && remainder > 0) ? ' og ' : '';
  const hourText = (remainder > 0) ? `${remainder.toString()} time` : '';
  const pluralHours = (remainder > 1) ? 'r' : '';
  return `${dayText}${andText}${hourText}${pluralHours}`;
}

const formatTimestamp = seconds => {
  const hours = Math.floor(seconds / 3600);
  const minutes = (seconds % 3600) / 60;
  const hoursText = padStart(hours.toString(), 2, '0');
  const minutesText = padStart(minutes.toString(), 2, '0');
  return `${hoursText}:${minutesText}`
}

const startTidspunktAndVarighet = (valgbarStarttidspunkt, startTidspunkt, varighet) => (
     (valgbarStarttidspunkt || varighet < 24) &&
      <div>
        {'Starttidspunkt: kl '}
        {formatTimestamp(startTidspunkt)}
        <br />
        {'Varighet: '}
        {formatVarighet(varighet)}
      </div>
  )

const findCardPeriod = (classes, purchase, card) => {
  if (isFastperiode(card)) {
    const from = card.sesong.fra;
    const to = card.sesong.til;
    return (
      <div>
        <span className={classes.name}>
          {formatDate(from)} - {formatDate(to)}
        </span>
        <span className={classes.price}>{card.priser.standardpris} kr</span>
      </div>
    );
  }
  if (isLoepende(card)) {
    if (purchase.datovalg && card.varighet <= 24) {
      return (
        <div>
          {purchase.datovalg.map(date => (
            <div key={date}>
              <span className={classes.name}>{formatDate(date)}</span>
              <span className={classes.price}>
                {priceForRange(startOfDay(date * 1000), card.priser.perioder, card.priser.standardpris)}{' '}
                kr
              </span>
            </div>
          ))}
          {startTidspunktAndVarighet(card.valgbarStarttidspunkt, purchase.startTidspunkt, card.varighet)}
        </div>)
    } else {
      return (
        <div>
          <span className={classes.name}>
            {formatDate(purchase.datovalg[0])}
            {' - '}
            {formatDate(Math.floor(addHours((purchase.datovalg[0] + purchase.startTidspunkt) * 1000, card.varighet) / 1000))}
            <span className={classes.price}>
              {priceForRange(
                startOfDay(purchase.datovalg[0] * 1000),
                card.priser.perioder,
                card.priser.standardpris
              )}{' '}
              kr
            </span>
          </span>
          {startTidspunktAndVarighet(card.valgbarStarttidspunkt, purchase.startTidspunkt, card.varighet)}
        </div>
      );
    }
  }
  return null;
};

const priceForRange = (date, priceChangePeriods, defaultPrice) => {
  const periodFound = priceChangePeriods.find(period =>
    isWithinRange(date, new Date(period.fra * 1000), new Date(period.til * 1000))
  );
  return periodFound ? periodFound.pris : defaultPrice;
};

const PriceSummary = ({ classes, order }) => {
  const options = order.purchase.valg ? order.purchase.valg : [];
  const numberOfDays = order.purchase.datovalg ? order.purchase.datovalg.length : 1;
  const discounts = options
    .filter(option => option.rabatt)
    .map(discount => (
      <p key={discount.id}>
        <span>{discount.navn}</span>
        <span className={classes.price}>- {discount.pris * numberOfDays} kr</span>
      </p>
    ));
  const extras = options
    .filter(option => !option.rabatt)
    .map(extra => (
      <p key={extra.id}>
        <span>{extra.navn}</span>
        <span className={classes.price}>+ {extra.pris * numberOfDays} kr</span>
      </p>
    ));
  return (
    <div className={classes.content}>
      <h4 className={classes.header}>{order.period.tittel}</h4>
      <div>
        <span>{findCardPeriod(classes, order.purchase, order.period)}</span>
      </div>
      {(discounts.length > 0 || extras.length > 0) && (
        <div>
          <hr className={classes.dividerDashed} />
          <h4 className={classes.header}>Rabatter og tillegg</h4>
          {discounts.length > 0 && <div>{discounts}</div>}
          {extras.length > 0 && <div>{extras}</div>}
        </div>
      )}
      <hr className={classes.divider} />
      <hr className={classes.divider} />
      <p className={classes.totalSum}>
        <span>{order.purchase && order.purchase.pris} kr</span>
      </p>
    </div>
  );
};

PriceSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
};

export default withStyles(styles)(PriceSummary);
