import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import Receipt from 'components/Receipt';
import {
  setPurchaseStage,
  STAGE_CONFIRMED,
  resetPurchase,
  resetPurchaseSameCard,
  fetchReceipt,
} from 'store/actions/order';
import { removeState } from 'store/index';
import { fetchOffer } from 'store/actions/offers';
import html2canvas from 'html2canvas';

window.html2canvas = html2canvas;

class SummaryContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePrintOptions: [],
      isPrinting: false,
    };
    this.onNextCustomerClick = this.onNextCustomerClick.bind(this);
    this.onSameCardNewCustomerClick = this.onSameCardNewCustomerClick.bind(this);
    this.onNewCardSameCustomerClick = this.onNewCardSameCustomerClick.bind(this);
    this.onPrintOptionSelect = this.onPrintOptionSelect.bind(this);
    this.onPrintClick = this.onPrintClick.bind(this);
  }

  componentDidMount() {
    this.props.setPurchaseStage(STAGE_CONFIRMED);
    this.props.fetchOffer(this.props.order.purchase.tilbud);
  }

  onPrintOptionSelect(option, active) {
    if (active) {
      const { activePrintOptions } = this.state;
      this.setState({
        activePrintOptions: activePrintOptions.filter(apo => apo.name !== option.name),
      });
    } else {
      const { activePrintOptions } = this.state;
      activePrintOptions.push(option);
      this.setState({ activePrintOptions });
    }
  }

  onNextCustomerClick() {
    removeState();
    setTimeout(() => {
      // Cannot reset purchase before route transition is finished
      this.props.resetPurchase();
    }, 500);
    this.props.history.push({ pathname: '/', state: { transition: 'home' } });
  }

  onSameCardNewCustomerClick() {
    const { order } = this.props;
    this.props.history.push(`/purchase/offers/${order.offer && order.offer.type}`);
  }

  onNewCardSameCustomerClick() {
    const { order } = this.props;
    removeState();
    setTimeout(() => {
      // Cannot reset purchase before route transition is finished
      this.props.resetPurchaseSameCard();
    }, 500);
    this.props.history.push(`/purchase/period/${order.purchase && order.purchase.tilbud}`);
  }

  async onPrintClick() {
    const { order } = this.props;
    fetchReceipt(order.purchase.ordrenummer);
  }

  render() {
    const { order, offer } = this.props;
    const { activePrintOptions, isPrinting } = this.state;
    return (
      <Receipt
        activePrintOptions={activePrintOptions}
        onNextCustomerClick={this.onNextCustomerClick}
        onSameCardNewCustomerClick={this.onSameCardNewCustomerClick}
        onNewCardSameCustomerClick={this.onNewCardSameCustomerClick}
        onPrintOptionSelect={this.onPrintOptionSelect}
        onPrintClick={this.onPrintClick}
        isPrinting={isPrinting}
        order={order}
        offer={offer}
      />
    );
  }
}

SummaryContainer.propTypes = {
  history: PropTypes.object.isRequired,
  fetchOffer: PropTypes.func.isRequired,
  setPurchaseStage: PropTypes.func.isRequired,
  resetPurchase: PropTypes.func.isRequired,
  resetPurchaseSameCard: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  offer: PropTypes.object,
};

SummaryContainer.defaultProps = {
  offer: null,
};

const mapStateToProps = state => ({
  order: state.get.order,
  offer: state.get.offer,
});

export default withRouter(
  connect(
    mapStateToProps,
    { setPurchaseStage, resetPurchase, resetPurchaseSameCard, fetchOffer }
  )(SummaryContainer)
);
