/* Getters-reducer to isolate state-shape from react components */

export default function gettersReducer(getStore) {
  const getters = {
    get types() {
      return getStore().getState().rootReducer.homeReducer.types;
    },
    get menu() {
      return getStore().getState().rootReducer.menuReducer;
    },
    get actionsInProgress() {
      return getStore().getState().rootReducer.statusReducer.inProgress;
    },
    get order() {
      return getStore().getState().rootReducer.orderReducer;
    },
    get purchase() {
      return getStore().getState().rootReducer.orderReducer.purchase;
    },
    get purchaseStage() {
      return getStore().getState().rootReducer.orderReducer.stage;
    },
    get purchasePrice() {
      return getStore().getState().rootReducer.orderReducer.price;
    },
    get purchaseType() {
      return getStore().getState().rootReducer.orderReducer.type;
    },
    get offer() {
      return getStore().getState().rootReducer.offersReducer.offer;
    },
    get offers() {
      return getStore().getState().rootReducer.offersReducer.offers;
    },
    get offerPeriods() {
      return getStore().getState().rootReducer.offersReducer.periods;
    },
    get periodAvailabilities() {
      return getStore().getState().rootReducer.offersReducer.periodAvailabilities;
    },
    get auth() {
      return getStore().getState().rootReducer.authReducer;
    },
    get user() {
      return getStore().getState().rootReducer.userReducer;
    },
    get periodCard() {
      return getStore().getState().rootReducer.orderReducer.period;
    },
    get sale() {
      return getStore().getState().rootReducer.salesReducer.sale;
    },
    get sales() {
      return getStore().getState().rootReducer.salesReducer.sales;
    },
    get salesRemaining() {
      return getStore().getState().rootReducer.salesReducer.salesRemaining;
    },
    get saleDetails() {
      return getStore().getState().rootReducer.salesReducer.saleDetails;
    },
    get report() {
      return getStore().getState().rootReducer.reportReducer.report;
    },
    get tilbydere() {
      return getStore().getState().rootReducer.reportReducer.tilbydere;
    },
  };
  return () => getters;
}
