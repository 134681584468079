import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Hunter from 'components/Personalia/Hunter';
import { setHunter, updateHunter, UPDATE_HUNTER_SUCCESS } from 'store/actions/order';
import {
  validationRunner,
  runValidations,
  required,
  validDateOfBirth,
} from 'utils/validation/validation';

class PersonaliaContainer extends React.Component {
  constructor(props) {
    super(props);

    this.fieldValidations = [
      validationRunner('hunterNumber', 'Jegernummer', required),
      validationRunner('birthDate', 'Fødselsdato', required, validDateOfBirth),
    ];

    const { order } = this.props;
    this.state = {
      hunter: {
        hunterNumber: order.hunter ? order.hunter.jegernummer : '',
        birthDate: order.hunter ? order.hunter.fodselsdato : '',
        manualApproval: order.hunter ? order.hunter.manueltKontrollert : false,
      },
      huntingFeeStatus: '',
      loading: false,
      validationErrors: {},
      displayErrors: false,
    };
    this.state.validationErrors = runValidations(this.state.hunter, this.fieldValidations);
    this.onChange = this.onChange.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.onNextClick = this.onNextClick.bind(this);
    this.fetchPaidStatus = this.fetchPaidStatus.bind(this);
  }

  onChange(event) {
    const { hunter } = this.state;
    hunter[event.target.name] = event.target.value;
    this.setState({ hunter, validationErrors: runValidations(hunter, this.fieldValidations) });
    if (['birthDate', 'hunterNumber'].includes(event.target.name)) {
      this.setState({ huntingFeeStatus: '' });
    }
  }

  onCheckboxChange(event) {
    const { hunter } = this.state;
    this.setState({
      hunter: {
        ...hunter,
        manualApproval: event.target.checked,
      },
    });
  }

  onNextClick() {
    if (this.isValid()) {
      const { order } = this.props;
      const hunter = this.createHunter();
      this.props.setHunter(hunter);
      this.props.updateHunter(order.purchase.id, hunter).then(() => {
        this.props.history.push({
          pathname: `/purchase/summary/${order.purchase.id}`,
          state: { transition: 'next' },
        });
      });
    }
  }

  createHunter() {
    const { hunterNumber, birthDate, manualApproval } = this.state.hunter;
    return {
      jegernummer: hunterNumber,
      fodselsdato: birthDate,
      manueltKontrollert: manualApproval,
    };
  }

  fetchPaidStatus() {
    const { hunterNumber, birthDate } = this.state.hunter;
    const { type } = this.props;
    this.setState({ loading: true });
    fetch(`/api/v1/jegernummer/${hunterNumber}/${birthDate}/status/${type.jakttype}`, {
      method: 'GET',
      credentials: 'same-origin',
    })
      .then(response => response.json())
      .then(huntingFeeStatus => this.setState({ loading: false, huntingFeeStatus }));
  }

  isValid() {
    return Object.keys(this.state.validationErrors).length === 0;
  }

  render() {
    const { hunter, huntingFeeStatus, loading, validationErrors, displayErrors } = this.state;
    const { updatingHunter } = this.props;
    return (
      <Hunter
        hunter={hunter}
        huntingFeeStatus={huntingFeeStatus}
        loading={loading}
        updatingHunter={updatingHunter}
        onCheckboxChange={this.onCheckboxChange}
        checkPaidStatus={this.fetchPaidStatus}
        onChange={this.onChange}
        isValid={this.isValid()}
        validationErrors={validationErrors}
        displayErrors={displayErrors}
        onNextClick={this.onNextClick}
      />
    );
  }
}

PersonaliaContainer.propTypes = {
  history: PropTypes.object.isRequired,
  setHunter: PropTypes.func.isRequired,
  updateHunter: PropTypes.func.isRequired,
  updatingHunter: PropTypes.bool.isRequired,
  order: PropTypes.object.isRequired,
  type: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  order: state.get.order,
  type: state.get.purchaseType,
  updatingHunter: state.get.actionsInProgress.includes(UPDATE_HUNTER_SUCCESS),
});

export default withRouter(
  connect(
    mapStateToProps,
    { setHunter, updateHunter }
  )(PersonaliaContainer)
);
