import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { fetchTypes } from 'store/actions/home';
import { setType } from 'store/actions/order';
import Home from 'components/Home';
import HomeSidebar from 'components/Home/HomeSidebar';
import MenuContainer from 'containers/MenuContainer';

const styles = theme => ({
  content: {
    position: 'absolute',
    background: 'linear-gradient(to top left, rgba(253,252,249,0), rgba(210,210,210,0.3))',
    height: '100%',
    width: '100%',
    zIndex: 2,
  },
  mysticSquare: {
    position: 'absolute',
    height: `calc(100% - ${theme.custom.menubarHeight}px)`,
    width: theme.custom.homeSidebarWidth + 300,
    marginTop: theme.custom.menubarHeight,
    background: 'linear-gradient(to top, rgba(253,252,249,1), rgba(230,230,230,1))',
    zIndex: 0,
  },
});

class HomeContainer extends React.Component {
  constructor(props) {
    super(props);
    this.onTypeClick = this.onTypeClick.bind(this);
  }

  componentDidMount() {
    this.props.fetchTypes();
  }

  onTypeClick(type) {
    this.props.setType(type);
    this.props.history.push({
      pathname: `/purchase/offers/${type.name}`,
      state: { transition: 'fade', duration: 300 },
    });
  }

  render() {
    const { classes, types, active } = this.props;
    return (
      <div className="animation-wrapper">
        <div className="slide-in">
          <MenuContainer />
          <div className="home-sidebar">
            <HomeSidebar active={active} />
          </div>
          <div className="animation-wrapper">
            <div className={`${classes.mysticSquare} mystique`} />
            <div className={`${classes.content} fade-in fade-out`}>
              <Home types={types} active={active} onTypeClick={this.onTypeClick} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

HomeContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  fetchTypes: PropTypes.func.isRequired,
  setType: PropTypes.func.isRequired,
  types: PropTypes.array.isRequired,
  active: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  types: state.get.types,
  active: state.get.menu.active,
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    { fetchTypes, setType }
  )(HomeContainer)
);
