import { REQUEST_BEGIN, REQUEST_END, REQUEST_ERROR } from 'store/actions/status';

const initialState = {
  inProgress: [],
  loading: false,
  failure: false,
  error: null,
};

export default function statusReducer(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST_BEGIN:
      return {
        ...state,
        loading: true,
        inProgress: [...state.inProgress, action.operation],
      };
    case REQUEST_END:
      return {
        ...state,
        inProgress: state.inProgress.filter(operation => operation !== action.operation),
      };
    case REQUEST_ERROR:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}
