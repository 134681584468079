import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { throttle, debounce } from 'lodash';
import { fetchSales, FETCH_SALES_SUCCESS, setSale, emptySales } from 'store/actions/sales';
import SalesHistory from 'components/SalesHistory';

class SalesHistoryContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      currentQuery: null,
      pagesVisible: 0,
      pageSize: 7,
    };
    this.onHomeClick = this.onHomeClick.bind(this);
    this.onSaleClick = this.onSaleClick.bind(this);
    this.onTextChange = this.onTextChange.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onMoreClick = this.onMoreClick.bind(this);

    this.fetchSalesThrottled = debounce(throttle(this.fetchSalesPageinated, 2000), 300);
  }

  componentDidMount() {
    this.props.emptySales();
    this.state.pagesVisible = 0;
    this.fetchSalesPageinated();
  }

  onSaleClick(sale) {
    this.props.setSale(sale);
    this.props.history.push({
      pathname: `/sales/${sale.id}`,
      state: { transition: 'fade' },
    });
  }

  onHomeClick() {
    this.props.history.push({ pathname: '/', state: { transition: 'home' } });
  }

  onTextChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  onSearchClick(searchWord) {
    this.props.emptySales();
    this.state.pagesVisible = 0;
    this.state.currentQuery = searchWord;
    this.fetchSalesThrottled();
  }

  onMoreClick() {
    this.fetchSalesThrottled();
  }

  fetchSalesPageinated() {
    const { pageSize, pagesVisible, currentQuery } = this.state;
    const sales = this.props.fetchSales(pageSize, pagesVisible, currentQuery);
    this.state.pagesVisible += 1;
    return sales;
  }

  render() {
    const { sales, salesRemaining, loading } = this.props;
    const { searchText } = this.state;
    return (
      <SalesHistory
        onHomeClick={this.onHomeClick}
        onSaleClick={this.onSaleClick}
        sales={sales}
        salesRemaining={salesRemaining}
        searchWord={searchText}
        onTextChange={this.onTextChange}
        onSearchClick={this.onSearchClick}
        searchLoading={loading}
        onMoreClick={this.onMoreClick}
      />
    );
  }
}

SalesHistoryContainer.propTypes = {
  history: PropTypes.object.isRequired,
  setSale: PropTypes.func.isRequired,
  fetchSales: PropTypes.func.isRequired,
  emptySales: PropTypes.func.isRequired,
  sales: PropTypes.array.isRequired,
  salesRemaining: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  sales: state.get.sales,
  salesRemaining: state.get.salesRemaining,
  loading: state.get.actionsInProgress.includes(FETCH_SALES_SUCCESS),
});

export default withRouter(
  connect(
    mapStateToProps,
    { fetchSales, setSale, emptySales }
  )(SalesHistoryContainer)
);
