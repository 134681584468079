import React from 'react';
import PropTypes from 'prop-types';
import { TextField, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import searchIcon from 'static/icons/search.svg';

const styles = theme => ({
  textField: {
    width: 250,
    fontSize: 18,
    fontFamily: theme.fonts.barlowSemiCondensed,
  },

  searchButton: {
    display: 'inline-block',
  },
});

const SearchField = ({
  classes,
  disabled,
  type,
  name,
  text,
  placeholder,
  onFieldChange,
  hasButton,
  onButtonClick,
  buttonDisabled,
}) => (
  <div>
    <TextField
      id="searchfield"
      type={type}
      name={name}
      disabled={disabled}
      label={placeholder}
      value={text}
      onChange={onFieldChange}
      className={classes.textField}
      margin="normal"
    />
    {hasButton && (
      <IconButton
        disabled={buttonDisabled}
        onClick={() => onButtonClick(text)}
        value={text}
        color="inherit"
        aria-label="Menu">
        <img src={searchIcon} alt="" />
      </IconButton>
    )}
  </div>
);

SearchField.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  text: PropTypes.string,
  placeholder: PropTypes.string,
  onFieldChange: PropTypes.func.isRequired,
  hasButton: PropTypes.bool.isRequired,
  onButtonClick: PropTypes.func,
  buttonDisabled: PropTypes.bool,
};

SearchField.defaultProps = {
  text: '',
  disabled: false,
  placeholder: '',
  onButtonClick: null,
  buttonDisabled: false,
};

export default withStyles(styles)(SearchField);
