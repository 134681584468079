import { getRequest, postRequest, putRequest, getPdfRequest } from 'store/actions/request';
import { requestBegin, requestEnd } from 'store/actions/status';

export const STAGE_OFFER = 10;
export const STAGE_TIME = 20;
export const STAGE_ADDITIONAL = 30;
export const STAGE_PERSONALIA = 40;
export const STAGE_SUMMARY = 50;
export const STAGE_CONFIRMED = 60;

export const SET_TYPE_SUCCESS = 'SET_TYPE_SUCCESS';
export const SET_PURCHASE_STAGE_SUCCESS = 'SET_PURCHASE_STAGE_SUCCESS';

export const SET_CUSTOMER = 'SET_CUSTOMER';
export const SET_OFFER = 'SET_OFFER';
export const SET_PERIOD = 'SET_PERIOD';
export const SET_PRICE = 'SET_PRICE';
export const ADD_TO_PRICE = 'ADD_TO_PRICE';
export const REMOVE_FROM_PRICE = 'REMOVE_FROM_PRICE';
export const SET_HUNTER = 'SET_HUNTER';

export const SET_PURCHASE_SUCCESS = 'SET_PURCHASE_SUCCESS';
export const FETCH_PURCHASE_SUCCESS = 'FETCH_PURCHASE_SUCCESS';
export const RESERVE_PURCHASE_SUCCESS = 'RESERVE_PURCHASE_SUCCESS';
export const RESET_PURCHASE_SAME_CARD_SUCCESS = 'RESET_PURCHASE_SAME_CARD_SUCCESS';
export const CONFIRM_PURCHASE_SUCCESS = 'CONFIRM_PURCHASE_SUCCESS';
export const UPDATE_STATUS_SUCCESS = 'UPDATE_STATUS_SUCCESS';
export const UPDATE_OPTIONS_SUCCESS = 'UPDATE_OPTIONS_SUCCESS';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const FETCH_CUSTOMER_BY_PHONE_SUCCESS = 'FETCH_CUSTOMER_BY_PHONE_SUCCESS';
export const RESET_PURCHASE_SUCCESS = 'RESET_PURCHASE_SUCCESS';
export const UPDATE_HUNTER_SUCCESS = 'UPDATE_HUNTER_SUCCESS';

export const PURCHASE_STATUS_CART = 'HANDLEKURV';
export const PURCHASE_STATUS_CONFIRMED = 'BETALT';
export const PURCHASE_STATUS_DELETED = 'SLETTET_GRUNNET_MANGLENDE_PERSONDETALJ';

const INATUR_SALES_CHANNEL = 'forhandler_2';

const setTypeSuccess = cardType => ({
  type: SET_TYPE_SUCCESS,
  cardType,
});

const setPurchaseStageSuccess = stage => ({
  type: SET_PURCHASE_STAGE_SUCCESS,
  stage,
});

const setCustomerSuccess = customer => ({
  type: SET_CUSTOMER,
  customer,
});

const setOfferSuccess = offer => ({
  type: SET_OFFER,
  offer,
});

const setPeriodCardSuccess = period => ({
  type: SET_PERIOD,
  period,
});

const setPriceSuccess = price => ({
  type: SET_PRICE,
  price,
});

const addToPriceSuccess = price => ({
  type: ADD_TO_PRICE,
  price,
});

const removeFromPriceSuccess = price => ({
  type: REMOVE_FROM_PRICE,
  price,
});

const setPurchaseSuccess = purchase => ({
  type: SET_PURCHASE_SUCCESS,
  purchase,
});

const fetchCustomerByPhoneSuccess = customer => ({
  type: FETCH_CUSTOMER_BY_PHONE_SUCCESS,
  customer,
});

const resetPurchaseSuccess = () => ({
  type: RESET_PURCHASE_SUCCESS,
});

const resetPurchaseSameCardSuccess = () => ({
  type: RESET_PURCHASE_SAME_CARD_SUCCESS,
});

const setHunterSuccess = hunter => ({
  type: SET_HUNTER,
  hunter,
});

export const reservePurchase = (offerId, cardId, dates = null, time = null) => async dispatch => {
  dispatch(requestBegin(RESERVE_PURCHASE_SUCCESS));
  const { json } = await postRequest('/api/v1/forhandlere/kjop', {
    tilbud: offerId,
    kort: cardId,
    datovalg: dates,
    startTidspunkt: time,
  });
  dispatch(requestEnd(RESERVE_PURCHASE_SUCCESS));
  return dispatch(setPurchaseSuccess(json));
};

export const updateOptions = (purchase, options) => async dispatch => {
  dispatch(requestBegin(UPDATE_OPTIONS_SUCCESS));
  const { json } = await putRequest(
    `/api/v1/forhandlere/kjop/${purchase.id}/valg`,
    options.map(option => option.id)
  );
  dispatch(requestEnd(UPDATE_OPTIONS_SUCCESS));
  return dispatch(setPurchaseSuccess(json));
};

export const updateStatus = (purchase, status, language = 'no') => async dispatch => {
  dispatch(requestBegin(UPDATE_STATUS_SUCCESS));
  const { json } = await putRequest(`/api/v1/forhandlere/kjop/${purchase.id}/status?sales-channel=${INATUR_SALES_CHANNEL}&lang=${language}`, status);
  dispatch(requestEnd(UPDATE_STATUS_SUCCESS));
  return dispatch(setPurchaseSuccess(json));
};

export const fetchCustomerByPhone = (phone, kjop) => async dispatch => {
  const { json } = await getRequest(
    `/api/v1/forhandlere/kunder?mobilnummer=${phone}&kjopid=${kjop}`
  );
  return dispatch(fetchCustomerByPhoneSuccess(json));
};

export const updateCustomer = (id, customer) => async dispatch => {
  dispatch(requestBegin(UPDATE_CUSTOMER_SUCCESS));
  const { json } = await putRequest(`/api/v1/forhandlere/kjop/${id}/betaler`, customer);
  dispatch(requestEnd(UPDATE_CUSTOMER_SUCCESS));
  return dispatch(setPurchaseSuccess(json));
};

export const updateHunter = (id, hunter) => async dispatch => {
  dispatch(requestBegin(UPDATE_HUNTER_SUCCESS));
  const { json } = await putRequest(`/api/v1/forhandlere/kjop/${id}/jeger`, hunter);
  dispatch(requestEnd(UPDATE_HUNTER_SUCCESS));
  return dispatch(setPurchaseSuccess(json));
};

export const fetchReceipt = async (orderNumber) => {
  const pdf = await getPdfRequest(`/api/v1/forhandlere/kvittering/ordrenummer/${orderNumber}/pdf`);
  const blob = await pdf.blob();
  const data = new Blob([blob], { type: 'application/pdf' });
  const url = window.URL.createObjectURL(data);
  const link = document.createElement('a');
  link.href = url;
  link.download = `ordrenummer-${orderNumber}.pdf`;
  link.click();
}

export const resetPurchase = () => dispatch => dispatch(resetPurchaseSuccess());

export const resetPurchaseSameCard = () => dispatch => dispatch(resetPurchaseSameCardSuccess());

export const setType = cardType => dispatch => dispatch(setTypeSuccess(cardType));

export const setPurchaseStage = stage => dispatch => dispatch(setPurchaseStageSuccess(stage));

export const setOffer = offer => dispatch => dispatch(setOfferSuccess(offer));

export const setPeriodCard = period => dispatch => dispatch(setPeriodCardSuccess(period));

export const setCustomer = customer => dispatch => dispatch(setCustomerSuccess(customer));

export const setPurchase = purchase => dispatch => dispatch(setPurchaseSuccess(purchase));

export const setPrice = price => dispatch => dispatch(setPriceSuccess(price));

export const addToPrice = price => dispatch => dispatch(addToPriceSuccess(price));

export const removeFromPrice = price => dispatch => dispatch(removeFromPriceSuccess(price));

export const setHunter = hunter => dispatch => dispatch(setHunterSuccess(hunter));
