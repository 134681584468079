import React from 'react';
import PropTypes from 'prop-types';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { withStyles, FormHelperText } from '@material-ui/core';

const styles = theme => ({
  inputField: {
    width: '100%',
    background: 'none',
    border: 'none',
    padding: '6px 0 7px',
    fontSize: 14,
  },

  calendar: {
    width: 300,
    fontFamily: theme.fonts.barlow,
    fontSize: 16,
    color: theme.colors.fontColor1,
  },

  fieldContainer: {
    background: 'rgba(253, 252, 249, 1)',
    border: '1.33px solid rgba(210,210,210,1)',
    borderRadius: '1.33px',
    padding: 4,
    paddingLeft: 8,
    fontFamily: theme.fonts.barlowSemiCondensed,
  },
});

const modifiers = {
  saturdays: { daysOfWeek: [6] },
  sundays: { daysOfWeek: [0] },
  weekday: { daysOfWeek: [1, 2, 3, 4, 5] },
};

const modifiersStyles = {
  saturdays: {
    backgroundColor: '#c2d4c6',
    borderRadius: 0,
  },
  sundays: {
    backgroundColor: '#c2d4c6',
    borderRadius: 0,
  },
  weekday: {
    backgroundColor: 'rgba(253, 252, 249, 1)',
    borderRadius: 0,
  },

  today: {
    fontFamily: 'barlow-semi-condensed-medium',
    fontWeight: 'normal',
  },
  disabled: {
    mixBlendMode: 'multiply',
    fontWeight: 'normal',
    cursor: 'not-allowed',
  },
  outside: {
    fontFamily: 'barlow-semi-condensed',
  },
};

const DatePickerField = ({
  classes,
  onChange,
  selectedDay,
  disabledDays,
  displayError,
  errorText,
}) => (
  <div>
    <div className={classes.fieldContainer}>
      <DayPickerInput
        placeholder="DD/MM/YYYY"
        format="DD/MM/YYY"
        inputProps={{ className: classes.inputField }}
        dayPickerProps={{
          className: classes.calendar,
          disabledDays,
          modifiersStyles,
          modifiers,
          firstDayOfWeek: 1,
          renderDay,
          showOutsideDays: true,
          locale: 'no',
        }}
        onDayChange={onChange}
        selectedDay={selectedDay}
      />
    </div>
    {displayError && <FormHelperText className={classes.errorText}>{errorText}</FormHelperText>}
  </div>
);

const renderDay = (day, mods) => {
  const cellStyle = {
    height: 42,
  };
  const dateBaseStyle = {
    height: 42,
    width: 42,
    display: 'table-cell',
    verticalAlign: 'middle',
  };
  const dateSelected = {
    background: 'rgba(50, 55, 50, 1)',
    color: 'white',
    borderRadius: '3rem',
  };

  const dateStyle = mods.selected ? { ...dateBaseStyle, ...dateSelected } : dateBaseStyle;

  return (
    <div style={cellStyle}>
      <div style={dateStyle}>{day.getDate()}</div>
    </div>
  );
};

DatePickerField.defaultProps = {
  selectedDay: null,
  errorText: null,
};

DatePickerField.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedDay: PropTypes.object,
  disabledDays: PropTypes.func.isRequired,
  displayError: PropTypes.bool.isRequired,
  errorText: PropTypes.string,
};

export default withStyles(styles)(DatePickerField);
