import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import PersonaliaForm from 'components/Personalia/PersonaliaForm';
import { Typography } from '@material-ui/core';
import NextButton from 'components/Common/NextButton';
import arrow from 'static/icons/arrow.svg';

const styles = theme => ({
  container: {
    postion: 'absolute',
    height: 'calc(100% - 80px)',
    width: '100%',
    background: 'rgba(253,252,249,1)',
    padding: 50,
    marginTop: 70,
    marginBottom: 40,
  },
  title: {
    fontFamily: theme.fonts.barlow,
    color: 'rgba(50,55,50,1)',
    marginBottom: '2em',
  },
});

const Personalia = ({
  classes,
  customer,
  onChange,
  onDropdownChange,
  onNextClick,
  validationErrors,
  displayErrors,
  isValid,
  showMemberInput,
  showKommuneInput,
  updatingCustomer,
  fetchPersonaliaFromNumber,
  customerNotFound,
}) => (
  <div>
    <div className={`${classes.container}`}>
      <Typography className={classes.title} variant="title">
        Kundeinformasjon
      </Typography>
      <PersonaliaForm
        customer={customer}
        onChange={onChange}
        onDropdownChange={onDropdownChange}
        validationErrors={validationErrors}
        displayErrors={displayErrors}
        showMemberInput={showMemberInput}
        showKommuneInput={showKommuneInput}
        fetchPersonaliaFromNumber={fetchPersonaliaFromNumber}
        customerNotFound={customerNotFound}
      />
    </div>
    <NextButton
      disabled={!isValid}
      text="Gå videre"
      icon={arrow}
      onClick={onNextClick}
      loading={updatingCustomer}
    />
  </div>
);

Personalia.propTypes = {
  classes: PropTypes.object.isRequired,
  customer: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onDropdownChange: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
  validationErrors: PropTypes.object.isRequired,
  displayErrors: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  showMemberInput: PropTypes.bool.isRequired,
  showKommuneInput: PropTypes.bool.isRequired,
  updatingCustomer: PropTypes.bool.isRequired,
  fetchPersonaliaFromNumber: PropTypes.func.isRequired,
  customerNotFound: PropTypes.bool.isRequired,
};

export default withStyles(styles)(Personalia);
