import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { toggleMenuActive, setActiveItem } from 'store/actions/menu';
import { logout } from 'store/actions/auth';
import Menu from 'components/Menu/Menu';

class MenuContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: 0,
      active: false,
      expand: false,
    };
    this.onToggleClick = this.onToggleClick.bind(this);
    this.onItemClick = this.onItemClick.bind(this);
    this.onUserItemClick = this.onUserItemClick.bind(this);
    this.onLogoutClick = this.onLogoutClick.bind(this);
    this.onHomeClick = this.onHomeClick.bind(this);
  }

  onToggleClick() {
    const { active } = this.state;
    this.props.toggleMenuActive(active);
    this.setState({ active: !active, expand: false });
  }

  onItemClick(route) {
    this.setState({ expand: false });
    this.props.toggleMenuActive(this.state.active);
    this.props.history.push(route);
  }

  onUserItemClick() {
    const { expand } = this.state;
    this.setState({ expand: !expand });
  }

  onHomeClick() {
    this.props.history.push('/');
  }

  onLogoutClick() {
    const { active } = this.state;
    this.props.toggleMenuActive(active);
    this.setState({ active: false });
    this.props.logout();
  }

  render() {
    const { activeItem, active, expand } = this.state;
    const { username } = this.props;
    return (
      <Menu
        onToggleClick={this.onToggleClick}
        onHomeClick={this.onHomeClick}
        onItemClick={this.onItemClick}
        onUserItemClick={this.onUserItemClick}
        logout={this.onLogoutClick}
        active={active}
        activeItem={activeItem}
        expand={expand}
        username={username}
        location={this.props.location}
      />
    );
  }
}

MenuContainer.propTypes = {
  history: PropTypes.object.isRequired,
  toggleMenuActive: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  username: state.get.auth.user.username,
  types: state.get.types,
});

export default withRouter(
  connect(
    mapStateToProps,
    { toggleMenuActive, setActiveItem, logout }
  )(MenuContainer)
);
