import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { Raven } from 'config';
import createRavenMiddleware from 'raven-for-redux';
import rootReducer from 'store/reducers/root';
import gettersReducer from 'store/reducers/getters';

export const configureStore = (history, persistedState) => {
  const middleware = routerMiddleware(history);
  const raven = createRavenMiddleware(Raven);
  const store = createStore(
    connectRouter(history)(
      combineReducers({
        get: gettersReducer(() => store),
        rootReducer,
      })
    ),
    persistedState,
    composeWithDevTools(applyMiddleware(thunk, middleware, raven))
  );
  return store;
};

export const removeState = () => {
  sessionStorage.removeItem('state');
};

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem('state', serializedState);
  } catch (error) {
    console.log(error);
  }
};

export const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};
