import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Routes from 'routes';
import { ConnectedRouter } from 'connected-react-router';

const styles = {
  container: {
    height: '100%',
  },
  content: {
    flexGrow: 1,
    minWidth: 0,
    height: 'inherit',
  },
};

function AppContainer({ classes, history }) {
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <ConnectedRouter history={history}>
          <Routes />
        </ConnectedRouter>
      </div>
    </div>
  );
}

AppContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  isAuthenticated: state.get.auth.isAuthenticated,
});

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(AppContainer));
