import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Login from 'components/Login';
import { login } from 'store/actions/auth';
import { resetPassword, setEmailSent } from 'store/actions/user';

class LoginContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onSubmit(event) {
    event.preventDefault();
    const { username, password } = this.state;
    this.props.login(username, password).then(() => {
      this.props.history.push({ pathname: '/', state: { transition: 'slide' } });
    });
  }

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    const { username, password } = this.state;
    const { errors, isAuthenticating } = this.props;
    return (
      <div className="slide-out">
        <Login
          username={username}
          password={password}
          onSubmit={this.onSubmit}
          onChange={this.onChange}
          isAuthenticating={isAuthenticating}
          errors={errors}
        />
      </div>
    );
  }
}

LoginContainer.propTypes = {
  history: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
  errors: PropTypes.array.isRequired,
  isAuthenticating: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.get.auth.errors,
  isAuthenticating: state.get.auth.isAuthenticating,
  emailSent: state.get.user.emailSent,
  userError: state.get.user.error,
});

export default connect(mapStateToProps, { login, resetPassword, setEmailSent })(LoginContainer);
