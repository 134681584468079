import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import NextButton from 'components/Common/NextButton';
import Tab from 'components/Common/Tab';
import arrow from 'static/icons/arrow.svg';

const styles = theme => ({
  container: {
    height: '100%',
    width: '100%',
    background: 'rgba(253,252,249,1)',
    padding: 50,
    marginTop: 70,
    marginBottom: 40,
  },
  title: {
    fontFamily: theme.fonts.barlow,
    color: theme.colors.fontColor1,
  },
  divider: {
    border: 'none',
    borderTop: '1px dashed rgba(70,130,90,1)',
    width: '100%',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
});

const isActive = (activeOptions, option) =>
  activeOptions.filter(ao => ao.id === option.id).length > 0;

const isDisabled = (activeOptions, option) =>
  activeOptions.filter(ao => ao.illegalCombination.includes(option.id)).length > 0;

const OtherOptions = ({
  classes,
  options,
  activeOptions,
  updatingOptions,
  onOptionClick,
  onNextClick,
}) => {
  const discounts = options
    .filter(option => option.rabatt)
    .map(option => {
      const active = isActive(activeOptions, option);
      return (
        <Grid key={option.id} item xs={12} md={6} lg={6}>
          <Tab
            title={option.navn}
            extra={`${option.pris} kr`}
            active={active}
            disabled={isDisabled(activeOptions, option)}
            onClick={() => onOptionClick(option, active)}
          />
        </Grid>
      );
    });

  const extras = options
    .filter(option => !option.rabatt)
    .map(option => {
      const active = isActive(activeOptions, option);
      return (
        <Grid key={option.id} item xs={12} md={6} lg={6}>
          <Tab
            title={option.navn}
            extra={`${option.pris} kr`}
            active={active}
            disabled={isDisabled(activeOptions, option)}
            onClick={() => onOptionClick(option, active)}
          />
        </Grid>
      );
    });

  return (
    <div>
      <div className={classes.container}>
        <div>
          <Grid container spacing={24}>
            {discounts.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="title" className={classes.title}>
                  - Rabatter
                </Typography>
              </Grid>
            )}
            {discounts}

            <Grid item xs={12}>
              <hr className={classes.divider} />
            </Grid>

            {extras.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="title" className={classes.title}>
                  + Tillegg
                </Typography>
              </Grid>
            )}
            {extras}
          </Grid>
        </div>
      </div>
      <NextButton text="GÅ VIDERE" icon={arrow} onClick={onNextClick} loading={updatingOptions} />
    </div>
  );
};

OtherOptions.propTypes = {
  classes: PropTypes.object.isRequired,
  options: PropTypes.array,
  activeOptions: PropTypes.array.isRequired,
  updatingOptions: PropTypes.bool.isRequired,
  onOptionClick: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
};

OtherOptions.defaultProps = {
  options: [],
};

export default withStyles(styles)(OtherOptions);
