import { getRequest, putRequest, postRequest, deleteRequest } from 'store/actions/request';
import { requestBegin, requestEnd } from 'store/actions/status';

export const RESET_SALE_SUCCESS = 'RESET_SALE_SUCCESS';
export const SET_SALE_SUCCESS = 'SET_SALE_SUCCESS';
export const FETCH_SALES_SUCCESS = 'FETCH_SALES_SUCCESS';
export const EMPTY_SALES_SUCCESS = 'EMPTY_SALES_SUCCESS';
export const FETCH_SALE_DETAILS_SUCCESS = 'FETCH_SALE_DETAILS_SUCCESS';
export const DELETE_SALE_SUCCESS = 'DELETE_SALE_SUCCESS';
export const EDIT_SALE_SUCCESS = 'EDIT_SALE_SUCCESS';
export const SEND_RECEIPT_SUCCESS = 'SEND_RECEIPT_SUCCESS';

const setSaleSuccess = sale => ({
  type: SET_SALE_SUCCESS,
  sale,
});

const resetSaleSuccess = () => ({
  type: RESET_SALE_SUCCESS,
});

const fetchSalesSuccess = sales => ({
  type: FETCH_SALES_SUCCESS,
  sales,
});

const emptySalesSuccess = () => ({
  type: EMPTY_SALES_SUCCESS,
});

const fetchSaleDetailsSuccess = saleDetails => ({
  type: FETCH_SALE_DETAILS_SUCCESS,
  saleDetails,
});

const deleteSalesSuccess = id => ({
  type: DELETE_SALE_SUCCESS,
  id,
});

const editSaleSuccess = (buyer, id) => ({
  type: EDIT_SALE_SUCCESS,
  buyer,
  id,
});

const sendReceiptSuccess = id => ({
  type: SEND_RECEIPT_SUCCESS,
  id,
});

export const setSale = sale => dispatch => dispatch(setSaleSuccess(sale));

export const resetSale = () => dispatch => dispatch(resetSaleSuccess());

export const fetchSales = (size, page, query) => async dispatch => {
  dispatch(requestBegin(FETCH_SALES_SUCCESS));
  let requestString = `/api/v1/forhandlere/salg?page=${page}&size=${size}`;
  if (query && query.length > 0) requestString += `&sok=${query}`;
  const { json } = await getRequest(requestString);
  dispatch(requestEnd(FETCH_SALES_SUCCESS));
  return dispatch(fetchSalesSuccess(json));
};

export const emptySales = () => dispatch => dispatch(emptySalesSuccess());

export const fetchSaleDetails = id => async dispatch => {
  dispatch(requestBegin(FETCH_SALE_DETAILS_SUCCESS));
  const { json } = await getRequest(`/api/v1/forhandlere/salg/${id}`);
  dispatch(requestEnd(FETCH_SALE_DETAILS_SUCCESS));
  return dispatch(fetchSaleDetailsSuccess(json));
};

export const deleteSale = id => async dispatch => {
  dispatch(requestBegin(DELETE_SALE_SUCCESS));
  await deleteRequest(`/api/v1/forhandlere/salg/${id}`);
  dispatch(requestEnd(DELETE_SALE_SUCCESS));
  return dispatch(deleteSalesSuccess(id));
};

export const editSale = (id, buyer) => async dispatch => {
  dispatch(requestBegin(EDIT_SALE_SUCCESS));
  const { json } = await putRequest(`/api/v1/forhandlere/salg/${id}/betaler`, buyer);
  dispatch(requestEnd(EDIT_SALE_SUCCESS));
  return dispatch(editSaleSuccess(json, id));
};

export const sendReceipt = id => async dispatch => {
  dispatch(requestBegin(SEND_RECEIPT_SUCCESS));
  await postRequest(`/api/v1/forhandlere/salg/${id}/kvittering`);
  dispatch(requestEnd(SEND_RECEIPT_SUCCESS));
  return dispatch(sendReceiptSuccess(id));
};
