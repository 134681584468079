import { FETCH_TYPES_SUCCESS } from 'store/actions/home';

const initialState = {
  types: [],
};

export default function homeReducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_TYPES_SUCCESS:
      return Object.assign({}, state, { types: action.types });
    default:
      return state;
  }
}
