import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import CloseButton from './CloseButton';

const styles = () => ({
  modal: {
    background: 'rgba(0,0,0,0.4)',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1300,
  },

  modalBox: {
    position: 'fixed',
    background: 'white',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: 511,
  },

  modalSidebar: {
    position: 'fixed',
    background: 'white',
    height: '100%',
    right: 0,
    width: 660,
  },
  displayBlock: {
    animation: 'fadein 200ms',
    display: 'block',
  },

  displayNone: {
    display: 'none',
  },

  slideIn: {
    animation: 'slidein 400ms',
  },
});

const Modal = ({ onCloseClick, show, sidebar, children, classes }) => {
  const showHideClassName = `${classes.modal} ${show ? classes.displayBlock : classes.displayNone}`;
  return (
    <div className={`${showHideClassName} ${classes.fadeIn}`}>
      <section
        className={sidebar ? `${classes.modalSidebar} ${classes.slideIn}` : classes.modalBox}>
        <CloseButton onClick={onCloseClick} />
        {children}
      </section>
    </div>
  );
};

Modal.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  sidebar: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(Modal);
