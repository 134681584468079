// Input: [{a:1, b:2}, {a:1, b:3}, {a:2, b:2}, {a:2, b:4}], el => el.a
// Output: [[{a:1, b:2}, {a:1, b:3}], [a:2,b:2}, {a:2,b:4}]]
export function groupBy(array, groupSelector, sortSelector) {
  const groups = [];
  array.forEach(element => {
    const groupId = groupSelector(element);
    const sortId = sortSelector(element);

    const group = getGroup(groups, groupId);
    if (group) {
      group.elements.push(element);
    } else {
      groups.push({ name: groupId, sortKey: sortId, elements: [element] });
    }
  });

  groups.sort(comparator).reverse();
  return groups;
}

function getGroup(arr, key) {
  return arr.find(x => x.name === key);
}

function comparator(a, b) {
  return a.sortKey.localeCompare(b.sortKey);
}

export function setArrayImmutable(arr, i, value) {
  return Object.assign([...arr], { [i]: value });
}
