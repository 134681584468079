import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, Tabs, Tab, IconButton } from '@material-ui/core';

const styles = theme => ({
  flex: {
    flex: 1,
  },
  navbar: {
    background: 'rgba(255,255,255,0)',
    height: theme.custom.menubarHeight,
    width: '100%',
  },
  title: {
    fontWeight: 300,
    fontSize: '16px',
    flex: 1,
  },
  font: {
    fontFamily: theme.fonts.barlowSemiCondensed,
    color: theme.colors.fontColor1,
  },
  menuItem: {
    fontWeight: 700,
    fontSize: '18px',
  },
  indicator: {
    backgroundColor: 'inherit',
  },
});

const Navbar = ({
  classes,
  className,
  style,
  position,
  fontColor,
  title,
  icon,
  onClick,
  items,
}) => {
  const tabs = items.map(item => (
    <Tab
      className={`${classes.font} ${classes.menuItem}`}
      label={item.label}
      onClick={item.onClick}
    />
  ));
  return (
    <AppBar position={position} className={`${classes.navbar} ${className}`} style={style}>
      <Toolbar>
        <IconButton onClick={onClick} color="inherit" aria-label="Menu">
          <img src={icon} alt="" />
        </IconButton>
        <Typography variant="title" className={`${classes.title} ${classes.font}`}>
          <span style={{ color: fontColor }}>{title}</span>
        </Typography>
        {tabs.length > 0 && (
          <Tabs classes={{ indicator: classes.indicator }} value={0}>
            {tabs}
          </Tabs>
        )}
      </Toolbar>
    </AppBar>
  );
};

Navbar.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  fontColor: PropTypes.string,
  items: PropTypes.array,
  position: PropTypes.string,
};

Navbar.defaultProps = {
  items: [],
  className: '',
  style: null,
  fontColor: '',
  position: 'fixed',
};

export default withStyles(styles)(Navbar);
