import iconInnland from 'static/icons/icon_innland.svg';
import iconSmaavilt from 'static/icons/icon_jakt_smaa.svg';
import iconStorvilt from 'static/icons/icon_jakt_stor.svg';
import iconLaks from 'static/icons/icon_laks.svg';
import iconInnlandGreen from 'static/icons/icon_innland_green.svg';
import iconSmaaviltGreen from 'static/icons/icon_jakt_smaa_green.svg';
import iconStorviltGreen from 'static/icons/icon_jakt_stor_green.svg';
import iconLaksGreen from 'static/icons/icon_laks_green.svg';

export const findTypeIcon = (type, green = false) => {
  switch (type) {
    case 'INNLANDSFISKETILBUD': {
      return green ? iconInnlandGreen : iconInnland;
    }
    case 'SMAAVILTTILBUD': {
      return green ? iconSmaaviltGreen : iconSmaavilt;
    }
    case 'STORVILTTILBUD': {
      return green ? iconStorviltGreen : iconStorvilt;
    }
    case 'LAKSEFISKETILBUD': {
      return green ? iconLaksGreen : iconLaks;
    }
    default:
      return null;
  }
};

export const isLoepende = card =>
  card.type === 'LOEPENDEKORT' ||
  card.type === 'STORVILTLOEPENDEKORT' ||
  card.type === 'FISKELOEPENDEKORT';

export const isFastperiode = card => !isLoepende(card);

export const padStart = (s, n, c) => {
  let r = s
  while(r.length < n) {
    r = c + r
  }
  return r;
}
