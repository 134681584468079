export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const SET_EMAIL_SENT = 'SET_EMAIL_SENT';

function resetPasswordRequest() {
  return {
    type: RESET_PASSWORD_REQUEST,
  };
}

function resetPasswordSuccess() {
  return {
    type: RESET_PASSWORD_SUCCESS,
  };
}

function resetPasswordFailure(data) {
  return {
    type: RESET_PASSWORD_FAILURE,
    data,
  };
}

function setEmailSentSuccess(value) {
  return {
    type: SET_EMAIL_SENT,
    value,
  };
}

export function resetPassword(username) {
  return dispatch => {
    dispatch(resetPasswordRequest());
    return fetch(`/api/v1/users/${username}/password`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
      },
    }).then(
      response =>
        response.status === 204
          ? dispatch(resetPasswordSuccess())
          : dispatch(resetPasswordFailure({ error: { message: 'Ugyldig e-post' } }))
    );
  };
}

export function setEmailSent(value) {
  return dispatch => dispatch(setEmailSentSuccess(value));
}
