import { CREATE_REPORT_SUCCESS } from 'store/actions/report';
import { FETCH_TILBYDERE_SUCCESS } from '../actions/report';

const initialState = {
  report: null,
  tilbydere: [],
};

export default function reportReducer(state = initialState, action = {}) {
  if (action.type === CREATE_REPORT_SUCCESS) return { ...state, report: action.report };
  else if (action.type === FETCH_TILBYDERE_SUCCESS)
    return { ...state, tilbydere: action.tilbydere };
  else return state;
}
