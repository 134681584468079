import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  resetSale,
  deleteSale,
  fetchSaleDetails,
  sendReceipt,
  FETCH_SALE_DETAILS_SUCCESS,
  SEND_RECEIPT_SUCCESS,
} from 'store/actions/sales';
import { fetchReceipt } from 'store/actions/order';
import SaleDetails from 'components/SaleDetails';

class SaleDetailsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.onBackClick = this.onBackClick.bind(this);
    this.onCloseDeleteModalClick = this.onCloseDeleteModalClick.bind(this);
    this.onCloseEditModalClick = this.onCloseEditModalClick.bind(this);
    this.onSendMailClick = this.onSendMailClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onPrintClick = this.onPrintClick.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.onDeletion = this.onDeletion.bind(this);
    this.afterEdit = this.afterEdit.bind(this);

    this.state = {
      saleId: this.props.match.params.id,
      showEditModal: false,
      showDeleteModal: false,
      showReceiptMessage: false,
    };
  }

  componentDidMount() {
    this.props.fetchSaleDetails(this.state.saleId);
  }

  onBackClick() {
    this.props.resetSale();
    this.props.history.push({
      pathname: '/sales',
      state: { transition: 'fade' },
    });
  }

  onCloseDeleteModalClick() {
    this.setState({ showDeleteModal: false });
  }

  onCloseEditModalClick() {
    this.setState({ showEditModal: false });
  }

  onDeleteClick() {
    this.setState({ showDeleteModal: true });
  }

  onEditClick() {
    this.setState({ showEditModal: true });
  }

  onPrintClick() {
    this.props.fetchReceipt(this.props.saleDetails.ordrenummer);
  }

  onDeletion() {
    this.props.deleteSale(this.state.saleId).then(() => {
      this.props.history.push({
        pathname: '/sales',
        state: { transition: 'fade' },
      });
    });
  }

  onSendMailClick() {
    this.props.sendReceipt(this.state.saleId);
    this.state.showReceiptMessage = true;
    setTimeout(() => {
      this.setState({ showReceiptMessage: false });
    }, 6000);
  }

  afterEdit() {
    this.props.fetchSaleDetails(this.state.saleId);
  }

  render() {
    const { saleDetails, loading, sendingReceipt } = this.props;
    const showMessage = this.state.showReceiptMessage && !sendingReceipt;
    return (
      <SaleDetails
        onBackClick={this.onBackClick}
        onDeleteButtonClick={this.onDeleteClick}
        onEditButtonClick={this.onEditClick}
        onCloseDeleteModalClick={this.onCloseDeleteModalClick}
        onPrintButtonClick={this.onPrintClick}
        onCloseEditModalClick={this.onCloseEditModalClick}
        onSendMailClick={this.onSendMailClick}
        onDeletion={this.onDeletion}
        saleId={this.state.saleId}
        details={saleDetails}
        showDeletionModal={this.state.showDeleteModal}
        showEditModal={this.state.showEditModal}
        loading={loading}
        afterEdit={this.afterEdit}
        showReceiptMessage={showMessage}
      />
    );
  }
}

SaleDetailsContainer.propTypes = {
  fetchSaleDetails: PropTypes.func.isRequired,
  deleteSale: PropTypes.func.isRequired,
  resetSale: PropTypes.func.isRequired,
  sendReceipt: PropTypes.func.isRequired,
  sendingReceipt: PropTypes.bool.isRequired,
  saleDetails: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  fetchReceipt: PropTypes.func.isRequired,
};

SaleDetailsContainer.defaultProps = {
  saleDetails: null,
};

const mapStateToProps = state => ({
  saleDetails: state.get.saleDetails,
  loading: state.get.actionsInProgress.includes(FETCH_SALE_DETAILS_SUCCESS),
  sendingReceipt: state.get.actionsInProgress.includes(SEND_RECEIPT_SUCCESS),
});

export default withRouter(
  connect(
    mapStateToProps,
    { fetchSaleDetails, resetSale, deleteSale, sendReceipt, fetchReceipt }
  )(SaleDetailsContainer)
);
