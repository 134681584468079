import { combineReducers } from 'redux';
import authReducer from 'store/reducers/auth';
import menuReducer from 'store/reducers/menu';
import statusReducer from 'store/reducers/status';
import homeReducer from 'store/reducers/home';
import orderReducer from 'store/reducers/order';
import offersReducer from 'store/reducers/offers';
import userReducer from 'store/reducers/user';
import salesReducer from 'store/reducers/sales';
import reportReducer from 'store/reducers/report';

export default combineReducers({
  statusReducer,
  menuReducer,
  homeReducer,
  orderReducer,
  offersReducer,
  authReducer,
  userReducer,
  salesReducer,
  reportReducer,
});
