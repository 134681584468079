import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { findTypeIcon } from 'utils/misc';
import { format } from 'date-fns';

const styles = theme => ({
  row: {
    fontFamily: theme.fonts.barlowSemiCondensed,
    fontSize: 16,
    color: theme.colors.fontColor1,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '35px 0',
    borderBottom: '1px solid rgba(230,230,230,1)',
    '&:hover': {
      cursor: 'pointer',
      background: 'rgba(230,230,230,1)',
    },
    '&:last-child': {
      borderBottom: 'none',
    },
  },

  iconColumn: {
    marginRight: 35,
  },
  timeColumn: {
    marginRight: 35,
  },
  nameColumn: {
    width: 210,
  },
  infoColumn: {
    textTransform: 'uppercase',
    flexGrow: 1,
  },
});

const SalesRow = ({ classes, sale, onSaleClick }) => {
  const time = format(new Date(sale.created), 'HH:mm');
  return (
    <div className={classes.row} onClick={() => onSaleClick(sale)}>
      <span className={classes.iconColumn}>
        <img src={findTypeIcon(sale.type, true)} alt="" />
      </span>
      <span className={classes.timeColumn}>{time}</span>
      <span className={classes.nameColumn}>{sale.navn}</span>
      <span className={classes.infoColumn}>{sale.tilbudTitle}</span>
    </div>
  );
};

SalesRow.propTypes = {
  classes: PropTypes.object.isRequired,
  sale: PropTypes.object.isRequired,
  onSaleClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(SalesRow);
