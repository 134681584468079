import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Summary from 'components/Summary';
import {
  setPurchaseStage,
  STAGE_SUMMARY,
  UPDATE_STATUS_SUCCESS,
  updateStatus,
  PURCHASE_STATUS_CONFIRMED,
} from 'store/actions/order';

class SummaryContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      norwegian: true,
    };
    this.onNorwegianSelect = this.onNorwegianSelect.bind(this);
    this.onEnglishSelect = this.onEnglishSelect.bind(this);
    this.onChangeClick = this.onChangeClick.bind(this);
    this.onBuyClick = this.onBuyClick.bind(this);
  }

  componentDidMount() {
    this.props.setPurchaseStage(STAGE_SUMMARY);
  }

  onNorwegianSelect() {
    this.setState({ norwegian: true });
  }

  onEnglishSelect() {
    this.setState({ norwegian: false });
  }

  onChangeClick() {
    const { order } = this.props;
    this.props.history.push({
      pathname: `/purchase/personalia/${order.purchase.id}`,
      state: { transition: 'change' },
    });
  }

  onBuyClick() {
    const { order } = this.props;
    this.props.updateStatus(order.purchase, PURCHASE_STATUS_CONFIRMED, this.state.norwegian ? 'no' : 'en').then(() => {
      this.props.history.push({
        pathname: `/purchase/receipt/${order.purchase.id}`,
        state: { transition: 'fade' },
      });
    });
  }

  render() {
    const { order, updatingStatus } = this.props;
    const { norwegian } = this.state;
    return (
      <Summary
        onNorwegianSelect={this.onNorwegianSelect}
        onEnglishSelect={this.onEnglishSelect}
        onBuyClick={this.onBuyClick}
        onChangeClick={this.onChangeClick}
        norwegian={norwegian}
        order={order}
        updatingStatus={updatingStatus}
      />
    );
  }
}

SummaryContainer.propTypes = {
  history: PropTypes.object.isRequired,
  setPurchaseStage: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  updateStatus: PropTypes.func.isRequired,
  updatingStatus: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  order: state.get.order,
  updatingStatus: state.get.actionsInProgress.includes(UPDATE_STATUS_SUCCESS),
});

export default withRouter(
  connect(
    mapStateToProps,
    { setPurchaseStage, updateStatus }
  )(SummaryContainer)
);
