import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  root: {},
  label: {
    fontFamily: theme.fonts.barlow,
    fontSize: 18,
    color: theme.colors.fontColor1,
    display: 'block',
    textAlign: 'center',
    marginBottom: '1rem',
  },
  loaderContainer: {
    textAlign: 'center',
  },
  loader: {
    color: theme.colors.fontColor1,
  },
});

const Loading = ({ classes, active, label }) => (
  <div>
    {active && (
      <div>
        <span className={classes.label}>{label}</span>
        <div className={classes.loaderContainer}>
          <CircularProgress className={classes.loader} size={40} />
        </div>
      </div>
    )}
  </div>
);

Loading.propTypes = {
  classes: PropTypes.object.isRequired,
  active: PropTypes.bool.isRequired,
  label: PropTypes.string,
};

Loading.defaultProps = {
  label: null,
};

export default withStyles(styles)(Loading);
