import {
  VALIDATE_AUTH_REQUEST,
  VALIDATE_AUTH_SUCCESS,
  VALIDATE_AUTH_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
} from 'store/actions/auth';

const initialState = {
  user: null,
  isAuthenticating: false,
  isAuthenticated: false,
  authValidated: false,
  errors: [],
};
// eslint-disable-next-line default-param-last
export default function auth(state = initialState, action) {
  switch (action.type) {
    case VALIDATE_AUTH_REQUEST:
      return { ...state, isAuthenticating: true };

    case VALIDATE_AUTH_SUCCESS:
      return {
        ...state,
        user: action.user,
        isAuthenticating: false,
        isAuthenticated: true,
        authValidated: true,
      };

    case VALIDATE_AUTH_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticating: false,
        authValidated: true,
      };

    case LOGOUT_REQUEST:
      return state;

    case LOGOUT_SUCCESS:
      return { ...state, ...initialState, authValidated: true };

    case LOGOUT_FAILURE:
      return { ...state, errors: action.errors };

    default:
      return state;
  }
}
