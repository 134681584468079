import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { format, isBefore } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

const styles = theme => ({
  root: {
    padding: '1em',
  },
  leftElement: {
    fontFamily: theme.fonts.barlowCondensed,
  },
  rightElement: {
    float: 'right',
    fontFamily: theme.fonts.barlowSemiCondensed,
  },
  tooltip: {
    backgroundColor: 'rgba(253,252,249)',
    color: 'rgba(50,55,50,1)',
    opacity: '1 !important',
  },
  periodPrice: {
    fontFamily: theme.fonts.barlowCondensed,
    fontSize: '12pt',
    marginTop: '8px',
    marginBottom: '8px',
  },
  expired: {
    color: theme.colors.buttonDanger,
  },
});

const PeriodInformation = ({
  classes,
  price,
  fromDate,
  toDate,
  disabledPeriod,
  priceChangePeriods,
  isActive,
  isOpenForSale,
  isSoldOut
}) => {
  const formatDateFromTimestamp = timestamp => {
    const timestampInMillis = timestamp * 1000;
    const formatString = 'DD.MM.YY';
    return format(new Date(timestampInMillis), formatString);
  };

  const periodPrices = priceChangePeriods
    .filter(period => isBefore(new Date(), new Date(period.til * 1000)))
    .slice(0, 10)
    .map(period => {
      const priceForPeriod = `${formatDateFromTimestamp(period.fra)} - ${formatDateFromTimestamp(
        period.til
      )}: ${period.pris} kr`;
      return (
        <div key={period.fra} className={classes.periodPrice}>
          {priceForPeriod}
        </div>
      );
    });

  const priceLabel = `${price} kr ${periodPrices.length > 0 ? '*' : ''}`;

  const dateLabel =
    fromDate && toDate
      ? `${formatDateFromTimestamp(fromDate)} - ${formatDateFromTimestamp(toDate)}`
      : '';

  const disabledPeriodLabel = disabledPeriod
    ? `untatt ${formatDateFromTimestamp(disabledPeriod.fra)} - ${formatDateFromTimestamp(
        disabledPeriod.til
      )}`
    : '';

  const warningLabel = !isActive ? '- UTLØPT' 
    : !isOpenForSale ? '- SALG IKKE ÅPNET' 
    : isSoldOut ? '- UTSOLGT' 
    : '';

  return (
    <div className={classes.root}>
      <Tooltip classes={{ tooltip: classes.tooltip }} title={periodPrices}>
        <span className={classes.leftElement}>
          {priceLabel}
          <span className={classes.expired}>{warningLabel}</span>
        </span>
      </Tooltip>
      <span className={classes.rightElement}>{dateLabel}</span>
      <br />
      <span className={classes.rightElement}>{disabledPeriodLabel}</span>
    </div>
  );
};

PeriodInformation.propTypes = {
  classes: PropTypes.object.isRequired,
  price: PropTypes.number.isRequired,
  fromDate: PropTypes.number,
  toDate: PropTypes.number,
  disabledPeriod: PropTypes.object,
  priceChangePeriods: PropTypes.array.isRequired,
  isActive: PropTypes.bool.isRequired,
  isOpenForSale: PropTypes.bool.isRequired,
  isSoldOut: PropTypes.bool.isRequired
};

PeriodInformation.defaultProps = {
  fromDate: 0,
  toDate: 0,
  disabledPeriod: null,
};

export default withStyles(styles)(PeriodInformation);
