import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Navbar from 'components/Common/Navbar';
import Price from 'components/Purchase/Price';
import ButtonGroup from 'components/Common/ButtonGroup';
import PurchaseNavigation from 'components/Purchase/PurchaseNavigation';
import { Typography } from '@material-ui/core';
import { STAGE_OFFER } from 'store/actions/order';
import { findTypeIcon } from 'utils/misc';
import crossIcon from 'static/icons/cross.svg';

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: `calc(100% - ${theme.custom.menubarHeight}px)`,
    overflow: 'hidden',
    position: 'absolute',
    display: 'flex',
  },
  sidebar: {
    border: 'none',
    backgroundColor: theme.colors.menuBackground,
    width: theme.custom.sidebarWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    minWidth: 0,
  },
  header: {
    marginTop: theme.margins.contentMarginTop,
    marginBottom: 50,
    fontSize: 18,
    fontFamily: theme.fonts.barlow,
    color: theme.colors.fontColor1,
  },
  icon: {
    marginRight: 10,
  },
  bottomGroup: {
    position: 'absolute',
    top: 'calc(100% - 80px)',
    width: '85%',
    marginTop: 0,
  },
  navbar: {
    width: theme.custom.sidebarWidth,
    left: 0,
  },
});

const PurchaseSidebar = ({
  classes,
  order,
  sale,
  onSaleClick,
  onInfoClick,
  onCancelClick,
  onLinkClick,
}) => (
  <div className={`${classes.root} menu`}>
    <Drawer
      variant="permanent"
      classes={{
        paper: classes.sidebar,
      }}>
      <Navbar title="AVBRYT" icon={crossIcon} onClick={onCancelClick} className={classes.navbar} />
      <div className={classes.content}>
        <Typography align="center" color="inherit" className={classes.header}>
          <img className={classes.icon} src={findTypeIcon(order.type.name, true)} alt="logo" />
          {order.type.title}
        </Typography>
        {order.stage !== STAGE_OFFER && (
          <PurchaseNavigation onLinkClick={onLinkClick} order={order} />
        )}
        <div className={classes.bottomGroup}>
          {order.stage === STAGE_OFFER ? (
            <ButtonGroup
              theme="light"
              buttons={[
                {
                  label: 'SELG',
                  onClick: onSaleClick,
                  active: sale,
                },
                {
                  label: 'INFO',
                  onClick: onInfoClick,
                  active: !sale,
                },
              ]}
            />
          ) : (
            <Price price={order.price} />
          )}
        </div>
      </div>
    </Drawer>
  </div>
);

PurchaseSidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  sale: PropTypes.bool.isRequired,
  onSaleClick: PropTypes.func.isRequired,
  onInfoClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  onLinkClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(PurchaseSidebar);
