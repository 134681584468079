import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import PeriodCalendar from 'components/OfferPeriod/PeriodCalendar';
import NextButton from 'components/Common/NextButton';
import arrow from 'static/icons/arrow.svg';

const styles = theme => ({
  wrapper: {
    marginTop: theme.margins.contentMarginTop,
  },
});

const PeriodCalendarPage = ({
  classes,
  period,
  periodAvailabilities,
  onDaySelect,
  onRangeSelect,
  onNextClick,
  onMonthChange,
  selectedDays,
  time,
  onTimeChange,
  rangeLength,
  isDateSelected,
  isDisabled,
  reservingPurchase,
}) => (
  <div className={classes.wrapper}>
    <PeriodCalendar
      rangeLength={rangeLength}
      selectedDays={selectedDays}
      time={time}
      onTimeChange={onTimeChange}
      onDaySelect={onDaySelect}
      onRangeSelect={onRangeSelect}
      onMonthChange={onMonthChange}
      periodAvailabilities={periodAvailabilities}
      period={period}
      isDisabled={isDisabled}
    />
    <NextButton
      disabled={!isDateSelected}
      text="GÅ VIDERE"
      loading={reservingPurchase}
      icon={arrow}
      onClick={onNextClick}
    />
  </div>
);

PeriodCalendarPage.propTypes = {
  classes: PropTypes.object.isRequired,
  period: PropTypes.object.isRequired,
  periodAvailabilities: PropTypes.array.isRequired,
  rangeLength: PropTypes.number.isRequired,
  onDaySelect: PropTypes.func.isRequired,
  onRangeSelect: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
  onMonthChange: PropTypes.func.isRequired,
  selectedDays: PropTypes.array.isRequired,
  time: PropTypes.string.isRequired,
  onTimeChange: PropTypes.func.isRequired,
  isDateSelected: PropTypes.bool.isRequired,
  isDisabled: PropTypes.func.isRequired,
  reservingPurchase: PropTypes.bool.isRequired,
};

export default withStyles(styles)(PeriodCalendarPage);
