import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import OfferPeriod from 'components/OfferPeriod';
import { fetchPeriods, FETCH_PERIODS_SUCCESS } from 'store/actions/offers';
import {
  STAGE_TIME,
  RESERVE_PURCHASE_SUCCESS,
  setPurchaseStage,
  setPeriodCard,
  reservePurchase,
  addToPrice,
  setPrice,
} from 'store/actions/order';

class OfferPeriodContainer extends React.Component {
  constructor(props) {
    super(props);
    this.onLoependeCardClick = this.onLoependeCardClick.bind(this);
    this.onFastperiodeCardClick = this.onFastperiodeCardClick.bind(this);
  }

  componentDidMount() {
    this.props.setPurchaseStage(STAGE_TIME);
    this.props.setPrice(0);
    this.props.fetchPeriods(this.props.match.params.id);
  }

  onLoependeCardClick(period) {
    const { offer } = this.props;
    this.props.setPeriodCard(period);
    this.props.history.push({
      pathname: `/purchase/calendar/${offer.id}`,
      state: { transition: 'next' },
    });
  }

  onFastperiodeCardClick(period) {
    const { offer } = this.props;
    this.props.setPeriodCard(period);
    this.props.addToPrice(period.priser.standardpris);
    this.props.reservePurchase(offer.id, period.id).then(() => {
      const nextStep = period.valg && period.valg.length > 0 ? 'additional' : 'personalia';
      this.props.history.push({
        pathname: `/purchase/${nextStep}/${period.id}`,
        state: { transition: 'next' },
      });
    });
  }

  render() {
    const { periods, loading, reservingPurchase } = this.props;
    return (
      <OfferPeriod
        periods={periods}
        loading={loading}
        reservingPurchase={reservingPurchase}
        onLoependeCardClick={this.onLoependeCardClick}
        onFastperiodeCardClick={this.onFastperiodeCardClick}
      />
    );
  }
}

OfferPeriodContainer.propTypes = {
  history: PropTypes.object.isRequired,
  fetchPeriods: PropTypes.func.isRequired,
  setPurchaseStage: PropTypes.func.isRequired,
  setPeriodCard: PropTypes.func.isRequired,
  reservePurchase: PropTypes.func.isRequired,
  addToPrice: PropTypes.func.isRequired,
  setPrice: PropTypes.func.isRequired,
  periods: PropTypes.array.isRequired,
  offer: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  reservingPurchase: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
};

OfferPeriodContainer.defaultProps = {
  offer: null,
};

const mapStateToProps = state => ({
  periods: state.get.offerPeriods,
  offer: state.get.order.offer,
  periodCard: state.get.periodCard,
  loading: state.get.actionsInProgress.includes(FETCH_PERIODS_SUCCESS),
  reservingPurchase: state.get.actionsInProgress.includes(RESERVE_PURCHASE_SUCCESS),
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      setPurchaseStage,
      fetchPeriods,
      setPeriodCard,
      reservePurchase,
      addToPrice,
      setPrice,
    }
  )(OfferPeriodContainer)
);
