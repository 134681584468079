import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchCustomerByPhone } from 'store/actions/order';
import { EDIT_SALE_SUCCESS, editSale } from 'store/actions/sales';
import SaleEditButtons from 'components/SaleDetails/SaleEditButtons';
import SaleEditPersonaliaForm from 'components/SaleDetails/SaleEditPersonaliaForm';
import {
  validationRunner,
  runValidations,
  required,
  validEmail,
} from 'utils/validation/validation';

class SaleEditContainer extends React.Component {
  constructor(props) {
    super(props);
    const { betaler, saleId, saleDetails } = this.props;

    this.fieldValidations = [
      validationRunner('countryCode', 'Landskode', required),
      validationRunner('mobileNumber', 'Mobilnummer', required),
      validationRunner('zipCode', 'Postnummer', required),
      validationRunner('country', 'Land', required),
      validationRunner('name', 'Navn', required),
      validationRunner('email', 'E-post', required),
      validationRunner('email', 'E-post', validEmail),
    ];

    if (this.hasMemberNumber(saleDetails)) {
      this.fieldValidations.push(validationRunner('membersNumber', 'Medlemsnummer', required));
    }

    this.state = {
      customer: {
        mobileNumber: betaler.mobil.nummer,
        countryCode: betaler.mobil.landskode,
        name: betaler.navn,
        country: betaler.land,
        zipCode: betaler.postnummer,
        email: betaler.epost,
        membersNumber: betaler.medlemsnummer,
      },
      norwegian: true,
      validationErrors: {},
      displayErrors: false,
      customerNotFound: false,
      id: saleId,
    };

    this.state.validationErrors = runValidations(this.state.customer, this.fieldValidations);
    this.onChange = this.onChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onDropdownChange = this.onDropdownChange.bind(this);
    this.onNorwegianSelect = this.onNorwegianSelect.bind(this);
    this.onEnglishSelect = this.onEnglishSelect.bind(this);
    this.fetchPersonaliaFromNumber = this.fetchPersonaliaFromNumber.bind(this);
  }

  onChange(event) {
    const { customer } = this.state;
    customer[event.target.name] = event.target.value;
    this.setState({ customer, validationErrors: runValidations(customer, this.fieldValidations) });
  }

  onSave() {
    const customer = this.createCustomer();
    this.props.editSale(this.state.id, customer).then(() => {
      this.props.afterEdit();
      this.props.onCloseClick();
    });
  }

  onDropdownChange(event) {
    const { customer } = this.state;
    this.setState({ customer: { ...customer, country: event ? event.value : 'no' } });
  }

  onNorwegianSelect() {
    this.setState({ norwegian: true });
  }

  onEnglishSelect() {
    this.setState({ norwegian: false });
  }

  fetchPersonaliaFromNumber() {
    const { mobileNumber } = this.state.customer;
    this.props.fetchCustomerByPhone(this.removeWhitespaces(mobileNumber)).then(result => {
      if (result.customer) {
        const { customer } = this.state;
        customer.name = result.customer.navn;
        customer.country = result.customer.land;
        customer.zipCode = result.customer.postnummer;
        customer.email = result.customer.epost;
        this.setState({
          customer,
          validationErrors: runValidations(customer, this.fieldValidations),
        });
      } else {
        this.setState({ customerNotFound: true });
      }
    });
  }

  isValid() {
    return Object.keys(this.state.validationErrors).length === 0;
  }

  createCustomer() {
    const { customer } = this.state;
    return {
      mobil: {
        landskode: this.removeWhitespaces(customer.countryCode),
        nummer: this.removeWhitespaces(customer.mobileNumber),
      },
      navn: customer.name,
      land: customer.country,
      postnummer: this.removeWhitespaces(customer.zipCode),
      epost: customer.email,
      medlemsnummer: customer.membersNumber,
    };
  }

  removeWhitespaces(value) {
    return value.replace(/\s/g, '');
  }

  hasMemberNumber(details) {
    return details.andreValg != null && details.andreValg.some(a => a.id === 'MEDLEM');
  }

  render() {
    const { customer, validationErrors, displayErrors, customerNotFound, norwegian } = this.state;
    const { saleDetails } = this.props;

    return (
      <div>
        <SaleEditPersonaliaForm
          customer={customer}
          onChange={this.onChange}
          onDropdownChange={this.onDropdownChange}
          validationErrors={validationErrors}
          displayErrors={displayErrors}
          showMemberInput={this.hasMemberNumber(saleDetails)}
          showReceiptChoice
          onNorwegianSelect={this.onNorwegianSelect} // TODO
          onEnglishSelect={this.onEnglishSelect} // TODO
          norwegian={norwegian}
          fetchPersonaliaFromNumber={this.fetchPersonaliaFromNumber}
          customerNotFound={customerNotFound}
        />
        <SaleEditButtons
          onCloseClick={this.props.onCloseClick}
          isValid={this.isValid()}
          onSaveClick={this.onSave}
        />
      </div>
    );
  }
}

SaleEditContainer.propTypes = {
  betaler: PropTypes.object.isRequired,
  saleId: PropTypes.string.isRequired,
  saleDetails: PropTypes.object.isRequired,
  fetchCustomerByPhone: PropTypes.func.isRequired,
  editSale: PropTypes.func.isRequired,
  afterEdit: PropTypes.func.isRequired,
  onCloseClick: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  updatingCustomer: state.get.actionsInProgress.includes(EDIT_SALE_SUCCESS),
  saleDetails: state.get.saleDetails,
});

export default connect(
  mapStateToProps,
  { fetchCustomerByPhone, editSale }
)(SaleEditContainer);
