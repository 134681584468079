import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { STAGE_TIME, STAGE_ADDITIONAL, STAGE_PERSONALIA, STAGE_SUMMARY } from 'store/actions/order';

const styles = theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  active: {
    borderBottom: '1px solid rgba(70,130,90,1)',
  },
  list: {
    listStyleType: 'none',
    fontFamily: theme.fonts.barlowSemiCondensed,
    fontSize: 18,
    paddingLeft: 0,
    color: theme.colors.fontColor1,
  },
  listItem: {
    margin: 30,
    cursor: 'pointer',
  },
  listItemDisabled: {
    cursor: 'not-allowed',
    opacity: 0.5,
  },
});

const PurchaseNavigation = ({ classes, order, onLinkClick }) => (
  <div className={classes.root}>
    <ul className={classes.list}>
      <li
        onClick={
          order.offer
            ? () => {
                onLinkClick({
                  pathname: `/purchase/period/${order.offer.id}`,
                  state: { transition: 'prev' },
                });
              }
            : null
        }
        className={`
          ${classes.listItem}
          ${!order.offer && classes.listItemDisabled}
          ${order.stage === STAGE_TIME && classes.active}
          `}>
        Tidsperiode
      </li>
      <li
        onClick={
          order.purchase && order.stage > STAGE_ADDITIONAL
            ? () => {
                onLinkClick({
                  pathname: `/purchase/additional/${order.period.id}`,
                  state: { transition: 'prev' },
                });
              }
            : null
        }
        className={`
          ${classes.listItem}
          ${!(order.purchase && order.stage >= STAGE_ADDITIONAL) && classes.listItemDisabled}
          ${order.stage === STAGE_ADDITIONAL && classes.active}
          `}>
        Andre valg
      </li>
      <li
        onClick={
          order.purchase && order.stage > STAGE_PERSONALIA
            ? () => {
                onLinkClick(`/purchase/personalia/${order.purchase.id}`);
              }
            : null
        }
        className={`
          ${classes.listItem}
          ${!(order.purchase && order.stage >= STAGE_PERSONALIA) && classes.listItemDisabled}
          ${order.stage === STAGE_PERSONALIA && classes.active}
          `}>
        Personalia
      </li>
      <li
        onClick={
          order.purchase && order.stage > STAGE_SUMMARY
            ? () => {
                onLinkClick(`/purchase/summary/${order.purchase.id}`);
              }
            : null
        }
        className={`
          ${classes.listItem}
          ${!(order.purchase && order.stage >= STAGE_SUMMARY) && classes.listItemDisabled}
          ${order.stage === STAGE_SUMMARY && classes.active}
          `}>
        Oppsummering
      </li>
    </ul>
  </div>
);

PurchaseNavigation.propTypes = {
  classes: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  onLinkClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(PurchaseNavigation);
