import React from 'react';
import PropTypes from 'prop-types';
import SalesHistoryContainer from 'containers/SalesHistoryContainer';
import SaleDetailsContainer from 'containers/SaleDetailsContainer';
import { Route } from 'react-router';

const SalesRoutes = ({ match }) => (
  <div className="fade-in slide-up fade-out slide-down">
    <Route exact path={`${match.url}/`} render={() => <SalesHistoryContainer />} />
    <Route path={`${match.url}/:id`} render={() => <SaleDetailsContainer />} />
  </div>
);

SalesRoutes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default SalesRoutes;
