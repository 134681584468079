import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const styles = theme => ({
  root: {
    flexGrow: 1,
    minHeight: 0,
  },
  tab: {
    fontWeight: 500,
    fontSize: 18,
    fontFamily: theme.fonts.barlowSemiCondensed,
    minWidth: 0,
    minHeight: 40,
    opacity: 1,
  },
  activeLight: {
    backgroundColor: theme.colors.fontColor1,
    color: theme.colors.fontColor2,
  },
  borderLight: {
    border: `1px solid ${theme.colors.fontColor1}`,
  },
  activeDark: {
    backgroundColor: theme.colors.fontColor2,
    color: theme.colors.fontColor1,
  },
  borderDark: {
    border: `1px solid ${theme.colors.fontColor2}`,
  },
  indicator: {
    backgroundColor: 'inherit',
  },
});

const ButtonGroup = ({ classes, className, bordered, buttons, theme }) => {
  let groupThemeActive = classes.activeLight;
  let groupBorder = classes.borderLight;
  switch (theme) {
    case 'dark':
      groupThemeActive = classes.activeDark;
      groupBorder = classes.borderDark;
      break;
    default:
      break;
  }
  const group = buttons.map(button => (
    <Tab
      key={button.label}
      style={{ width: `calc(100% / ${buttons.length})` }}
      label={button.label}
      onClick={button.onClick}
      className={`${classes.tab} ${button.active ? groupThemeActive : ''}`}
    />
  ));
  return (
    <div className={`${classes.root} ${className} ${bordered && groupBorder}`}>
      <Tabs
        centered
        className={classes.root}
        classes={{ indicator: classes.indicator }}
        value={0}
        onChange={() => {}}>
        {group}
      </Tabs>
    </div>
  );
};

ButtonGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  theme: PropTypes.oneOf(['default', 'light', 'dark']),
  bordered: PropTypes.bool,
  buttons: PropTypes.array.isRequired,
};

ButtonGroup.defaultProps = {
  theme: 'default',
  className: null,
  bordered: false,
};

export default withStyles(styles)(ButtonGroup);
