import {
  SET_TYPE_SUCCESS,
  SET_PURCHASE_STAGE_SUCCESS,
  SET_CUSTOMER,
  SET_HUNTER,
  SET_OFFER,
  SET_PERIOD,
  RESET_PURCHASE_SAME_CARD_SUCCESS,
  SET_PURCHASE_SUCCESS,
  UPDATE_STATUS_SUCCESS,
  FETCH_CUSTOMER_BY_PHONE_SUCCESS,
  RESET_PURCHASE_SUCCESS,
  ADD_TO_PRICE,
  REMOVE_FROM_PRICE,
  SET_PRICE,
} from 'store/actions/order';

const initialState = {
  type: null,
  stage: null,
  customer: null,
  offer: null,
  period: null,
  purchase: null,
  price: 0,
  status: null,
  hunter: null,
};

export default function orderReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_TYPE_SUCCESS:
      return { ...state, type: action.cardType };
    case SET_PURCHASE_STAGE_SUCCESS:
      return { ...state, stage: action.stage };
    case SET_CUSTOMER:
      return { ...state, customer: action.customer };
    case SET_HUNTER:
      return { ...state, hunter: action.hunter };
    case SET_OFFER:
      return { ...state, offer: action.offer };
    case SET_PERIOD:
      return { ...state, period: action.period };
    case SET_PURCHASE_SUCCESS:
      return { ...state, purchase: action.purchase, price: action.purchase.pris, status: action.status, orderNumber: action.ordrenummer };
    case UPDATE_STATUS_SUCCESS:
      return { ...state, status: action.status };
    case FETCH_CUSTOMER_BY_PHONE_SUCCESS:
      return { ...state, customer: action.customer };
    case RESET_PURCHASE_SUCCESS:
      return {
        ...state,
        stage: null,
        customer: null,
        period: null,
        purchase: null,
        price: 0,
        status: null,
        hunter: null,
      };
    case RESET_PURCHASE_SAME_CARD_SUCCESS:
      return {
        ...state,
        period: null,
        customer: null,
        price: 0,
        status: null,
        hunter: null,
      };
    case SET_PRICE: {
      return { ...state, price: action.price };
    }
    case ADD_TO_PRICE: {
      const newPrice = state.price + action.price;
      return { ...state, price: newPrice };
    }
    case REMOVE_FROM_PRICE: {
      const newPrice = state.price - action.price;
      return { ...state, price: newPrice };
    }
    default:
      return state;
  }
}
