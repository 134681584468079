import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Common/Modal';
import Button from 'components/Common/Button';
import { Grid, withStyles } from '@material-ui/core';

const styles = theme => ({
  modalContent: {
    margin: 50,
  },
  textArea: {
    marginBottom: theme.margins.large,
    fontFamily: theme.fonts.barlow,
    fontSize: '14pt',
  },
});

const SaleDeletionModal = ({ show, onCloseClick, onDeleteClick, classes }) => (
  <Modal onCloseClick={onCloseClick} sidebar={false} show={show}>
    <div className={classes.modalContent}>
      <p className={classes.textArea}>Er du sikker på at du vil slette kortet?</p>

      <Grid container spacing={16} alignContent="center">
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <Button
            fullWidth
            className={classes.button}
            theme="tetriary"
            onClick={onDeleteClick}
            label="SLETT"
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <Button
            fullWidth
            className={classes.button}
            theme="primary"
            onClick={onCloseClick}
            label="AVBRYT"
          />
        </Grid>
      </Grid>
    </div>
  </Modal>
);

SaleDeletionModal.propTypes = {
  classes: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(SaleDeletionModal);
