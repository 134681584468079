import { getRequest, getCsrfHeader } from 'store/actions/request';

export const VALIDATE_AUTH_REQUEST = 'VALIDATE_AUTH_REQUEST';
export const VALIDATE_AUTH_SUCCESS = 'VALIDATE_AUTH_SUCCESS';
export const VALIDATE_AUTH_FAILURE = 'VALIDATE_AUTH_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

function loginRequest() {
  return {
    type: VALIDATE_AUTH_REQUEST,
  };
}

function loginSuccess(data) {
  return {
    type: VALIDATE_AUTH_SUCCESS,
    user: data,
  };
}

function loginFailure(data) {
  return {
    type: VALIDATE_AUTH_FAILURE,
    errors: data.errors,
  };
}

function validateCookieSuccess(data) {
  return {
    type: VALIDATE_AUTH_SUCCESS,
    user: data,
  };
}

function validateCookieFailure() {
  return {
    type: VALIDATE_AUTH_FAILURE,
  };
}

function logoutRequest() {
  return {
    type: LOGOUT_REQUEST,
  };
}

function logoutSuccess() {
  return {
    type: LOGOUT_SUCCESS,
  };
}

function logoutFailure(data) {
  return {
    type: LOGOUT_FAILURE,
    errors: data.errors,
  };
}

export function login(username, password) {
  return async dispatch => {
    dispatch(loginRequest(username, password));
    const csrfHeader = await getCsrfHeader();
    return fetch('/api/v1/authorization/login', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...csrfHeader,
      },
      credentials: 'same-origin',
      body: JSON.stringify({ username, password }),
    }).then(response =>
      response
        .json()
        .then(
          json =>
            response.status === 200 ? dispatch(loginSuccess(json)) : dispatch(loginFailure(json))
        )
    );
  };
}

export function validateCookie() {
  return async dispatch => {
    const { status, json } = await getRequest('/api/v1/authorization');
    if (status === 401) {
      return dispatch(validateCookieFailure());
    } else {
      return dispatch(validateCookieSuccess(json));
    }
  };
}

export function logout() {
  return dispatch => {
    dispatch(logoutRequest());
    return fetch('/api/v1/authorization/logout', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'same-origin',
    }).then(
      response =>
        response.status === 204
          ? dispatch(logoutSuccess())
          : response.json().then(json => dispatch(logoutFailure(json)))
    );
  };
}
