export const REQUEST_BEGIN = 'REQUEST_BEGIN';
export const REQUEST_END = 'REQUEST_END';
export const REQUEST_ERROR = 'REQUEST_ERROR';

export const requestBegin = operation => ({
  type: REQUEST_BEGIN,
  operation,
});

export const requestEnd = operation => ({
  type: REQUEST_END,
  operation,
});

export const requestError = error => ({
  type: REQUEST_ERROR,
  error,
});
