import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Grid, Typography, Checkbox } from '@material-ui/core';
import TextField from 'components/Common/TextField';
import Button from 'components/Common/Button';
import NextButton from 'components/Common/NextButton';
import Status from 'components/Common/Status';
import arrow from 'static/icons/arrow.svg';

const styles = theme => ({
  content: {
    height: 'calc(100% - 80px)',
    width: '100%',
    background: 'rgba(253,252,249,1)',
    padding: '80px 48px 64px 48px',
    marginTop: 70,
  },
  title: {
    marginBottom: theme.margins.medium,
  },
  status: {
    height: 40,
  },
  textStyle: {
    color: theme.colors.fontColor1,
    fontFamily: theme.fonts.barlow,
  },
  displayFlex: {
    display: 'flex',
  },
  horizontalAlign: {
    justifyContent: 'center',
  },
  verticalAlign: {
    alignSelf: 'center',
  },
  divider: {
    border: 'none',
    borderTop: theme.divider.primaryDashed,
    marginTop: theme.margins.medium,
    marginBottom: theme.margins.medium,
  },
});

const HunterValidation = ({
  classes,
  hunter,
  huntingFeeStatus,
  onChange,
  onCheckboxChange,
  isValid,
  validationErrors,
  displayErrors,
  checkPaidStatus,
  onNextClick,
  loading,
  updatingHunter,
}) => {
  const findStatus = () => {
    switch (huntingFeeStatus) {
      case 'OK':
        return {
          message: 'Personen har betalt jegeravgift',
          variant: 'success',
          iconType: 'check',
        };
      case 'FEIL_FØDSELSDATO':
      case 'IKKE_REGISTRERT':
        return {
          message: 'Jeger er ikke registrert i Jegerregisteret. Sjekk jegernummer og fødselsdato.',
          variant: 'error',
          iconType: 'close',
        };
      case 'IKKE_BETALT':
        return {
          message:
            'Jegeravgift for inneværende jaktår er ikke betalt. Ved forevist jegeravgiftskort, benytt manuell godkjenning.',
          variant: 'error',
          iconType: 'close',
        };
      case 'UTENLANDSK_JEGER':
        return {
          message:
            'Jegeren er utenlandsk. Ved tillatt jakt for utenlandsk jeger og forevist jegeravgiftskort, benytt manuell godkjenning.',
          variant: 'error',
          iconType: 'close',
        };
      default:
        return null;
    }
  };
  const { hunterNumber, birthDate, manualApproval } = hunter;
  const status = findStatus();
  const onNextDisabled = !(manualApproval || huntingFeeStatus === 'OK');
  const checkButtonDisabled = !isValid;
  const checkboxDisabled = !huntingFeeStatus;
  return (
    <div className={classes.content}>
      <Grid container spacing={24}>
        <Grid item xs={12} md={12} lg={12}>
          <Typography variant="title" className={`${classes.title} ${classes.textStyle}`}>
            Sjekk jeger i jegerregisteret
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <TextField
            label="Jegernummer"
            type="number"
            value={hunterNumber}
            name="hunterNumber"
            onChange={onChange}
            errorText={validationErrors.hunterNumber}
            displayError={displayErrors}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <TextField
            label="Fødselsdato"
            type="number"
            placeholder="ddmmåå"
            value={birthDate}
            name="birthDate"
            onChange={onChange}
            errorText={validationErrors.birthDate}
            displayError={displayErrors}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          {status ? (
            <Status
              className={classes.status}
              message={status.message}
              variant={status.variant}
              iconType={status.iconType}
            />
          ) : (
            <Button
              label="SJEKK OM JEGERAVGIFT ER BETALT"
              theme="primary"
              fullWidth
              onClick={checkPaidStatus}
              disabled={checkButtonDisabled}
              loading={loading}
            />
          )}
        </Grid>
        <Grid item xs={4} md={5} lg={5}>
          <hr className={classes.divider} />
        </Grid>
        <Grid
          item
          xs={4}
          md={2}
          lg={2}
          className={`${classes.horizontalAlign} ${classes.displayFlex}`}>
          <span className={`${classes.textStyle} ${classes.verticalAlign}`}>ELLER</span>
        </Grid>
        <Grid item xs={4} md={5} lg={5}>
          <hr className={classes.divider} />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Typography variant="title" className={`${classes.textStyle}`}>
            Godkjenn manuelt
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Checkbox
            checked={manualApproval}
            name="manualApproval"
            onChange={onCheckboxChange}
            disabled={checkboxDisabled}
            color="default"
          />
          <span className={classes.textStyle}>
            Jeg bekrefter at jeg har sett kvittering for betalt jegeravgift
          </span>
        </Grid>
      </Grid>
      <NextButton
        disabled={onNextDisabled}
        loading={updatingHunter}
        text="Gå videre"
        icon={arrow}
        background="rgba(50,55,50,1)"
        onClick={onNextClick}
      />
    </div>
  );
};

HunterValidation.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  updatingHunter: PropTypes.bool.isRequired,
  hunter: PropTypes.object.isRequired,
  huntingFeeStatus: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
  checkPaidStatus: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  validationErrors: PropTypes.object.isRequired,
  displayErrors: PropTypes.bool.isRequired,
  onNextClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(HunterValidation);
