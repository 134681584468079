import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 2,
    marginTop: 100,
    marginLeft: 50,
    marginRight: 50,
  },
  header: {
    fontFamily: theme.fonts.barlowSemiCondensed,
    fontSize: 40,
  },
  text: {
    fontFamily: theme.fonts.barlow,
    fontSize: 18,
  },
});

const Unauthorized = ({ classes }) => (
  <div className={classes.root} elevation={1}>
    <Typography className={classes.header} variant="headline" component="h3">
      Uautorisert
    </Typography>
    <Typography className={classes.text} component="p">
      Du er ikke autorisert for å hente angitt ressurs.
    </Typography>
  </div>
);

Unauthorized.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Unauthorized);
