import {
  FETCH_OFFERS_SUCCESS,
  FETCH_OFFER_SUCCESS,
  FETCH_PERIODS_SUCCESS,
  FETCH_PERIOD_AVAILABILITIES_SUCCESS,
} from 'store/actions/offers';

const initialState = {
  offer: null,
  offers: [],
  periods: [],
  periodAvailabilities: [],
};

export default function offersReducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_OFFERS_SUCCESS:
      return { ...state, offers: action.offers };
    case FETCH_OFFER_SUCCESS:
      return { ...state, offer: action.offer };
    case FETCH_PERIODS_SUCCESS:
      return { ...state, periods: action.periods };
    case FETCH_PERIOD_AVAILABILITIES_SUCCESS:
      return { ...state, periodAvailabilities: action.availabilities };
    default:
      return state;
  }
}
