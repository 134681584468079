import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import ContactInfo from 'components/Home/ContactInfo';
import logo from 'static/icons/inatur_logo.svg';

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    position: 'absolute',
    display: 'flex',
    zIndex: 1,
  },
  sidebar: {
    position: 'relative',
    border: 'none',
    background: 'linear-gradient(to top, rgba(253,252,249,1), rgba(255,255,255,0.3))',
    width: theme.custom.homeSidebarWidth,
  },
  contactContainer: {
    padding: '200px 34px 200px 34px',
    position: 'relative',
    height: 630,
  },
  logoContainer: {
    paddingLeft: 34,
    paddingRight: 34,
  },
  logo: {
    height: 40,
    width: 110,
    position: 'absolute',
    bottom: 0,
  },

  toolbar: {
    backgroundColor: 'rgba(255,255,255,1)',
    position: 'absolute',
    width: '100%',
  },
});

function HomeSidebar(props) {
  const { classes, active } = props;
  return (
    <div className={classes.root}>
      <Drawer
        variant="permanent"
        classes={{
          paper: classes.sidebar,
        }}>
        <Toolbar className={classes.toolbar} />
        <div className={classes.contactContainer}>
          {active && <ContactInfo />}
          <img className={classes.logo} src={logo} alt="logo" />
        </div>
      </Drawer>
    </div>
  );
}

HomeSidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  active: PropTypes.bool.isRequired,
};

export default withStyles(styles)(HomeSidebar);
