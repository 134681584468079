import React from 'react';
import PropTypes from 'prop-types';
import { Typography, TextField, FormHelperText, withStyles } from '@material-ui/core';

const styles = theme => ({
  label: {
    fontFamily: theme.fonts.barlow,
    fontWeight: 500,
    color: theme.colors.inputLabel,
    marginBottom: theme.margins.small,
  },
  mobileContainer: {
    display: 'flex',
    border: '1pt solid rgba(70,130,90,1)',
    borderRadius: '20pt',
    padding: '0.2em',
    paddingLeft: '0.5em',
    fontFamily: theme.fonts.barlowSemiCondensed,
    marginRight: 8,
  },
  countryCodeInput: {
    width: '40px',
    borderRight: '1pt solid rgba(210,210,210,1)',
    marginRight: '10px',
  },
  mobileNumberInput: {
    flexGrow: 1,
  },
  searchButton: {
    color: 'rgba(70,130,90,1)',
    alignSelf: 'center',
    marginRight: theme.margins.small,
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  listStyling: {
    color: theme.colors.bulletPoint,
  },
  listText: {
    fontFamily: theme.fonts.barlowSemiCondensed,
    color: theme.colors.inputLabel,
  },
  errorText: {
    fontFamily: theme.fonts.barlow,
    fontSize: 12,
    color: 'rgba(189, 9, 9, 1)',
  },
});

const MobileInput = ({
  classes,
  countryCode,
  mobileNumber,
  customerNotFound,
  onChange,
  onClick,
  displayError,
  errorText,
}) => {
  const formatMobileNumber = number => {
    const res = number.match(/(\d{1,3})/g);
    return res ? res.join(' ') : '';
  };

  return (
    <div>
      <Typography variant="subheading" className={classes.label}>
        Mobilnummer
      </Typography>
      <div className={classes.mobileContainer}>
        <div>
          <TextField
            className={classes.countryCodeInput}
            InputProps={{ disableUnderline: true }}
            onChange={onChange}
            value={countryCode}
            name="countryCode"
          />
        </div>
        <div className={classes.mobileNumberInput}>
          <TextField
            fullWidth
            InputProps={{ disableUnderline: true }}
            onChange={onChange}
            value={formatMobileNumber(mobileNumber)}
            name="mobileNumber"
          />
        </div>
        <div onClick={onClick} className={classes.searchButton}>
          Søk
        </div>
      </div>
      {customerNotFound && (
        <ul>
          <li className={classes.listStyling}>
            <span className={classes.listText}>
              Systemet fant ikke personen knyttet til dette nummeret.
            </span>
          </li>
        </ul>
      )}
      {displayError && <FormHelperText className={classes.errorText}>{errorText}</FormHelperText>}
    </div>
  );
};

MobileInput.propTypes = {
  classes: PropTypes.object.isRequired,
  countryCode: PropTypes.string.isRequired,
  mobileNumber: PropTypes.string.isRequired,
  customerNotFound: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  displayError: PropTypes.bool.isRequired,
  errorText: PropTypes.string,
};

MobileInput.defaultProps = {
  errorText: null,
};

export default withStyles(styles)(MobileInput);
