import {
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  SET_EMAIL_SENT,
} from 'store/actions/user';

const initialState = {
  emailSent: false,
  error: { message: '' },
};
// eslint-disable-next-line default-param-last
export default function user(state = initialState, action) {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST:
      return state;

    case RESET_PASSWORD_SUCCESS:
      return { ...state, emailSent: true };

    case RESET_PASSWORD_FAILURE:
      return { ...state, error: action.data.error };

    case SET_EMAIL_SENT:
      return { ...state, emailSent: action.value };

    default:
      return state;
  }
}
