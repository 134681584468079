import { TOGGLE_MENU_ACIVE, SET_ACTIVE_ITEM } from 'store/actions/menu';

const initialState = {
  active: false,
  activeItem: 0,
};

export default function menuReducer(state = initialState, action = {}) {
  switch (action.type) {
    case TOGGLE_MENU_ACIVE:
      return Object.assign({}, state, { active: !action.active });
    case SET_ACTIVE_ITEM:
      return Object.assign({}, state, { activeItem: action.value });
    default:
      return state;
  }
}
