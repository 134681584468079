import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grow from '@material-ui/core/Grow';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
  button: {
    borderRadius: 0,
    minHeight: 40,
    fontSize: 16,
    fontWeight: 500,
    fontFamily: theme.fonts.barlowSemiCondensed,
  },
  disabled: {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
  icon: {
    position: 'absolute',
    right: 40,
  },
  buttonDefault: {
    background: 'inherit',
  },
  primary: {
    color: theme.colors.fontColor2,
    backgroundColor: theme.colors.buttonPrimary,
    '&:hover': {
      backgroundColor: theme.colors.buttonPrimary,
    },
  },
  secondary: {
    color: theme.colors.fontColor2,
    backgroundColor: theme.colors.buttonSecondary,
    '&:hover': {
      backgroundColor: theme.colors.buttonSecondary,
    },
  },
  primaryGradient: {
    color: theme.colors.fontColor2,
    background: theme.colors.buttonPrimaryGradient,
    '&:hover': {
      backgroundColor: theme.colors.buttonPrimaryGradient,
    },
  },
  tetriary: {
    color: theme.colors.fontColor1,
    background: 'inherit',
    padding: 0,
    borderBottom: `1px solid ${theme.colors.fontColor1}`,
  },
  success: {
    color: theme.colors.fontColor2,
    background: theme.colors.buttonSuccess,
    '&:hover': {
      backgroundColor: theme.colors.buttonSuccess,
    },
  },
  danger: {
    color: theme.colors.fontColor2,
    background: theme.colors.buttonDanger,
    '&:hover': {
      backgroundColor: theme.colors.buttonDanger,
    },
  },
});

const CustomButton = ({
  classes,
  className,
  type,
  theme,
  onClick,
  label,
  icon,
  style,
  fullWidth,
  disabled,
  loading,
  loadingLabel,
}) => {
  let buttonTheme = classes.default;
  switch (theme) {
    case 'primary':
      buttonTheme = classes.primary;
      break;
    case 'secondary':
      buttonTheme = classes.secondary;
      break;
    case 'primarygradient':
      buttonTheme = classes.primaryGradient;
      break;
    case 'tetriary':
      buttonTheme = classes.tetriary;
      break;
    case 'success':
      buttonTheme = classes.success;
      break;
    case 'danger':
      buttonTheme = classes.danger;
      break;
    default:
      break;
  }
  return (
    <Button
      fullWidth={fullWidth}
      type={type}
      style={style}
      onClick={!disabled ? onClick : () => {}}
      className={`${classes.button} ${disabled && classes.disabled} ${buttonTheme} ${className}`}>
      {loadingLabel && loading ? (
        <Grow timeout={500} in={loading}>
          <span>{loadingLabel}</span>
        </Grow>
      ) : (
        <span>{label}</span>
      )}
      {icon && !loading && <img className={classes.icon} src={icon} alt="" />}
      {loading && (
        <Grow timeout={500} in={loading}>
          <CircularProgress className={`${buttonTheme} ${classes.icon}`} size={20} />
        </Grow>
      )}
    </Button>
  );
};

CustomButton.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.oneOf([
    'default',
    'primary',
    'secondary',
    'primarygradient',
    'tetriary',
    'success',
    'danger',
  ]),
  type: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  icon: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  loadingLabel: PropTypes.string,
};

CustomButton.defaultProps = {
  className: null,
  fullWidth: false,
  theme: 'default',
  type: 'button',
  icon: null,
  style: null,
  disabled: false,
  loading: false,
  loadingLabel: null,
};

export default withStyles(styles)(CustomButton);
