import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import infoIcon from 'static/icons/info.svg';

const styles = theme => ({
  cardBack: {
    backgroundColor: theme.colors.backgroundColor1,
    borderWidth: '15px 15px 0px 15px',
    borderStyle: 'solid',
    borderImage: `${theme.gradients.dark} 2`,
    transform: 'rotateY(180deg)',
  },
  content: {
    padding: 5,
    height: 'inherit',
  },
  title: {
    fontFamily: theme.fonts.vollkorn,
    fontSize: 16,
    fontWeight: 500,
    display: 'block',
    color: 'rgba(50,55,50,1)',
  },
  area: {
    fontFamily: theme.fonts.barlow,
    fontSize: 14,
    fontWeight: 300,
    color: 'rgba(50,55,50,1)',
  },
  titleContainer: {
    width: '80%',
    float: 'left',
  },
  info: {
    margin: 5,
    height: 30,
    width: 30,
  },
});

const OfferCardBack = ({ classes, offer, sizeClass, onInfoOpen }) => (
  <Paper onClick={onInfoOpen} className={`card ${sizeClass} ${classes.cardBack}`} elevation={1}>
    <div className={classes.content}>
      <div className={classes.titleContainer}>
        <span className={`${classes.title} ${classes.colorBack}`}>{offer.title.toUpperCase()}</span>
        <span className={`${classes.area} ${classes.colorBack}`}>{offer.tilbyderName}</span>
      </div>
      <div>
        <img className={classes.info} src={infoIcon} alt="logo" />
      </div>
    </div>
  </Paper>
);

OfferCardBack.propTypes = {
  classes: PropTypes.object.isRequired,
  offer: PropTypes.object.isRequired,
  sizeClass: PropTypes.string.isRequired,
  onInfoOpen: PropTypes.func.isRequired,
};

export default withStyles(styles)(OfferCardBack);
