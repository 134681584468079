import * as ErrorMessages from 'utils/validation/errorMessages';
import { isAfter, subYears } from 'date-fns';

export const required = value => {
  if (value && (value.length > 0 || value > 0)) {
    return null;
  } else {
    return ErrorMessages.isRequired;
  }
};

export const requiredMultiList = array => {
  if (array.some(el => el.active)) {
    return null;
  } else {
    return ErrorMessages.isRequired;
  }
};

export const minLength = length => text =>
  text.length >= length ? null : ErrorMessages.minLength(length);

export const validDateOfBirth = date => {
  const regex = /^(0[1-9]|[1-2][0-9]|31(?!(?:0[2469]|11))|30(?!02))(0[1-9]|1[0-2])([0-9]\d{1})$/;
  if (regex.test(date)) {
    return null;
  } else {
    return ErrorMessages.validDateOfBirth;
  }
};

export const validEmail = email => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (regex.test(email)) {
    return null;
  } else {
    return ErrorMessages.notValidEmail;
  }
};

export const validReportTimespan = timespan => {
  if (timespan.fromDate === null || timespan.fromDate === undefined || timespan.toDate === null || timespan.toDate === undefined){
    return null;
  }
  const fromDate = new Date(timespan.fromDate);
  const toDate = new Date(timespan.toDate);
  if (!isAfter(subYears(toDate, 1), fromDate)){
    return null;
  }
  return ErrorMessages.validReportTimespan;
}

export const validationRunner = (field, name, ...validations) => state => {
  const errors = validations.map(val => {
    const errorMessageFunc = val(state[field], state);
    const isRequired = validations.includes(required);
    if (errorMessageFunc && (isRequired || state[field])) {
      return { [field]: errorMessageFunc(name) };
    }
    return null;
  });
  return errors.find(error => error !== null);
};

export const runValidations = (state, runners) =>
  runners.reduce((prev, runner) => Object.assign(prev, runner(state)), {});
