import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import SalesRow from 'components/SalesHistory/SalesRow';
import Button from 'components/Common/Button';
import { groupBy } from 'utils/array';
import { format } from 'date-fns';
import nbLocale from 'date-fns/locale/nb';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
    width: '100%',
  },
  salesGroup: {
    marginBottom: 30,
  },
  listHeader: {
    fontFamily: theme.fonts.barlow,
    textTransform: 'capitalize',
    borderBottom: theme.divider.primaryDashed,
    padding: '20px 0',
  },
});

const SalesTable = ({ classes, sales, salesRemaining, onSaleClick, onMoreClick }) => {
  function displayTime(t) {
    return format(new Date(t), 'dddd DD/MM', { locale: nbLocale });
  }
  function sortTime(t) {
    return format(new Date(t), 'YYYY-MM-DD');
  }
  const salesGroups = groupBy(sales, s => displayTime(s.created), s => sortTime(s.created));
  const salesList = salesGroups.map(salesGroup => (
    <div className={classes.salesGroup} key={salesGroup.sortKey}>
      <div className={classes.listHeader}>{salesGroup.name}</div>
      {salesGroup.elements
        .sort((a, b) => b.created - a.created)
        .map(sale => (
          <SalesRow key={sale.id} sale={sale} onSaleClick={onSaleClick} />
        ))}
    </div>
  ));

  return (
    <div className={classes.root}>
      <div className={classes.table}>{salesList}</div>
      {salesRemaining && (
        <Button
          fullWidth
          className={classes.button}
          theme="primary"
          onClick={onMoreClick}
          label="VIS FLERE SALG"
          disabled={!salesRemaining}
        />
      )}
    </div>
  );
};

SalesTable.propTypes = {
  classes: PropTypes.object.isRequired,
  sales: PropTypes.array.isRequired,
  salesRemaining: PropTypes.bool.isRequired,
  onSaleClick: PropTypes.func.isRequired,
  onMoreClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(SalesTable);
