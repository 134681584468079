import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CountUp from 'react-countup';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  content: {
    width: '100%',
    textAlign: 'center',
  },
  font: {
    fontWeight: 300,
    fontSize: 36,
    fontFamily: theme.fonts.barlowSemiCondensed,
    color: theme.colors.fontColor1,
  },
  divider: {
    border: 'none',
    marginTop: 0,
    borderTop: `1px solid ${theme.colors.fontColor1}`,
  },
});

class Price extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prevPrice: this.props.price,
      currentPrice: this.props.price,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.price !== nextProps.price) {
      this.setState({
        prevPrice: this.props.price,
        currentPrice: nextProps.price,
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { prevPrice, currentPrice } = this.state;
    return (
      <div className={classes.content}>
        <hr className={classes.divider} />
        <span className={classes.font}>
          <CountUp start={prevPrice} end={currentPrice} duration={1} useEasing /> .-
        </span>
      </div>
    );
  }
}

Price.propTypes = {
  classes: PropTypes.object.isRequired,
  price: PropTypes.number.isRequired,
};

export default withStyles(styles)(Price);
