export const TOGGLE_MENU_ACIVE = 'SET_MENU_ACTIVE';
export const SET_ACTIVE_ITEM = 'SET_ACTIVE_ITEM';

const toggleMenuActiveSuccess = active => ({
  type: TOGGLE_MENU_ACIVE,
  active,
});

const setActiveItemSuccess = value => ({
  type: SET_ACTIVE_ITEM,
  value,
});

export const toggleMenuActive = active => dispatch => dispatch(toggleMenuActiveSuccess(active));

export const setActiveItem = value => dispatch => dispatch(setActiveItemSuccess(value));
