import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import PeriodCard from 'components/OfferPeriod/PeriodCard';
import PeriodInformation from 'components/OfferPeriod/PeriodInformation';
import Grid from '@material-ui/core/Grid';
import Loading from 'components/Common/Loading';
import { isLoepende, isFastperiode } from '../../utils/misc';

const styles = theme => ({
  wrapper: {
    marginTop: theme.margins.contentMarginTop,
  },
  divider: {
    border: 'none',
    borderTop: theme.divider.primaryDashed,
    margin: '2rem',
    marginLeft: 0,
    marginRight: 0,
  },
});

const OfferPeriod = ({
  classes,
  periods,
  onLoependeCardClick,
  onFastperiodeCardClick,
  loading,
}) => {
  const getNumberOfDays = durationInHours => Math.ceil(durationInHours / 24) % 7;
  const getNumberOfWeeks = durationInHours => Math.floor(durationInHours / (24 * 7));

  const isActive = period => new Date() < period.sesong.til * 1000;

  const shortPeriodCards = periods.filter(isLoepende).map(period => (
    <Grid
      key={period.tittel}
      onClick={() => (isActive(period) && period.apnetForSalg && !period.utsolgt ? onLoependeCardClick(period) : {})}
      item
      xs={6}
      md={4}>
      <PeriodCard
        title={period.tittel}
        numberOfDays={getNumberOfDays(period.varighet)}
        numberOfWeeks={getNumberOfWeeks(period.varighet)}
        isSeason={false}
        isActive={isActive(period)}
      />
      <PeriodInformation
        price={period.priser.standardpris}
        priceChangePeriods={period.priser.perioder}
        isActive={isActive(period)}
        isOpenForSale={period.apnetForSalg}
        isSoldOut={period.utsolgt}
      />
    </Grid>
  ));

  const longPeriodCards = periods.filter(isFastperiode).map(period => (
    <Grid
      key={period.tittel}
      onClick={() => (isActive(period) && period.apnetForSalg && !period.utsolgt ? onFastperiodeCardClick(period) : {})}
      item
      xs={12}
      md={6}>
      <PeriodCard
        title={period.tittel}
        numberOfDays={0}
        numberOfWeeks={0}
        isSeason
        isActive={isActive(period)}
      />
      <PeriodInformation
        price={period.priser.standardpris}
        fromDate={period.sesong.fra}
        toDate={period.sesong.til}
        disabledPeriod={period.ikkeGyldigPeriode}
        priceChangePeriods={period.priser.perioder}
        isActive={isActive(period)}
        isOpenForSale={period.apnetForSalg}
        isSoldOut={period.utsolgt}
      />
    </Grid>
  ));

  return (
    <div>
      <div className={classes.wrapper}>
        {!loading && (
          <div>
            <Grid container spacing={24}>
              {shortPeriodCards}
            </Grid>
            <hr className={classes.divider} />
            <Grid container spacing={24} className={classes.cardsContainer}>
              {longPeriodCards}
            </Grid>
          </div>
        )}
        <Loading label="Laster inn" active={loading} />
      </div>
    </div>
  );
};

OfferPeriod.propTypes = {
  classes: PropTypes.object.isRequired,
  periods: PropTypes.array.isRequired,
  onLoependeCardClick: PropTypes.func.isRequired,
  onFastperiodeCardClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default withStyles(styles)(OfferPeriod);
