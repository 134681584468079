import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/Common/Button';

import { Grid, withStyles } from '@material-ui/core';

const styles = theme => ({
  bottomGroup: {
    marginTop: 50,
    position: 'relative',
    width: '100%',
    fontFamily: theme.fonts.barlow,
    fontSize: 14,
  },
  printIcon: {
    border: '1px solid rgba(50,55,50,1)',
    marginTop: 80,
  },
  toPrint: {
    display: 'none',
  },
});

const SaleDetailsButtons = ({
  classes,
  onChangeClick,
  onSendEmailClick,
  onDeleteClick,
  onPrintClick,
}) => (
  <div>
    <Grid container spacing={16} alignContent="center">
      <Grid item xs={12} sm={12} md={4} lg={4}>
        <Button
          fullWidth
          className={classes.button}
          theme="primary"
          onClick={onChangeClick}
          label="ENDRE"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <Button
          fullWidth
          className={classes.button}
          theme="tetriary"
          onClick={onSendEmailClick}
          label="SEND PA EPOST"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <Button
          fullWidth
          className={classes.button}
          theme="tetriary"
          onClick={onPrintClick}
          label="PRINT"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Button
          fullWidth
          className={classes.button}
          theme="danger"
          onClick={onDeleteClick}
          label="SLETT KORT"
        />
      </Grid>
    </Grid>
  </div>
);

SaleDetailsButtons.propTypes = {
  classes: PropTypes.object.isRequired,
  onChangeClick: PropTypes.func.isRequired,
  onSendEmailClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onPrintClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(SaleDetailsButtons);
