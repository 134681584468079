import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/Common/Modal';
import { withStyles } from '@material-ui/core';
import SaleEditContainer from '../../containers/SaleEditContainer';

const styles = theme => ({
  modalContent: {
    margin: 40,
  },
  textArea: {
    marginBottom: theme.margins.large,
    fontFamily: theme.fonts.barlow,
    fontSize: '14pt',
  },
});

const SaleEditModal = ({ show, onCloseClick, afterEdit, saleId, customer, classes }) => (
  <Modal onCloseClick={onCloseClick} sidebar show={show}>
    <div className={classes.modalContent}>
      <p className={classes.textArea}>Rediger kundeinformasjon</p>
      <SaleEditContainer
        betaler={customer}
        saleId={saleId}
        onCloseClick={onCloseClick}
        afterEdit={afterEdit}
      />
    </div>
  </Modal>
);

SaleEditModal.propTypes = {
  classes: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  customer: PropTypes.object.isRequired,
  saleId: PropTypes.string.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  afterEdit: PropTypes.func.isRequired,
};

export default withStyles(styles)(SaleEditModal);
