import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import SalesRoutes from 'components/Sales/SalesRoutes';

class SalesContainer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { match, location } = this.props;
    return (
      <div>
        <SalesRoutes match={match} location={location} />
      </div>
    );
  }
}

SalesContainer.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(SalesContainer);
