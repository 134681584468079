import React from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import Navbar from 'components/Common/Navbar';
import arrowLeft from 'static/icons/arrow_left.svg';
import SalesTable from 'components/SalesHistory/SalesTable';
import SearchField from 'components/Common/SearchField';

const styles = theme => ({
  container: {
    position: 'absolute',
    minHeight: '100%',
  },
  search: {
    position: 'absolute',
    marginRight: 40,
    top: 50,
    right: 0,
  },
  header: {
    fontFamily: theme.fonts.vollkorn,
    fontSize: 48,
    fontWeight: 'normal',
  },
  left: {
    background: theme.colors.backgroundColor1,
  },
  right: {
    background: theme.gradients.light,
  },
  content: {
    padding: '150px 40px 40px 40px',
    color: theme.colors.fontColor1,
  },
});

const SalesHistory = ({
  classes,
  onHomeClick,
  onSaleClick,
  sales,
  salesRemaining,
  searchWord,
  searchLoading,
  onTextChange,
  onSearchClick,
  onMoreClick,
}) => (
  <div>
    <Navbar title="HJEM" icon={arrowLeft} onClick={onHomeClick} />
    <Grid container spacing={0} className={classes.container}>
      <Grid item sm={3} md={3} lg={3} className={classes.left} />
      <Grid item xs={12} sm={9} md={9} lg={9} className={classes.right}>
        <div className={classes.content}>
          <div className={classes.search}>
            <SearchField
              type="text"
              name="searchText"
              placeholder="Søk etter salg"
              text={searchWord}
              onFieldChange={onTextChange}
              hasButton
              onButtonClick={onSearchClick}
              buttonDisabled={searchLoading}
            />
          </div>
          <h1 className={classes.header}>Salgshistorikk</h1>
          <SalesTable
            sales={sales}
            onSaleClick={onSaleClick}
            onMoreClick={onMoreClick}
            salesRemaining={salesRemaining}
          />
        </div>
      </Grid>
    </Grid>
  </div>
);

SalesHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  onHomeClick: PropTypes.func.isRequired,
  sales: PropTypes.array.isRequired,
  salesRemaining: PropTypes.bool.isRequired,
  onSaleClick: PropTypes.func.isRequired,
  onSearchClick: PropTypes.func.isRequired,
  onMoreClick: PropTypes.func.isRequired,
  onTextChange: PropTypes.func.isRequired,
  searchWord: PropTypes.string.isRequired,
  searchLoading: PropTypes.bool.isRequired,
};

export default withStyles(styles)(SalesHistory);
